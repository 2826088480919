import React, { useState, useEffect } from "react";
import MyIcon from "../Icon/pageIcon.jsx";

const theme = localStorage.getItem("theme");

const bool = (themeString) => {
  if (themeString === "false") {
    return false;
  } else {
    return true;
  }
};

function ThemeButton(props) {
  // eslint-disable-next-line no-use-before-define
  const [s_state, setState] = useState(bool(theme));

  const is_change_theme = () => {
    setState((prevState) => !prevState);
  };

  useEffect(() => {
    props.onClick(s_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s_state]);

  const themeIconStyle = {
    cursor: "pointer",
  };

  return (
    <>
      {s_state ? (
        <MyIcon.IconMoon
          style={{ ...props.style, ...themeIconStyle }}
          onClick={is_change_theme}
        />
      ) : (
        <MyIcon.IconSun
          style={{ ...props.style, ...themeIconStyle }}
          onClick={is_change_theme}
        />
      )}
    </>
  );
}
export default ThemeButton;
