import { useState, useEffect } from "react";
import styled from "styled-components";
import CustomTable from "../../antdCustom/CustomTable";
import Light from "../../Light";
import { Row, Col, Button, message } from "antd";
import CustomModal from "../../antdCustom/CustomModal";
import { getAllRunb, getAllRun90 } from "../../../services/api/api";
import checkUndefined from "../../../utility/checkUndefined";
import MyIcon from "../../Icon/pageIcon";
import Title from "../../Text"
import QualityInspectionTwo from "./QualityInspectionTwo";
import ChoosePeople from "./ChoosePeople";

const getLight = (light) => {
  switch (light) {
    case "G":
      return <Light size="20px" type="success" />;
    case "Y":
      return <Light size="20px" type="warning" />;
    case "W":
      return <Light size="20px" disabled />;
    case "R":
      return <Light size="20px" type="error" />;
    default:
      return <Light size="20px" disabled />;
  }
};

//2級
const QualityInspectionOneElement = ({ setIsQualityInspection, className }) => {
  const pdtlotCol = [
    { title: "批號", key: "pdtlot", dataIndex: "pdtlot", width: "200px" },
    {
      title: "目前槽號",
      key: "lifeF",
      dataIndex: "lifeF",
      width: "200px",
      align: "center",
    },
    {
      title: "F1品檢",
      key: "f1pass",
      dataIndex: "f1pass",
      width: "100px",
      align: "center",
      render: (item) => getLight(item),
    },
    {
      title: "F1時標",
      key: "f1Tstr",
      dataIndex: "f1Tstr",
      width: "200px",
      align: "center",
    },
    {
      title: "F2品檢",
      key: "f2pass",
      dataIndex: "f2pass",
      width: "100px",
      align: "center",
      render: (item) => getLight(item),
    },
    {
      title: "F2時標",
      key: "f2Tstr",
      dataIndex: "f2Tstr",
      width: "200px",
      align: "center",
    },
    {
      title: "F3品檢",
      key: "f3pass",
      dataIndex: "f3pass",
      width: "100px",
      align: "center",
      render: (item) => getLight(item),
    },
    {
      title: "F3時標",
      key: "f3Tstr",
      dataIndex: "f3Tstr",
      width: "200px",
      align: "center",
    },
  ];

  const pdtlotBodyCol = [
    {
      title: "燈號",
      key: "ispass",
      dataIndex: "ispass",
      width: "50px",
      align: "center",
      render: (item) => getLight(item),
    },
    {
      title: "檢驗單號",
      key: "inspN",
      dataIndex: "inspN",
      width: "300px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "SIP文編",
      key: "sipID",
      dataIndex: "sipID",
      width: "250px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "版碼",
      key: "version",
      dataIndex: "version",
      width: "100px",
      align: "center",
      render: (item) => checkUndefined(item),
    },
    // {
    //   title: "類型",
    //   key: "siptype",
    //   dataIndex: "siptype",
    //   width: "100px",
    //   align: "center",
    //   render: (item) => checkUndefined(item),
    // },
    {
      title: "控點",
      key: "ctrlspot",
      dataIndex: "ctrlspot",
      width: "100px",
      align: "center",
      render: (item) => (item === "wl" ? "工線比對" : "工站比對"),
    },
    {
      title: "控點編號",
      key: "spotID",
      dataIndex: "spotID",
      width: "100px",
      align: "center",
    },

    {
      title: "創建時間",
      key: "createTstr",
      dataIndex: "createTstr",
      width: "100px",
      align: "center",
      // render: (item) => checkUndefined(item),
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "抽樣人員",
      key: "sampleID",
      dataIndex: "sampleID",
      width: "100px",
      align: "center",
      render: (item) => {
        return item ? (
          checkUndefined(item)
        ) : (
          <Button size="large" onClick={() => setIsChoosePeopleModal(true)}>
            選擇抽樣人員
          </Button>
        );
      },
    },
    {
      title: "採樣時間",
      key: "sampleTstr",
      dataIndex: "sampleTstr",
      width: "100px",
      align: "center",
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "完測時間",
      key: "closeTstr",
      dataIndex: "closeTstr",
      width: "100px",
      align: "center",
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "檢測時間",
      key: "timeDiff",
      dataIndex: "timeDiff",
      width: "200px",
      align: "center",
      render: (item) => `${item[0]}天${item[1]}時${item[2]}分${item[3]}秒`,
    },
    {
      title: "操作",
      key: "action",
      dataIndex: "action",
      width: "80px",
      align: "center",
      render: (text, rowData, index) =>
        rowData.sampleID ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ cursor: "pointer" }}>
              <MyIcon.IconPen
                className="icon"
                onClick={() => setIsQualityInspectionTwo(true)}
              />
            </div>
          </div>
        ) : null,
    },
  ];

  const [lotdata, setlodata] = useState([]);

  const [run90, setRun90] = useState([]);

  const [singlePdtlot, setSinglePdtlot] = useState({});

  const [isQualityInspectionTwo, setIsQualityInspectionTwo] = useState(false);

  const [isChoosePeopleModal, setIsChoosePeopleModal] = useState(false);

  const [twoData, setTwoData] = useState({});

  useEffect(() => {
    call_getAllRunb();
  }, []);

  useEffect(() => {
    if (!isQualityInspectionTwo) {
      call_getAllRunb();
    }
  }, [isQualityInspectionTwo]);

  useEffect(() => {
    if (!isChoosePeopleModal && Object.keys(singlePdtlot).length > 0) {
      call_getAllRun90();
    }
  }, [isChoosePeopleModal]);

  useEffect(() => {
    if (Object.keys(singlePdtlot).length > 0) {
      call_getAllRun90();
    }
  }, [lotdata]);

  useEffect(() => {
    if (Object.keys(singlePdtlot).length > 0) {
      call_getAllRun90();
    }
  }, [singlePdtlot]);

  const call_getAllRunb = async () => {
    try {
      const res = await getAllRunb();
      if (res.status) {
        const data = res.data.map((x) => {
          return { ...x, key: x.pdtlot };
        });
        setlodata(data);
      } else {
        message.error(res.status);
      }
    } catch (e) {
      console.log("call_getAllRunb", e);
    }
  };

  const call_getAllRun90 = async () => {
    try {
      const res = await getAllRun90(singlePdtlot);
      if (res.status) {
        const data = res.data.map((x) => {
          return { ...x, key: x.inspN };
        });
        setRun90(data);
        message.success(res.msg);
      } else {
        setRun90([]);
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_getAllRun90", e);
    }
  };

  const clickRow = (record) => {
    return {
      onClick: () => {
        setSinglePdtlot(record);
      },
    };
  };

  const clickTwoRow = (record) => {
    return {
      onClick: () => {
        setTwoData(record);
      },
    };
  };

  const closeModal = () => {
    setIsQualityInspection(false);
  };

  return (
    <Row className={className} gutter={[0, 12]}>
      <Col span={24}>
        <CustomTable
          dataSource={lotdata}
          columns={pdtlotCol}
          scroll={{ x: "max-content", y: "auto" }}
          rowClassName={(record) => {
            return record.pdtlot === singlePdtlot.pdtlot ? "clickRowStyle" : "";
          }}
          pagination={false}
          onRow={clickRow}
        />
      </Col>
      <Col span={24}>
        <Title level="h5" type="main">反應槽過站檢驗</Title>
      </Col>
      <Col span={24}>
        <CustomTable
          dataSource={run90}
          columns={pdtlotBodyCol}
          scroll={{ x: "max-content", y: "auto" }}
          pagination={false}
          onRow={clickTwoRow}
        />
      </Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
          onClick={closeModal}
        >
          關閉
        </Button>
      </Col>

      {isQualityInspectionTwo ? (
        <CustomModal
          width="90%"
          title="品檢錄入"
          visible={isQualityInspectionTwo}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => setIsQualityInspectionTwo(false)}
        >
          <QualityInspectionTwo
            isQualityInspectionTwo={setIsQualityInspectionTwo}
            twoData={twoData}
          />
        </CustomModal>
      ) : null}

      {isChoosePeopleModal ? (
        <CustomModal
          centered
          width="20%"
          title="選擇採樣人員"
          visible={isChoosePeopleModal}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => setIsChoosePeopleModal(false)}
        >
          <ChoosePeople
            isShowModal={setIsChoosePeopleModal}
            twoData={twoData}
          />
        </CustomModal>
      ) : null}
    </Row>
  );
};

const QualityInspectionOne = styled(QualityInspectionOneElement)`
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .align-r {
    text-align: right;
  }
  .button {
    border-redius: 5px;
  }
`;

export default QualityInspectionOne;
