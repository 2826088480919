export const fontSize = {
  // h1: "38px",
  // h2: "30px",
  // h3: "24px",
  // h4: "20px",
  // h5: "16px",
  // p: "14x",
  h1: "29px",
  h2: "27px",
  h3: "25px",
  h4: "23px",
  h5: "21px",
  p: "19px",
};
