const apiBasic = {
  // 屏東
  url: "wos.mc.sat.com.tw",
  conn: "https",

  // url: "dwos.mc.syncff-sat.com:9000",
  // conn: "http",

  // url: "192.168.0.204:9000",
  // conn: "http",
  // 自己
  // url: "localhost:9000",
  // conn: "http",
};

export default apiBasic;
