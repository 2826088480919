import { useState,useEffect } from "react";
import styled from "styled-components";
import { Select, Row, Col, Button, message } from "antd";
import { getAllStaffs,run90sample } from "../../../services/api/api";

const ChoosePeopleEle = ({ className, isShowModal ,twoData}) => {

	const {Option} = Select

	const [peopleList, setPeopleLset] = useState([])

	const [thisPeopleID, setThisPeopleID] = useState("")

	const call_run90sample = async() =>{
		try{
			const res = await run90sample({...twoData, sampleID:thisPeopleID})
			if (res.status){
				message.success(res.msg)
				closeModal()
			}else{
				message.error(res.msg)
			}
		}catch(e){
		}
	}

  const call_getAllStaffs = async() => {
    try {
			const res = await getAllStaffs()
			if (res.status){
				const options = res.data.map(x=>{
					return <Option key={x.cardID} value={x.cardID}>{x.peopleNM}</Option>
				})
				setPeopleLset(options)
			}else{
				message.error(res.msg)
			}
    } catch (e) {
			setPeopleLset([])
      console.log("call_getAllStaffs",e);
    }
  };

	useEffect(() => {
		call_getAllStaffs()
	}, [])

  const closeModal = () => {
    isShowModal(false);
  };

  return (
    <Row className={className} gutter={[24, 24]}>
      <Col span={24} className="align-c">
				<Select
					size="large"
					className="w-200p"
					onChange={(e)=>{
						setThisPeopleID(e)
					}}
					value={thisPeopleID}
				>
					{peopleList}
				</Select>
			</Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
         	onClick={call_run90sample}
        >
          送出
        </Button>
      </Col>
    </Row>
  );
};

const ChoosePeople = styled(ChoosePeopleEle)`
  .align-c {
    text-align: center;
  }
  .align-r {
    text-align: right;
  }
  .button {
    border-redius: 5px;
  }
  .w-200p {
    width: 200px;
  }
`;
export default ChoosePeople;
