//當前收起狀態
const navBarReducer = (state = true, action) => {
  switch (action.type) {
    case "CHANGE_MENU_STATUS": {
      return action.payload;
    }
    default:
      return state;
  }
};
export default navBarReducer;
