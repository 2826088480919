import { useState, useEffect } from "react";
import styled from "styled-components";
import CustomTable from "../../antdCustom/CustomTable";
import { Row, Col, Button, message, PageHeader } from "antd";
import CustomModal from "../../antdCustom/CustomModal";
import Light from "../../Light";
import MyIcon from "../../Icon/pageIcon";

import { getAllRun91, getAllRun92 } from "../../../services/api/api";

import ModifyLightModal from "./ModifyLightModal";
import AddRun92Comment from "./AddRun92Comment";
import ModifyRun90Light from "./ModifyRun90Light";
import Title from "../../Text";
//3級

const getLight = (light) => {
  switch (light) {
    case "G":
      return <Light size="20px" type="success" />;
    case "Y":
      return <Light size="20px" type="warning" />;
    case "W":
      return <Light size="20px" disabled />;
    case "R":
      return <Light size="20px" type="error" />;
    default:
      return <Light size="20px" disabled />;
  }
};

const QualityInspectionTwoElement = ({
  className,
  isQualityInspectionTwo,
  twoData,
}) => {
  const run91siptb = [
    {
      title: "是否合格",
      key: "ispass",
      dataIndex: "ispass",
      width: "100px",
      align: "center",
      render: (item) => {
        return getLight(item);
      },
    },
    {
      title: "合格判定",
      key: "action",
      dataIndex: "action",
      width: "80px",
      align: "center",
      render: (text, rowData) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ cursor: "pointer" }}>
            <MyIcon.IconPen
              className="icon"
              onClick={() => setIsModifyLightModal(true)}
            />
          </div>
        </div>
      ),
    },
    {
      title: "SIP項次",
      key: "sipno",
      dataIndex: "sipno",
      width: "100px",
      align: "center",
    },

    // { title: "檢驗單號", key: "inspN", dataIndex: "inspN", width: "250px" },
    { title: "SIP文號", key: "sipID", dataIndex: "sipID", width: "250px" },
    {
      title: "版本",
      key: "version",
      dataIndex: "version",
      width: "100px",
      align: "center",
    },
    {
      title: "類別",
      key: "itemtype",
      dataIndex: "itemtype",
      width: "100px",
      align: "center",
    },
    {
      title: "驗收型態",
      key: "insptype",
      dataIndex: "insptype",
      width: "100px",
      align: "center",
    },
    {
      title: "檢驗項目",
      key: "sipitem",
      dataIndex: "sipitem",
      width: "100px",
      align: "center",
    },
    // {
    //   title: "計量數值(標準值)",
    //   key: "value",
    //   dataIndex: "value",
    //   width: "200px",
    // },
    {
      title: "管制上限",
      key: "UCL",
      dataIndex: "UCL",
      width: "50px",
      align: "center",
    },
    {
      title: "管制下限",
      key: "LCL",
      dataIndex: "LCL",
      width: "50px",
      align: "center",
    },

    {
      title: "檢驗要點(計數屬性)",
      key: "inspnote",
      dataIndex: "inspnote",
      width: "200px",
    },
    {
      title: "監控內容/圖號",
      key: "drawingno",
      dataIndex: "drawingno",
      width: "200px",
      align: "center",
    },
    {
      title: "單位",
      key: "unit",
      dataIndex: "unit",
      width: "100px",
      align: "center",
    },
    // {
    //   title: "檢測環境",
    //   key: "sipcond",
    //   dataIndex: "sipcond",
    //   width: "100px",
    //   align: "center",
    // },
    // {
    //   title: "允收品質水準",
    //   key: "aql",
    //   dataIndex: "aql",
    //   width: "150px",
    //   align: "center",
    // },
    {
      title: "樣本大小",
      key: "samplesize",
      dataIndex: "samplesize",
      width: "100px",
      align: "center",
    },
    {
      title: "計量值之量測平均",
      key: "avgvalue",
      dataIndex: "avgvalue",
      width: "200px",
    },
    {
      title: "計量值之標準差",
      key: "delta",
      dataIndex: "delta",
      width: "200px",
    },
    { title: "全距", key: "range", dataIndex: "range", width: "100px" },
    {
      title: "完測時間",
      key: "closeTstr",
      dataIndex: "closeTstr",
      width: "200px",
    },
    { title: "備註", key: "note", dataIndex: "note", width: "200px" },
  ];

  const run92Measuring = [
    {
      title: "是否合格",
      key: "ispass",
      dataIndex: "ispass",
      width: "100px",
      align: "center",
      render: (item) => {
        return getLight(item);
      },
    },
    { title: "累積採樣數", key: "recno", dataIndex: "recno", width: "150px" },
    {
      title: "驗收型態",
      key: "insptype",
      dataIndex: "insptype",
      width: "200px",
      align: "center",
    },
    {
      title: "檢驗項目",
      key: "sipitem",
      dataIndex: "sipitem",
      width: "200px",
      align: "center",
    },
    {
      title: "量測值",
      key: "realvalue",
      dataIndex: "realvalue",
      width: "150px",
    },
    {
      title: "標準值",
      key: "value",
      dataIndex: "value",
      width: "200px",
    },
    {
      title: "檢測環境",
      key: "sipcond",
      dataIndex: "sipcond",
      width: "200px",
      align: "center",
    },
    {
      title: "允收品質水準",
      key: "aql",
      dataIndex: "aql",
      width: "200px",
      align: "center",
    },

    {
      title: "檢驗時間",
      key: "checkTstr",
      dataIndex: "checkTstr",
      width: "200px",
    },
  ];

  const run92Count = [
    {
      title: "是否合格",
      key: "ispass",
      dataIndex: "ispass",
      width: "100px",
      align: "center",
      render: (item) => {
        return getLight(item);
      },
    },

    { title: "累積採樣數", key: "recno", dataIndex: "recno", width: "200px" },
    {
      title: "驗收型態",
      key: "insptype",
      dataIndex: "insptype",
      width: "200px",
      align: "center",
    },
    {
      title: "檢驗項目",
      key: "sipitem",
      dataIndex: "sipitem",
      width: "150px",
      align: "center",
    },
    { title: "良品數", key: "okC", dataIndex: "okC", width: "150px" },
    { title: "不良品數", key: "ngC", dataIndex: "ngC", width: "150px" },
    {
      title: "檢測環境",
      key: "sipcond",
      dataIndex: "sipcond",
      width: "200px",
      align: "center",
    },
    {
      title: "允收品質水準",
      key: "aql",
      dataIndex: "aql",
      width: "200px",
      align: "center",
    },

    {
      title: "檢驗時間",
      key: "checkTstr",
      dataIndex: "checkTstr",
      width: "200px",
    },
  ];

  const [isAddRun92Modal, setIsAddRun92Modal] = useState(false);

  const [run91Data, setRun91Data] = useState([]);

  const [thisRun91, setThisRun91Data] = useState({});

  const [run92Data, setRun92Data] = useState([]);

  const [isModifyLightModal, setIsModifyLightModal] = useState(false);

  const [isModifyRun90Light, setIsModifyRun90Light] = useState(false);

  const call_getAllRun91 = async () => {
    const res = await getAllRun91(twoData);
    if (res.status) {
      console.log(res.data);
      setRun91Data(
        res.data.map((x) => {
          return { ...x, key: x.inspN + x.sipno };
        })
      );
    } else {
      message.error(res.msg);
    }
  };

  const call_getAllRun92 = async () => {
    const res = await getAllRun92(thisRun91);
    if (res.status) {
      setRun92Data(
        res.data.map((x) => {
          return { ...thisRun91, ...x, key: x.recno };
        })
      );
      message.success(res.msg);
    } else {
      setRun92Data([]);
      message.error(res.msg);
    }
  };

  useEffect(() => {
    if (!isModifyLightModal) {
      call_getAllRun91();
    }
  }, [isModifyLightModal]);

  useEffect(() => {
    if (!isAddRun92Modal) {
      call_getAllRun92();
    }
  }, [isAddRun92Modal]);

  useEffect(() => {
    if (Object.keys(thisRun91).length > 0) {
      call_getAllRun92();
    }
  }, [thisRun91]);

  const closeModal = () => {
    isQualityInspectionTwo(false);
  };

  const clickTwoRow = (record) => {
    return {
      onClick: () => {
        setThisRun91Data(record);
      },
    };
  };

  useEffect(() => {
    call_getAllRun91();
  }, []);

  return (
    <Row gutter={[0, 6]} className={className}>
      <Col span={24}>
        <PageHeader
          title={
            <Title level="h3" type="main">
              檢驗主檔 &nbsp;&nbsp;{run91Data[0]?.inspN}
            </Title>
          }
          extra={[
            <Button
              size="large"
              type="primary"
              className="button"
              onClick={() => setIsModifyRun90Light(true)}
            >
              總判
            </Button>,
          ]}
          style={{ padding: "0px" }}
        />
      </Col>
      <Col span={24}>
        <CustomTable
          dataSource={run91Data}
          columns={run91siptb}
          scroll={{ x: "max-content", y: "250px" }}
          pagination={false}
          onRow={clickTwoRow}
          rowClassName={(record) => {
            return record.inspN + record.sipno ===
              thisRun91.inspN + thisRun91.sipno
              ? "clickRowStyle"
              : "";
          }}
        />
      </Col>
      <Col span={24}>
        <PageHeader
          title={
            <Title level="h3" type="main">
              明細紀錄
            </Title>
          }
          extra={[
            <Button
              size="large"
              type="primary"
              className="button"
              disabled={Object.keys(thisRun91).length > 0 ? false : true}
              onClick={() => setIsAddRun92Modal(true)}
            >
              新增抽樣明細
            </Button>,
          ]}
          style={{ padding: "0px" }}
        />
      </Col>
      <Col span={24}>
        <CustomTable
          dataSource={run92Data}
          columns={
            thisRun91.insptype === "計數型" ? run92Count : run92Measuring
          }
          scroll={{ x: "max-content", y: "250px" }}
          pagination={false}
        />
      </Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
          onClick={closeModal}
        >
          關閉
        </Button>
      </Col>
      {isAddRun92Modal ? (
        <CustomModal
          style={{ top: 20 }}
          width="40%"
          title="新增抽樣明細"
          visible={isAddRun92Modal}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => setIsAddRun92Modal(false)}
        >
          <AddRun92Comment
            setIsAddRun92Modal={setIsAddRun92Modal}
            thisRun91={thisRun91}
          />
        </CustomModal>
      ) : null}
      {isModifyLightModal ? (
        <CustomModal
          width="20%"
          title="修改是否合格燈號"
          visible={isModifyLightModal}
          destroyOnClose
          footer={null}
          forceRender
          centered
          getContainer={false}
          onCancel={() => setIsModifyLightModal(false)}
        >
          <ModifyLightModal
            setIsModifyLightModal={setIsModifyLightModal}
            thisRun91={thisRun91}
          />
        </CustomModal>
      ) : null}
      {isModifyRun90Light ? (
        <CustomModal
          width="20%"
          title="修改總判燈號"
          visible={isModifyRun90Light}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => setIsModifyRun90Light(false)}
        >
          <ModifyRun90Light
            setIsModifyRun90Light={setIsModifyRun90Light}
            twoData={twoData}
          />
        </CustomModal>
      ) : null}
    </Row>
  );
};

const QualityInspectionTwo = styled(QualityInspectionTwoElement)`
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .align-r {
    text-align: right;
  }
  .ant-page-header-heading-extra{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;
export default QualityInspectionTwo;
