import React, { useState, useEffect } from "react";
import WOI from "../../styled/workOrderInformation";
import MyWpcRecode from "../../styled/wpcRecode";
import WsWlSelect from "../../components/WsWlSelect";
import WorkOrderInformation from "../../components/WosQualityControl/WorkOrderInformation";
import Productivity from "../../components/WosQualityControl/Productivity";
import { numberWithCommas } from "../../utility/numberWithCommas";
import useStateRef from "../../utility/useStateRef";
import MyButton from "../../styled/button";
import checkUndefined from "../../utility/checkUndefined";
import Cookies from "universal-cookie";

import {
  Row,
  Col,
  Table,
  message,
  Button,
  Space,
  Modal,
  Input,
  Typography,
  Tooltip,
} from "antd";
import {
  MyPageName,
  MyComponent,
  MyPageTitle,
  MyThemeH1,
  MyMainColorH3,
  MySubtitleH4,
  MyDisabled,
  MyMainColorH1,
  MyComponentTwo
} from "../../styled/publicTag";

import {
  getAllWpc,
  pass,
  waive,
  quarantine,
  confirm,
  cancel,
} from "../../services/api/qcAPI";

const WosQualityControl = () => {
  
  const { Search } = Input;

  const { Link } = Typography;

  const cookies = new Cookies();

  const wpcDetailsTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "下線碼",
      dataIndex: "offfix",
      key: "offfix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "多單碼",
      dataIndex: "woMix",
      key: "woMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "線別碼",
      dataIndex: "lineMix",
      key: "lineMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
  ];

  const wpcTableCol = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      width: "90px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      width: "250px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "歷程時間",
      dataIndex: "lifeFTime",
      key: "lifeFTime",
      width: "150px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(
          str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料"
        );
      },
    },
    {
        title: "料號",
      dataIndex: "PN",
      key: "PN",
      width: "280px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      width: "300px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    // {
    //   title: "生產數量",
    //   dataIndex: "okC",
    //   key: "okC",
    //   width: "130px",
    //   className: "row-min-width",
    //   align: "center",
    //   render: (str) => {
    //     return checkUndefined(str);
    //   },
    // },
    {
      title: "動作",
      dataIndex: "action",
      key: "action",
      className: "row-last-col-width",
      align: "center",
      render: (obj, recoded) => {
        return (
          <Tooltip title={`途程碼：${recoded.wpcSN + ""}`}>
            <Link
              onClick={() => {
                setSingleWpc({ ...singleWpc, isOpen: true });
                setAllWpc({ ...allWpc, thisWpc: obj });
              }}
            >
              詳情
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  const [singleWpc, setSingleWpc, singleWpcRef] = useStateRef({
    isOpen: false,
    thisWpc: {},
  });

  const [allWpc, setAllWpc, allWpcRef] = useStateRef({
    //料表詳情
    pageInfo: {
      page: 1,
      quertString: "",
      totalItems: 0,
      totalItems: 25,
      currentPage: 1,
      pageItemNumber: 10,
      startItemNumber: 1,
      endItemNumber: 10,
    },
    loading: false,
    data: [],
    thisWpc: {},
  });

  const [wpcRecode, setWpcRecode] = useState({
    up: {},
    down: {},
  });

  const [processManagementButton, setProcessManagementButton] = useState({
    passButton: true,
    waiverButton: true,
    quarantineButton: true,
    confirmButton: true,
    cancelButton: true,
  });

  const call_getAllWpc = async () => {
    try {
      const res = await getAllWpc(allWpcRef.current.pageInfo);
      if (res.status) {
        const newAllWpc = res.data.woNs.map((x) => {
          const wlID = cookies.get("wlID");
          return {
            ...x,
            qty: x.planQty - x.d_planQty - x.d_inC,
            key: x.UUID,
            wlID: wlID,
          };
        });
        const thisWpcIndex = newAllWpc
          .map((x) => {
            return x.UUID;
          })
          .indexOf(allWpcRef.current.thisWpc.UUID);

        let newPageInfo = {
          ...allWpcRef.current.pageInfo,
          ...res.data.pageInfo,
        };
        setAllWpc({
          ...allWpcRef.current,
          data: newAllWpc,
          thisWpc: thisWpcIndex > -1 ? newAllWpc[thisWpcIndex] : {},
          pageInfo: newPageInfo,
        });
      } else {
        setAllWpc({
          pageInfo: {
            page: 1,
            quertString: "",
            totalItems: 0,
            totalItems: 25,
            currentPage: 1,
            pageItemNumber: 10,
            startItemNumber: 1,
            endItemNumber: 10,
          },
          loading: false,
          data: [],
          thisWpc: {},
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searchWPC = async (value) => {
    const newPageInfo = allWpcRef.current.pageInfo;
    newPageInfo.queryString = value
    setAllWpc({ ...allWpcRef.current,pageInfo:newPageInfo });
    call_getAllWpc();
  };


  const call_pass = async () => {
    try {
      if (singleWpcRef.current.thisWpc.lifeF === "P") {
        return;
      }
      const res = await pass({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_waive = async () => {
    try {
      if (singleWpcRef.current.thisWpc.lifeF === "W") {
        return;
      }

      const res = await waive({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_quarantine = async () => {
    try {
      if (singleWpcRef.current.thisWpc.lifeF === "Q") {
        return;
      }
      const res = await quarantine({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_cancel = async () => {
    try {
      const res = await cancel({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_confirm = async () => {
    try {
      const res = await confirm({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    call_getAllWpc();
  }, []);

  useEffect(() => {
    //recode 需要用到的資料
    setSingleWpc({ ...singleWpc, thisWpc: allWpcRef.current.thisWpc });
    let up = {
      wpcSN: allWpcRef.current.thisWpc.preSN,
      wsID: allWpcRef.current.thisWpc.preWsID,
      wsNM: allWpcRef.current.thisWpc.preWsNM,
    };
    let down = {
      wpcSN: allWpcRef.current.thisWpc.nextSN,
      wsID: allWpcRef.current.thisWpc.nextWsID,
      wsNM: allWpcRef.current.thisWpc.nextWsNM,
    };
    setWpcRecode({ up: up, down: down });

    //流程管理
    if (Object.getOwnPropertyNames(allWpc.thisWpc).length !== 0) {
      if (allWpcRef.current.thisWpc.lifeF === "L") {
        setProcessManagementButton({
          passButton: false,
          waiverButton: false,
          quarantineButton: false,
          confirmButton: true,
          cancelButton: true,
        });
      } else if (allWpcRef.current.thisWpc.lifeF === "P") {
        setProcessManagementButton({
          passButton: false,
          waiverButton: true,
          quarantineButton: true,
          confirmButton: false,
          cancelButton: false,
        });
      } else if (allWpcRef.current.thisWpc.lifeF === "Q") {
        setProcessManagementButton({
          passButton: true,
          waiverButton: true,
          quarantineButton: false,
          confirmButton: true,
          cancelButton: false,
        });
      } else if (allWpcRef.current.thisWpc.lifeF === "W") {
        setProcessManagementButton({
          passButton: true,
          waiverButton: false,
          quarantineButton: true,
          confirmButton: false,
          cancelButton: false,
        });
      } else if (allWpcRef.current.thisWpc.lifeF === "G") {
        setProcessManagementButton({
          passButton: true,
          waiverButton: true,
          quarantineButton: true,
          confirmButton: true,
          cancelButton: true,
        });
      }
    } else {
      setProcessManagementButton({
        passButton: true,
        waiverButton: true,
        quarantineButton: true,
        confirmButton: true,
        cancelButton: true,
      });
    }
  }, [allWpc.thisWpc]);

  const buttonStyle = { width: "100%", height: "70px" };

  return (
    <React.Fragment>
      <MyPageName>
        <Row justify="center" align="top">
          <Col span={4}>
            <MyPageTitle>過站_質控作業</MyPageTitle>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Space align="center" size={20}>
              <WsWlSelect />
            </Space>
          </Col>
        </Row>
      </MyPageName>
      <Row justify="space-between" align="bottom">
        <Col span={24}>
          <MyComponentTwo>
            <Row justify="space-between" align="bottom">
              <Col span={12}>
                <Row justify="space-between" align="bottom">
                  <Col span={24}>
                    <MyComponent style={{height:"240px"}}>
                      <Row
                        justify="space-between"
                        align="bottom"
                        gutter={[24, 24]}
                      >
                        <Col span={8}>
                          <MyButton.MySuccessButton
                            disabled={processManagementButton.passButton}
                            style={buttonStyle}
                            onClick={call_pass}
                          >
                            質控合格
                          </MyButton.MySuccessButton>
                        </Col>
                        <Col span={8}>
                          <MyButton.MyWarningButton
                            disabled={processManagementButton.waiverButton}
                            style={buttonStyle}
                            onClick={call_waive}
                          >
                            質控特採
                          </MyButton.MyWarningButton>
                        </Col>
                        <Col span={8}>
                          <MyButton.MyErrorButton
                            disabled={processManagementButton.quarantineButton}
                            style={buttonStyle}
                            onClick={call_quarantine}
                          >
                            質控隔離
                          </MyButton.MyErrorButton>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#BEBEBE" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              投產數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.inC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#448933" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              良品數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.okC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#C54141" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              不良品數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.ngC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#BEBEBE" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              平衡帳差
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.bal
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <MyComponent style={{ margin: "8px" }}>
                      <Row justify="center" align="bottom" gutter={[24, 24]}>
                      <Col span={12}>
                          <MyButton.MyWhiteButton1
                            disabled={processManagementButton.cancelButton}
                            style={buttonStyle}
                            onClick={call_cancel}
                          >
                            質控取消
                          </MyButton.MyWhiteButton1>
                        </Col>

                        <Col span={12}>
                          <MyButton.MyButton1
                            disabled={processManagementButton.confirmButton}
                            style={buttonStyle}
                            onClick={call_confirm}
                          >
                            結單過站
                          </MyButton.MyButton1>
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <MyComponent>
                      <Row
                        justify="space-between"
                        align="bottom"
                        gutter={[24, 12]}
                      >
                        <Col span={24}>
                          <MyMainColorH1>途單紀錄</MyMainColorH1>
                        </Col>
                        <Col span={12}>
                          <MyWpcRecode title="上途程" content={wpcRecode.up} />
                        </Col>
                        <Col span={12}>
                          <MyWpcRecode
                            title="下途程"
                            content={wpcRecode.down}
                          />
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="bottom">
                  <Col span={24}>
                    <MyComponent>
                      <WorkOrderInformation data={singleWpc.thisWpc} />
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <Productivity data={singleWpc.thisWpc} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </MyComponentTwo>
        </Col>
        <Col span={24}>
          <MyDisabled>
              <Row align="bottom">
                <Col span={12}>
                  <MyMainColorH3>途單索引</MyMainColorH3>
                </Col>
                <Col span={12} style={{ textAlign: "right",margin:"6px 0" }}>
                  <Search
                    placeholder="請輸入完整的工單或料號"
                    onSearch={searchWPC}
                    enterButton
                    style={{ width: "230px" }}
                  />
                </Col>
                <Col span={24}>
                  <Table
                    bordered={true}
                    columns={wpcTableCol}
                    scroll={{ x: "max-content", y: 150 }}
                    dataSource={allWpc.data}
                    loading={allWpc.loading}
                    rowClassName={(record) => {
                      return record.UUID === allWpc.thisWpc.UUID
                        ? "clickRowStyl"
                        : "";
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          if (record.UUID === allWpc.thisWpc.UUID) {
                            //點擊詳請不算
                            if (singleWpcRef.current.isOpen) {
                              setAllWpc({ ...allWpc, thisWpc: record });
                            } else {
                              setAllWpc({ ...allWpc, thisWpc: {} });
                            }
                          } else {
                            setAllWpc({ ...allWpc, thisWpc: record });
                          }
                        },
                      };
                    }}
                    // defaultSelectedRowKeys={allWpc.data[0]?allWpc.data[0].UUID:''}
                    pagination={{
                      defaultCurrent: 1,
                      total: allWpc.pageInfo.totalItems
                        ? allWpc.pageInfo.totalItems
                        : 1,
                      current: allWpc.pageInfo.page ? allWpc.pageInfo.page : 1,
                      pageSize: allWpc.pageInfo.pageSize
                        ? allWpc.pageInfo.pageSize
                        : 10,
                      onChange: (current) => {
                        let pageData = allWpc.pageInfo;
                        pageData.page = current;
                        setAllWpc({ ...allWpc, pageInfo: pageData });
                        call_getAllWpc();
                      },
                      showTotal: (total, range) => (
                        <MySubtitleH4>
                          共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
                        </MySubtitleH4>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </MyDisabled>
        </Col>
      </Row>
      <Modal
        title={<MyThemeH1>途單索引詳情</MyThemeH1>}
        visible={singleWpc.isOpen}
        onCancel={() => setSingleWpc({ ...singleWpc, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              setSingleWpc({ ...singleWpc, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1400px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={wpcDetailsTableCol}
          dataSource={[{ ...singleWpc.thisWpc, key: "prefix456" }]}
          pagination={false}
        />
      </Modal>
    </React.Fragment>
  );
};
export default WosQualityControl;
