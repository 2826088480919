import React from "react";
import styled from "styled-components";
import { fontSize } from "./publicTag";

const MyWpcRecodeElement = ({ title, content, className }) => {
  return (
    <div className={className}>
      <div className="MyWpcRecodeElement-title">{title}</div>
      <div className="MyWpcRecodeElement-items">
        <div className="MyWpcRecodeElement-key">途程號</div>
        <div className="MyWpcRecodeElement-val">
          {content.wpcSN ? content.wpcSN : "暫無資料"}
        </div>
      </div>
      <div className="MyWpcRecodeElement-items">
        <div className="MyWpcRecodeElement-key">站別</div>
        <div className="MyWpcRecodeElement-val">
          {content.wsID ? content.wsID : "暫無資料"}
        </div>
      </div>
      <div className="MyWpcRecodeElement-items">
        <div className="MyWpcRecodeElement-key">站名</div>
        <div className="MyWpcRecodeElement-val">
          {content.wsNM ? content.wsNM : "暫無資料"}
        </div>
      </div>
    </div>
  );
};

const MyWpcRecode = styled(MyWpcRecodeElement)`
  width: 100%;
  height: 120px;
  border: ${({ theme }) => `.5px solid ${theme.borderColor}`};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.pageBg};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .MyWpcRecodeElement-title {
    font-size: ${fontSize.h2};
    width: 80px;
    height: 100%;
    background-color: ${({ theme }) => theme.mainColor};
    color: ${({ theme }) => theme.retext};
    writing-mode: vertical-lr;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: ${({ theme }) => `.1px solid ${theme.borderColor}`};
  }
  .MyWpcRecodeElement-items {
    font-size: ${fontSize.h3};
    flex: 1;
    width: 100%;
    height: 100%;
  }
  .MyWpcRecodeElement-key {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: ${({ theme }) => `.1px solid ${theme.borderColor}`};

    color: ${({ theme }) => theme.text};
  }
  .MyWpcRecodeElement-val {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top: ${({ theme }) => `.1px solid ${theme.borderColor}`};
    border-left: ${({ theme }) => `.1px solid ${theme.borderColor}`};
    white-space: nowrap;
    color: ${({ theme }) => theme.mainColor};
  }
`;

export default MyWpcRecode;
