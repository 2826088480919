import { useState } from "react";
import styled from "styled-components";
import { Select, Row, Col, Button, message } from "antd";
import { modifyRun90pass } from "../../../services/api/api";

const ModifyRun90LightEle = ({ className, setIsModifyRun90Light, twoData }) => {
  const [lightColor, setLightColor] = useState("G");

  const { Option } = Select;

  const call_modifyRun90pass = async () => {
    const res = await modifyRun90pass({ ...twoData, ispass: lightColor });
		if (res.status){
			message.success(res.msg)
			closeModal()
		}else{
			message.error(res.msg)
		}
  };

  const closeModal = () => {
    setIsModifyRun90Light(false);
  };

  return (
    <Row className={className} gutter={[24, 24]}>
      <Col span={24} className="align-c">
        <Select
          className="w-200p"
          size="large"
          value={lightColor}
          onChange={(e) => setLightColor(e)}
        >
          <Option key="G">綠燈</Option>
          <Option key="Y">黃燈</Option>
          <Option key="R">紅燈</Option>
        </Select>
      </Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
          onClick={call_modifyRun90pass}
        >
          送出
        </Button>
      </Col>
    </Row>
  );
};

const ModifyRun90Light = styled(ModifyRun90LightEle)`
  .align-c {
    text-align: center;
  }
  .align-r {
    text-align: right;
  }
  .button {
    border-redius: 5px;
  }
  .w-200p {
    width: 200px;
  }
`;
export default ModifyRun90Light;
