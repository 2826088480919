import styled from "styled-components";

export const Wrapper = styled.div`
  //width: ${({width}) => width};
  width: 100%;
  height: 143px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  width: 100%;
  height: 36px;
  background-color: #E8E8E8;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #707070;
  display: flex;
`
export const HeaderItem = styled.div`
  width: ${props => props.width ? props.width : null};
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 21px;
  background-color: ${props => props.bg ? props.bg : null};
  color: ${props => props.color ? props.color : '#000'};
`
export const Container = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  height: 368px;
  overflow: hidden;
  width: 100%;
  border: .5px solid #707070;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`
export const Container1 = styled.div`
  height: 368px;
  overflow: auto;
  
`
export const Card = styled.div`
  margin-top: -1px;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-top: .5px solid #707070;
`
export const CardItem = styled.div`
  width: ${props => props.width ? props.width : null};
  border-right: 1px solid black;
  display: flex;
  justify-content: ${props => props.align ? props.align : null};
  text-align: ${props => props.align ? props.align : null};
  font-size: 17px;
  background-color: ${props => props.bg ? props.bg : null};
`
export const Box = styled.div`
  display: flex;
  font-weight: 500;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
`
export const Light = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 40px;
  background-color: ${props => props.color ? props.color : null};
`