import { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "antd";

const MyAntdInputElement = (props) => {
  const { label, onChange, placeholder, className, defaultValue, disabled } =
    props;

  const [value, setValue] = useState("");

  const setInputValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className={className}>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={setInputValue}
        bordered={false}
        size="large"
        disabled={disabled}
      />
      <span className="text">{label}</span>
      <span className="line"></span>
    </div>
  );
};

/**
 * @param {String} type - 三種狀態 :
 * standard標準, filled填充 ,outlined 框線
 * @param {String} label - 標籤名
 * @param {String} placeholder - 佔位註解
 * @param {boolean} disabled - 預設false
 */
const MyAntdInput = styled(MyAntdInputElement)`
  position: relative;
  width: ${(props) => (props.width ? props.width + "px" : "120px")};
  height: 56px;
  background-color: ${(props) => {
    return props.disabled
      ? props.theme.disabledColor
      : props.type === "filled" && props.theme.componentsBg;
  }};
  border-radius: 5px;
  display: flex;
  align-items: end;
  margin: 8px;
  input {
    width: 100%;
    height: 50px;
    border: ${(props) =>
      props.type === "outlined" && `1px solid ${props.theme.mainColor}`};
    border-radius: 5px;
  }
  input:hover {
    border: ${(props) => {
      return props.disabled
        ? "none"
        : props.type === "outlined" && `1px solid ${props.theme.mainColor}`;
    }};
  }
  input:focus {
    border: ${(props) =>
      props.type === "outlined" && `1px solid ${props.theme.mainColor}`};
    border-radius: 5px;
  }
  input::placeholder {
    color: transparent;
  }
  input:focus::placeholder {
    transition: 0.3s;
    color: #929292;
  }

  .text {
    color: #bcbcbc;
    padding: 0px 5px;
    position: absolute;
    top: 11px;
    left: 7px;
    display: block;
    pointer-events: none;
    text-align: center;
    transition: 0.3s;
    font-size: 23px;
  }

  input:focus ~ .text {
    top: ${(props) => (props.type === "filled" ? "0px" : "-5px")};
    left: 7px;
    color: ${(props) => props.theme.mainColor};
    font-size: 14px;
    background-color: ${(props) =>
      props.type === "outlined" ? props.theme.pageBg : "transparent"};
    text-align: center;
    height: 20px;
    transition: 0.3s;
  }

  input:not(:placeholder-shown) ~ .text {
    top: ${(props) => (props.type === "filled" ? "0px" : "-5px")};
    left: 7px;
    color: ${(props) => props.theme.mainColor};
    font-size: 14px;
    background-color: ${(props) =>
      props.type === "outlined" ? props.theme.pageBg : "transparent"};
    text-align: center;
    height: 20px;
    transition: 0.3s;
  }

  input:focus ~ .line::after {
    left: 0;
    width: 100%;
  }

  .line {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    display: ${(props) => {
      return props.disabled
        ? "none"
        : props.type === "outlined"
        ? "none"
        : "block";
    }};
    width: calc(100% - 10px);
    height: 1px;
    background: #bcbcbc;
    transition: 0.5s;
    border-radius: 2px;
    pointer-events: none;
  }

  input:hover ~ .line {
    height: 2px;
    background: #919191;
  }

  .line:after {
    content: "";
    position: absolute;
    left: 45%;
    bottom: 0;
    display: block;
    width: 0px;
    height: 2px;
    background-color: ${(props) => props.theme.mainColor};
    transition: 0.5s ease all;
  }
`;

export default MyAntdInput;
