import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Cookies from "universal-cookie";

import WosWo from "../pages/WosWo";
import WosMaterialsControl from "../pages/WosMaterialsControl";
import WosQualityControl from "../pages/WosQualityControl";
import WosChartKanban from "../pages/WosChartKanban";
import WosTableKanban from "../pages/WosTableKanban";
import WosSIP from "../pages/WosSIP";
import WosSOP from "../pages/WosSOP";
import Scada from "../pages/Scada";

import TestAntdInput from "../pages/TestAntdInput";

const Routes = () => {
  let location = useLocation();

  const cookies = new Cookies();

  return (
    <React.Fragment>
      {/* {cookies.get("wswls") && cookies.get("wswls").length === 1 ? (
        <Switch location={location}>
          <Route path="/wosmc" component={WosMaterialsControl} />
          <Route path="/wosqc" component={WosQualityControl} />
          <Route path="/wosck" component={WosChartKanban} />
          <Route path="/wostk" component={WosTableKanban} />
          <Route path="/wossip" component={WosSIP} />
          <Route path="/wossop" component={WosSOP} />
          <Route path="/test" component={TestAntdInput} />
          <Redirect exact from="*" to="/wosck" />
        </Switch>
      ) : (
        <Switch location={location}>
          <Route path="/woswo" component={WosWo} />
          <Redirect exact from="*" to="/woswo" />
        </Switch>
      )} */}
      <Switch>
        <Route path="/wosmc" component={WosMaterialsControl} />
        <Route path="/wosqc" component={WosQualityControl} />
        {/* <Route path="/wosck" component={WosChartKanban} />
        <Route path="/wostk" component={WosTableKanban} />
        <Route path="/wossip" component={WosSIP} />
        <Route path="/wossop" component={WosSOP} /> */}
        <Route path="/Scada" component={Scada} />
        <Route path="/test" component={TestAntdInput} />
        <Route path="/woswo" component={WosWo} />
        <Redirect exact from="*" to="/woswo" />
      </Switch>
    </React.Fragment>
  );
};
export default Routes;
