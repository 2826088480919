import styled from "styled-components";
export const Wrapper = styled.div`
  width: ${({width}) => width};
  height: 143px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`
export const Title = styled.div`
  width: 100%;
  height: 48px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #C1DCE8;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  font-family: MicrosoftJhengHeiBold;
`
export const HeaderContainer = styled.div`
  height: 48px;
  display: flex;
`
export const HeaderTitle = styled.div`
  //width: 174px;
  width: 30%;
  background-color: #CDEEF5;
  line-height: 48px;
  font-size: 24px;
  font-family: MicrosoftJhengHeiBold;
  text-align: center;
  border-right: ${props => props.border ? '.5px solid #B1A9A9' : null } ;
`
export const HeaderValue = styled(HeaderTitle)`
  background-color: #C1DCE8;
  font-size: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-left-radius: ${props => props.radius_L ? '6px' : null};
  border-bottom-right-radius: ${props => props.radius_R ? '6px' : null};
`