import { login, getWsWl } from "../../services/api/api";

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Select, message, Row, Col } from "antd";
import Cookies from "universal-cookie";

import loginSvg from "../../assets/images/login.svg";
import logo from "../../assets/images/logo.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginSvg});
  background-size: cover;
  //background-repeat: no-repeat;
  background-position: center 110px;
  background-position: left;
  background-repeat: no-repeat;
  background-color: rgb(40, 98, 139);
  position: relative;
  //display: flex;
  //justify-content: flex-end;
  //align-self: center;
  //overflow :hidden;

  .logo {
    width: 100%;
  }

  .logoSvg {
    position: absolute;
    top: 150px;
    left: 80px;
    width: 10%;
    height: 140px;
    z-index: 10;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 130px;
  left: 70px;
  width: 650px;
  height: 70%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    width: 550px;
  }
`;
const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 12px;
  font-family: Microsoft JhengHei;
  text-align: center;
`;
const StyledForm = styled(Form)`
  // 客製化輸入框寬度設定100
  .w-100 {
    width: 100%;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }

  .ant-input {
    font-size: 20px;
  }

  .ant-select-selector{
    height: 46px !important;
  }

  .ant-input-suffix {
    font-size: 20px;
    color: #bebebe;
  }

  .ant-input::placeholder {
    color: #bebebe;
    font-size: 20px;
  }

  .ant-select-selector {
    border-radius: 5px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }
`;

function Login() {
  const cookies = new Cookies();

  const [form] = Form.useForm();

  const { Option } = Select;

  let history = useHistory();

  const [buttonLodaing, setButtonLoading] = useState(false);

  const [WsWlOptions, setWsWlOptions] = useState({
    original: [],
    wsOptions: [],
    wlOptions: [],
  });

  const call_getWsWl = async () => {
    const res = await getWsWl();
    if (res.status) {
      let wsOptionArray = [];
      let WsWlOriginal = res.data.wsIDs;
      for (const i of res.data.wsIDs) {
        wsOptionArray.push(
          <Option
            key={i.wsID.wsID}
            value={`${i.wsID.wsID}-${i.wsID.wsNM}`}
          >{`${i.wsID.wsID}-${i.wsID.wsNM}`}</Option>
        );
      }
      setWsWlOptions({
        ...WsWlOptions,
        original: WsWlOriginal,
        wsOptions: wsOptionArray,
      });
    } else {
      message.error(res.msg);
    }
  };

  useEffect(() => {
    call_getWsWl();
  }, []);

  const onFinish = async (values) => {
    setButtonLoading(true);
    const res = await login(values);
    setButtonLoading(false);
    if (res.status) {
      if (res.data.wswls.length < 0){
        message.error("當前工線,無綁定任何IP")
        return;
      }
      // const ws = values.wsID.split("-");
      // const wl = values.wlID.split("-");
      cookies.set("wswls",res.data.wswls)
      cookies.set("wsID", res.data.wswls[0].wsID);
      cookies.set("wsNM", res.data.wswls[0].wsNM);
      cookies.set("wlID", res.data.wswls[0].wlID);
      cookies.set("wlNM", res.data.wswls[0].wlNM);
      message.success(res.msg);
      history.push("/");
    } else {
      message.error(res.msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //form.resetFields();
  };

  return (
    <Wrapper>
      <div className="logoSvg">
        <img src={logo}/>
      </div>
      <Box>
        <div>
          <Title>帳戶登入-設備報工</Title>
          <StyledForm
            form={form}
            className="login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
          >
            {/* <Form.Item
              name="wsID"
              hasFeedback
              rules={[{ required: true, message: "請選擇工站" }]}
            >
              <Select placeholder="請選擇工站">{WsWlOptions.wsOptions}</Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.wsID !== currentValues.wsID) {
                  form.setFieldsValue({ wlID: "" });
                  return prevValues.wsID !== currentValues.wsID;
                }
              }}
            >
              {({ getFieldValue }) => {
                const wsID = getFieldValue("wsID");
                let wlOptionsArray = [];
                for (const x of WsWlOptions.original) {
                  if (wsID === `${x.wsID.wsID}-${x.wsID.wsNM}`) {
                    for (const i of x.wlIDs) {
                      wlOptionsArray.push(
                        <Option key={i.wlID} value={`${i.wlID}-${i.wlNM}`}>
                          {`${i.wlID}-${i.wlNM}`}
                        </Option>
                      );
                    }
                  }
                }
                return (
                  <Form.Item
                    name="wlID"
                    hasFeedback
                    rules={[{ required: true, message: "請選擇工線" }]}
                  >
                    <Select placeholder="請選擇工線">{wlOptionsArray}</Select>
                  </Form.Item>
                );
              }}
            </Form.Item> */}
            <Form.Item
              name="wosID"
              rules={[{ required: true, message: "請輸入正確的用戶名或郵件" }]}
            >
              <Input placeholder="請輸入帳號"/>
            </Form.Item>
            <Form.Item
              name="staffPwd"
              rules={[{ required: true, message: "請輸入正確的密碼" }]}
            >
              <Input.Password
                size="large"
                placeholder="請輸入密碼"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
                loading={buttonLodaing}
              >
                登入
              </Button>
            </Form.Item>
          </StyledForm>
        </div>
      </Box>
    </Wrapper>
  );
}
export default Login;
