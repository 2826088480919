import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import "antd/dist/antd.css";
import zhTW from "antd/lib/locale/zh_TW";
import { ConfigProvider } from "antd";

import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./redux/redusers/";

import "./assets/index.css";
import Home from "./pages/Home";
import Login from "./pages/Login";

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //<=用於Redux devtool chrome插件
);

ReactDOM.render(
  <>
    <ConfigProvider locale={zhTW}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/" component={Home} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  </>,
  document.getElementById("root")
);
reportWebVitals();
