import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { MyWsWlTagBox, MySubtitleH3 } from "../../styled/publicTag";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const WsWlSelect = () => {
  const { Option } = Select;

  const cookies = new Cookies();

  const wsID = cookies.get("wsID");

  const wlID = cookies.get("wlID");

  const wsNM = cookies.get("wsNM");

  const wlNM = cookies.get("wlNM");

  const wswls = cookies.get("wswls");

  const [wswl, setWswl] = useState({
    thisWsWl: {},
    options: [],
    data: [],
  });

  useEffect(() => {
    if (wswls) {
      const options = wswls.map((x) => {
        return (
          <Option key={x.wlID} value={x.wlID}>{`${x.wlID}-${x.wlNM}`}</Option>
        );
      });
      setWswl({ ...wswl, thisWsWl: wswls[0], options: options, data: wswls });
    }
  }, []);

  const changeWl = (e) => {
    const thisIndex = wswl.data
      .map((x) => {
        return x.wlID;
      })
      .indexOf(e);
    cookies.set("wsID", wswl.data[thisIndex].wsID);

    cookies.set("wlID", wswl.data[thisIndex].wlID);

    cookies.set("wsNM", wswl.data[thisIndex].wsNM);

    cookies.set("wlNM", wswl.data[thisIndex].wlNM);

    window.location.assign("/");
  };

  const r_initData = useSelector((allReducers) => allReducers.initData.wsIDs);

  return (
    <React.Fragment>
      <MySubtitleH3>工站 </MySubtitleH3>
      <MyWsWlTagBox>{`${wsID}-${wsNM}`}</MyWsWlTagBox>
    </React.Fragment>
  );
};

export default WsWlSelect;
