import { instance } from "../handlerAPI";
import apiBasic from "../apiBasic";

export const getAllWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllWpc`, {
    params: _params,
  });
};

export const getSingleWpc = (_params) => {
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/mc/getSingleWpc`,
    {
      params: _params,
    }
  );
};

export const ok = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/ok`, data);
};

export const ng = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/ng`, data);
};

export const confirm = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/mc/confirm`,
    data
  );
};

export const cancel = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/mc/cancel`,
    data
  );
};

export const prefixWorkAPI = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/mc/prefixWork`,
    data
  );
};

export const suffixWorkAPI = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/mc/suffixWork`,
    data
  );
};

export const getNgitem = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getNgitem`);
};

export const addNg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/addNg`, data);
};

export const getAllNg = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllNg`, {
    params: _params,
  });
};

export const getOkitem = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getOkitem`);
};

export const addOk = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/addOk`, data);
};

export const getAllOk = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllOk`, {
    params: _params,
  });
};
