import React from 'react';
import styled from "styled-components";
//component
import WorkOrder from './childComponents/WorkOrder';
import ProductionNumber from './childComponents/ProductionNumber';
import List from './childComponents/List';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30PX;
  //padding-bottom: 30px;
`

const CustomTable = () => {

  return(
    <Wrapper>
      <div style={{width: '35%'}}>
        <WorkOrder />
      </div>
      <div style={{width: '35%'}}>
        <ProductionNumber width='30%' />
      </div>
      <div style={{width: '35%'}}>
        <List width='35%' />
      </div>
     
    </Wrapper>
  )
}

export default CustomTable;