import styled from "styled-components";
import Title from "../Text";
import { useState, useEffect } from "react";
import { Tabs, Space, Select, Divider } from "antd";
import checkUndefined from "../../utility/checkUndefined";
import { MyDisabled } from "../../styled/publicTag";
import { chooseWpcDocuments } from "../../services/api/api";

const optionJson = {
  sopVer_1: [],
  sipVer_1: [],
  sip3Ver_1: [],
  sopVer_2: [],
  sipVer_2: [],
  sip3Ver_2: [],
  sopVer_3: [],
  sipVer_3: [],
  sip3Ver_3: [],
};

const SopSipVerElement = ({
  className,
  singleWpc = {},
  setSopSipVer,
  disabled = Boolean,
  sopSipOption = optionJson,
}) => {
  const defaultVerJson = {
    sopVer_1: "",
    sipVer_1: "",
    sip3Ver_1: "",
    sopVer_2: "",
    sipVer_2: "",
    sip3Ver_2: "",
    sopVer_3: "",
    sipVer_3: "",
    sip3Ver_3: "",
    sopID_1: "",
    sipID_1: "",
    sip3ID_1: "",
    sopID_2: "",
    sipID_2: "",
    sip3ID_2: "",
    sopID_3: "",
    sipID_3: "",
    sip3ID_3: "",
  };

  const { TabPane } = Tabs;

  const [sopsipList, setSopSipList] = useState(defaultVerJson);

  useEffect(() => {
    setSopSipVer(sopsipList);
  }, [sopsipList]);

  useEffect(() => {
    // console.log(singleWpc.thisWpc.woN)
    // console.log("pdtlot",singleWpc.thisWpc.pdtlot)
    const call_chooseWpcDocuments = async () => {
      const res = await chooseWpcDocuments(singleWpc.thisWpc);
      if (res.status) {
        let newJson = defaultVerJson;
        for (const [index, value] of res.data.entries()) {
          newJson[`sopVer_${index + 1}`] = value.sopVer;
          newJson[`sipVer_${index + 1}`] = value.sip4Ver;
          newJson[`sip3Ver_${index + 1}`] = value.sip3Ver;
          newJson[`sopID_${index + 1}`] = value.sopID;
          newJson[`sipID_${index + 1}`] = value.sip4ID;
          newJson[`sip3ID_${index + 1}`] = value.sip3ID;
        }
        setSopSipList(newJson);
      }
    };

    if (singleWpc.thisWpc.pdtlot) {
      call_chooseWpcDocuments();
    } else {
      let newJson = defaultVerJson;
      newJson["sopID_1"] = singleWpc.thisWpc.sopID_1;
      newJson["sipID_1"] = singleWpc.thisWpc.sipID_1;
      newJson["sip3ID_1"] = singleWpc.thisWpc.sip3ID_1;
      newJson["sopID_2"] = singleWpc.thisWpc.sopID_2;
      newJson["sipID_2"] = singleWpc.thisWpc.sipID_2;
      newJson["sip3ID_2"] = singleWpc.thisWpc.sip3ID_2;
      newJson["sopID_3"] = singleWpc.thisWpc.sopID_3;
      newJson["sipID_3"] = singleWpc.thisWpc.sipID_3;
      newJson["sip3ID_3"] = singleWpc.thisWpc.sip3ID_3;
      setSopSipList(newJson);
    }
  }, [singleWpc.thisWpc.pdtlot]);

  return (
    <Tabs className={className}>
      {/* sop/sip 01 */}
      <TabPane tab="第一道反應" size="large" key="第一道反應">
        <MyDisabled disabled={disabled}>
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SOP文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sopID_1
                ? checkUndefined(singleWpc.thisWpc.sopID_1)
                : sopsipList.sopID_1}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sopVer_1}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sopVer_1: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sopVer_1}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SIP{" "}文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sipID_1
                ? checkUndefined(singleWpc.thisWpc.sipID_1)
                : sopsipList.sipID_1}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sipVer_1}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sipVer_1: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sipVer_1}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              監控文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sip3ID_1
                ? checkUndefined(singleWpc.thisWpc.sip3ID_1)
                : sopsipList.sip3ID_1}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sip3Ver_1}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sip3Ver_1: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sip3Ver_1}
            </Select>
            <Title level="h3">版</Title>
          </Space>
        </MyDisabled>
      </TabPane>

      {/* sop/sip 02 */}
      <TabPane tab="第二道反應" size="large" key="第二道反應">
        <MyDisabled disabled={disabled}>
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SOP文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sopID_2
                ? checkUndefined(singleWpc.thisWpc.sopID_2)
                : sopsipList.sopID_2}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sopVer_2}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sopVer_2: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sopVer_2}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SIP{" "}文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sipID_2
                ? checkUndefined(singleWpc.thisWpc.sipID_2)
                : sopsipList.sipID_2}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sipVer_2}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sipVer_2: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sipVer_2}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              監控文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sip3ID_2
                ? checkUndefined(singleWpc.thisWpc.sip3ID_2)
                : sopsipList.sip3ID_2}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sip3Ver_2}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sip3Ver_2: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sip3Ver_2}
            </Select>
            <Title level="h3">版</Title>
          </Space>
        </MyDisabled>
      </TabPane>

      {/* sop/sip 03 */}
      <TabPane tab="第三道反應" size="large" key="第三道反應">
        <MyDisabled disabled={disabled}>
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SOP文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sopID_3
                ? checkUndefined(singleWpc.thisWpc.sopID_3)
                : sopsipList.sopID_3}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sopVer_3}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sopVer_3: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sopVer_3}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              SIP{" "}文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sipID_3
                ? checkUndefined(singleWpc.thisWpc.sipID_3)
                : sopsipList.sipID_3}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sipVer_3}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sipVer_3: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sipVer_3}
            </Select>
            <Title level="h3">版</Title>
          </Space>
          <Divider />
          <Space align="center" size={40}>
            <Title level="h3" className="w-120 tl ml-15px">
              監控文編
            </Title>
            <Title level="h3">|</Title>
            <Title level="h3" className="w-400">
              {singleWpc.thisWpc.sip3ID_3
                ? checkUndefined(singleWpc.thisWpc.sip3ID_3)
                : sopsipList.sip3ID_3}
            </Title>
            <Title level="h3">|</Title>
            <Select
              size="large"
              value={sopsipList.sip3Ver_3}
              onChange={(e) => {
                setSopSipList({ ...sopsipList, sip3Ver_3: e });
              }}
              className="w-120 select-border-bottom"
            >
              {sopSipOption.sip3Ver_3}
            </Select>
            <Title level="h3">版</Title>
          </Space>
        </MyDisabled>
      </TabPane>
    </Tabs>
  );
};

const SopSipVer = styled(SopSipVerElement)`
  .ant-space {
    width: 100% !important;
  }

  /* 控制分割線margin */
  .ant-divider-horizontal {
    margin: 15px 0px;
  }
  .tl {
    text-align: center;
  }
  .ml-15px {
    margin-left: 15px;
  }
  .ml-20px {
    margin-left: 20px;
  }
  .w-120 {
    width: 120px;
  }
  .w-400 {
    width: 400px;
  }
  .select-border-bottom {
    border: 1px solid transparent;
    border-bottom: ${({ theme }) => `2px solid ${theme.mainColor}`};
  }
`;

export default SopSipVer;
