import CustomTable from "../../../components/antdCustom/CustomTable";
import { Row, Col, Button, message } from "antd";
import styled from "styled-components";
import { useEffect } from "react";
import checkUndefined from "../../../utility/checkUndefined";
import { checkRun81 } from "../../../services/api/scada";

const CheckFeedCEle = ({
  checkFeedCData,
  setIsShowModal,
  className,
  scadaData,
}) => {
  const checkCol = [
    {
      title: "查核點1:原料",
      key: "setcheck1",
      dataIndex: "setcheck1",
      width: "300px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "查核點2:投量(參數數值)",
      key: "setcheck2",
      dataIndex: "setcheck2",
      width: "300px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "查核點3:時間間隔(分鐘)",
      key: "setcheck3",
      dataIndex: "setcheck3",
      width: "300px",
      render: (item) => checkUndefined(item),
    },
  ];

  const call_checkRun81 = async (row) => {
    const res = await checkRun81(row);
    if (res.status) {
      setIsShowModal(false);
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };

  //

  return (
    <Row gutter={[24, 24]} className={className}>
      <Col span={24}>
        <CustomTable
          columns={checkCol}
          dataSource={[checkFeedCData]}
          pagination={false}
        />
      </Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
          onClick={() => call_checkRun81({ ...checkFeedCData, ...scadaData })}
        >
          確認
        </Button>
      </Col>
    </Row>
  );
};

const CheckFeedC = styled(CheckFeedCEle)`
  .align-r {
    text-align: right;
  }
  .button {
    border-radius: 5px;
  }
`;

export default CheckFeedC;
