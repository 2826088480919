import Title from "../Text";
import styled from "styled-components";
import CustomTable from "../antdCustom/CustomTable";
import Light from "../Light";
import { useEffect } from "react";
import { Button } from "antd";

const getLight = (light) => {
  switch (light) {
    case "G":
      return <Light size="20px" type="success" />;
    case "Y":
      return <Light size="20px" type="warning" />;
    case "W":
      return <Light size="20px" disabled />;
    case "R":
      return <Light size="20px" type="error" />;
    default:
      return <Light size="20px" disabled />;
  }
};

const pdtlotCol = [
  { title: "批號", key: "pdtlot", dataIndex: "pdtlot", width: "200px" },
  {
    title: "目前槽號",
    key: "lifeF",
    dataIndex: "lifeF",
    width: "200px",
    align: "center",
  },
  {
    title: "F1品檢",
    key: "f1pass",
    dataIndex: "f1pass",
    width: "100px",
    align: "center",
    render: (item) => getLight(item),
  },
  {
    title: "F2品檢",
    key: "f2pass",
    dataIndex: "f2pass",
    width: "100px",
    align: "center",
    render: (item) => getLight(item),
  },
  {
    title: "F3品檢",
    key: "f3pass",
    dataIndex: "f3pass",
    width: "100px",
    align: "center",
    render: (item) => getLight(item),
  },
];

const PDTlotProcessElement = ({
  className,
  lotdata,
  setIsQualityInspection,
  processManagementButton,
}) => {
  return (
    <div className={className}>
      <div className="title">
        <Title level="h2" type="main">
          批號進程
        </Title>
        <Button
          type="primary"
          size="large"
          disabled={processManagementButton.button.abcReport}
          onClick={() => {
            setIsQualityInspection(true);
          }}
        >
          品檢管理
        </Button>
      </div>
      <CustomTable
        dataSource={lotdata}
        columns={pdtlotCol}
        scroll={{ x: "max-content", y: 210 }}
        pagination={false}
      />
    </div>
  );
};

const PDTlotProcess = styled(PDTlotProcessElement)`
  display: flex;
  flex-direction: column;
  height: 290px;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export default PDTlotProcess;
