import { useState } from "react";
import styled from "styled-components";
import { Select, Row, Col, Space, Button, message } from "antd";
import {modifyRun91pass}from "../../../services/api/api";

const ModifyLightModalEle = ({ className, setIsModifyLightModal,thisRun91 }) => {
  const [lightColor, setLightColor] = useState("G");

  const { Option } = Select;

  const closeModal = () => {
    setIsModifyLightModal(false);
  };

  const call_modifyRun91pass = async() =>{
    const res = await modifyRun91pass({...thisRun91,ispass:lightColor})
    if (res.status){
      message.success(res.msg)
      closeModal()
    }else{
      message.error(res.msg)
    }
  }

  return (
    <Row className={className} gutter={[24, 24]}>
      <Col span={24} className="align-c">
        <Select
          className="w-200p"
          size="large"
          value={lightColor}
          onChange={(e) => setLightColor(e)}
        >
          <Option key="G">綠燈</Option>
          <Option key="Y">黃燈</Option>
          <Option key="R">紅燈</Option>
        </Select>
      </Col>
      <Col span={24} className="align-r">
        <Button
          size="large"
          type="primary"
          className="button"
          onClick={call_modifyRun91pass}
        >
          送出
        </Button>
      </Col>
    </Row>
  );
};

const ModifyLightModal = styled(ModifyLightModalEle)`
  .align-c {
    text-align: center;
  }
  .align-r {
    text-align: right;
  }
  .button {
    border-redius: 5px;
  }
  .w-200p{
    width:200px;
  }
`;
export default ModifyLightModal;
