import React from "react";
import styled from "styled-components";
import { fontSize } from "./publicTag";

const MyButton1 = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledColor
      : ({ theme }) => theme.mainColor};
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MyWhiteButton1 = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;
  border: 0.5px solid #f5f5f5;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#D0D0D0"};
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : ({ theme }) => theme.text};
  position: relative;
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MyButton2 = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledColor
      : ({ theme }) => theme.Color1};
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MyButton3 = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;

  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledColor
      : ({ theme }) => theme.Color2};
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MyErrorButton = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #ed6c6c;
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#d86363"};
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MyWarningButton = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;

  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #ed6c6c;
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#EBB549"};
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MySuccessButton = styled.button`
  font-size: ${fontSize.h2};
  white-space: nowrap;

  border: 1px solid transparent;
  border-radius: 5px;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #ed6c6c;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#699f5b"};
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : "rgba(255,255,255,0.8)"};
  position: relative;
  &:after {
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
`;

const MySuccessIconButtonElement = ({ icon, className, onClick, disabled }) => {
  return (
    <div className={className} onClick={disabled ? null : onClick}>
      <div className="MySuccessIconButton-icon">{icon}</div>
      <div className="MySuccessIconButton-text">良品登錄</div>
    </div>
  );
};

const MySuccessIconButton = styled(MySuccessIconButtonElement)`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 10px;
  touch-action: manipulation;
  cursor: pointer;
  text-align: center;
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : ({ theme }) => theme.retext};
  font-size: ${fontSize.h2};
  display: flex;
  align-items: center;
  background-color: #699f5b;
  position: relative;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#699f5b"};
  &:after {
    width: 100%;
    height: 100%;
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
  .MySuccessIconButton-text {
    width: 100%;
    height: 100%;
  }
  .MySuccessIconButton-icon {
    border-radius: 10px 0px 0px 10px;
    font-size: 32px;
    padding: 15px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.disabled ? ({ theme }) => theme.disabledColor : "#448933"};
  }
  .MySuccessIconButton-icon > svg {
    color: ${(props) =>
      props.disabled
        ? ({ theme }) => theme.disabledTextColor
        : ({ theme }) => theme.retext};
  }
`;

const MyErrorIconButtonElement = ({ icon, className, onClick, disabled }) => {
  return (
    <div className={className} onClick={disabled ? null : onClick}>
      <div className="MySuccessIconButton-icon">{icon}</div>
      <div className="MySuccessIconButton-text">不良品登錄</div>
    </div>
  );
};

const MyErrorIconButton = styled(MyErrorIconButtonElement)`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 10px;
  touch-action: manipulation;
  cursor: pointer;
  text-align: center;
  color: ${(props) =>
    props.disabled
      ? ({ theme }) => theme.disabledTextColor
      : ({ theme }) => theme.retext};
  font-size: ${fontSize.h2};
  display: flex;
  align-items: center;
  background-color: #699f5b;
  position: relative;
  background-color: ${(props) =>
    props.disabled ? ({ theme }) => theme.disabledColor : "#d86363"};
  &:after {
    width: 100%;
    height: 100%;
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: "";
    border: 1px solid #a09f9f;
  }
  .MySuccessIconButton-text {
    width: 100%;
    height: 100%;
  }
  .MySuccessIconButton-icon {
    border-radius: 10px 0px 0px 10px;
    font-size: 32px;
    padding: 15px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.disabled ? ({ theme }) => theme.disabledColor : "#c54141"};
  }
  .MySuccessIconButton-icon > svg {
    color: ${(props) =>
      props.disabled
        ? ({ theme }) => theme.disabledTextColor
        : ({ theme }) => theme.retext};
  }
`;

const MyButton = {
  MyButton1,
  MyButton2,
  MyButton3,
  MyWhiteButton1,
  MyErrorButton,
  MySuccessButton,
  MySuccessIconButton,
  MyErrorIconButton,
  MyWarningButton,
};

export default MyButton;
