import { useState, useEffect } from "react";
import styled from "styled-components";
import Title from "../../Text";
import {
  Row,
  Col,
  Button,
  message,
  Form,
  Input,
  Space,
  Select,
  InputNumber,
} from "antd";
import { addRun92 } from "../../../services/api/api";

const AddRun92CommentElement = ({
  className,
  setIsAddRun92Modal,
  thisRun91,
}) => {
  const [form] = Form.useForm();

  const { Option } = Select;

	const call_addRun92 = async(newJ) =>{
		const res = await addRun92(newJ)
		if (res.status){
      console.log(res)
			message.success(res.msg)
			closeModal()
		}else{
			message.error(res.msg)
		}
	}

  const onFinish = async (values) => {
		const newJ = {...thisRun91, ...values}
		call_addRun92(newJ)
  };

  const onFinishFailed = async (values) => {
    console.log("NO", values);
  };

  const closeModal = () => {
    setIsAddRun92Modal(false);
  };

  return (
    <Row className={className}>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        size="large"
        layout="vertical"
        initialValues={thisRun91}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="inspN" label={<Title size="h3">檢驗單號</Title>}>
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="sipno" label={<Title size="h3">SIP項次</Title>}>
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="sipitem" label={<Title size="h3">檢驗項目</Title>}>
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="inspnote"
              label={<Title size="h3">檢驗要點(計數屬性)</Title>}
            >
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="drawingno"
              label={<Title size="h3">監控內容/圖號</Title>}
            >
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="insptype"
              label={<Title size="h3">驗收型態</Title>}
            >
              <Input disabled className="w-100" />
            </Form.Item>
          </Col>
          {thisRun91.insptype === "計數型" ? (
            <>
              <Col span={12}>
                <Form.Item name="okC" label={<Title size="h3">良品數量</Title>}>
                  <InputNumber className="w-100" min={0}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="ngC" label={<Title size="h3">不良品數</Title>}>
                  <InputNumber className="w-100" min={0}/>
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={12}>
              <Form.Item
                name="realvalue"
                label={<Title size="h3">量測值</Title>}
                rules={[{ required: true, message: "請輸入量測值" }]}
              >
                <InputNumber className="w-100" min={0}/>
              </Form.Item>
            </Col>
          )}
          <Col span={24} className="align-r">
            <Space size={25}>
              <Button size="large" className="button" onClick={closeModal}>
                取消
              </Button>
              <Button
                size="large"
                type="primary"
                className="button"
                // onClick={closeModal}
                htmlType="submit"
              >
                送出
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

const AddRun92Comment = styled(AddRun92CommentElement)`
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .align-r {
    text-align: right;
  }
  .button {
    border-redius: 5px;
  }
  .w-100 {
    width: 100%;
  }
`;

export default AddRun92Comment;
