import { useState, useEffect } from "react";
import apiBasic from "../services/apiBasic";
/**
 * @return {object} websocket data object
 * @return {function} 帶入參數
    url: string,
    apiName: string,
    params: string,
 * @return {function}  
    決定WebSocket開啟或關閉 true & false
  *@example
  *
  * ```js
*   changeOpenWorkSocketParams({
      url: "localhost9000",
      apiName: "openSocket",
      params: `UUID=${singleWpcRef.current.thisWpc.UUID}`,
    });
    setOpenWorkSocket(false);
  * ```
  * 
 */

function closeCode(code) {
    const codes = {
        1000: '1000 CLOSE_NORMAL',
        1001: '1001 CLOSE_GOING_AWAY',
        1002: '1002 CLOSE_PROTOCOL_ERROR',
        1003: '1003 CLOSE_UNSUPPORTED',
        1004: '1004 CLOSE_RETAIN',
        1005: '1005 CLOSE_NO_STATUS',
        1006: '1006 CLOSE_ABNORMAL',
        1007: '1007 UNSUPPORTED_DATA',
        1008: '1008 POLICY_VIOLATION',
        1009: '1009 CLOSE_TOO_LARGE',
        1010: '1010 MISSING_EXTENSION',
        1011: '1011 INTERNAL_ERROR',
        1012: '1012 SERVICE_RESTART',
        1013: '1013 TRY_AGAIN_LATER',
        1014: '1014 CLOSE_RETAIN',
        1015: '1015 TLS_HANDSHAKE'
    }
    let error = codes[code];
    if (error === undefined) error = '0000 UNKNOWN_ERROR 未知错误';
    return error;
}
const useWebSocket = () => {
  // 紀錄重新連線次數
  let [reconnect, setReconnect] = useState(0);
  //判斷是否有新 params 重新連線 webSocket
  let [isUpdateParams, setIsUpdateParams] = useState(false);

  let [ws, setWS] = useState();
  let [data, setData] = useState({ status: false, data: {}, msg: "" });
  let [isOpen, setIsOpen] = useState(false);
  let [apiParams, setApiParams] = useState({
    url: "",
    apiName: "",
    params: "",
  });

  let createWebSocket = () => {
    const conn = new WebSocket(
      `${apiBasic.conn === "http" ? "ws" : "wss"}://${apiParams.url}/api/${
        apiParams.apiName
      }?${apiParams.params}`
    );
    return conn;
  };

  let changeParams = ({ url, apiName, params }) => {
    setIsUpdateParams(true);
    setApiParams({
      url: url,
      apiName: apiName,
      params: params,
    });
  };

  useEffect(() => {
    if (ws && isUpdateParams && isOpen) {
      const webSocketPath = createWebSocket();
      setWS(webSocketPath);
      setReconnect(0);
      setIsUpdateParams(false);
    } else {
      console.log("change Params ok , isOpen = false");
    }
  }, [apiParams]);

  useEffect(() => {
    if (apiParams.url === "" || apiParams.apiName === "") {
      setData({ status: false, data: "url or apiName is empty" });
      return;
    }
    if (!isOpen) {
      ws && ws.close();
    } else {
      const webSocketPath = createWebSocket();
      setWS(webSocketPath);
    }
  }, [isOpen]);

  let isOpenSwitch = (val) => {
    val ? setIsOpen(true) : setIsOpen(false);
  };

  useEffect(() => {
    let resCodeStatus = (res) => {
      switch (res.status) {
        case 200:
          const data = res.data;
          setData({ status: true, data: data, msg: "資料獲取成功" });
          break;
        case 403:
          setData({ status: false, data: {}, msg: "403憑證過期返回登入頁面" });
          break;
        case 404:
          setData({
            status: false,
            data: {},
            msg: "404查無資料，請確認資料是否正確",
          });
          break;
        default:
          setData({
            status: false,
            data: {},
            msg: "502連線意外中斷，請嘗試重新連線",
          });
          break;
      }
    };
    try {
      if (ws) {
        ws.onopen = () => {
          console.log("ws",ws)
          console.log(
            `webSocket連線開啟 ${apiBasic.conn === "http" ? "ws" : "wss"}://${
              apiParams.url
            }/api/${apiParams.apiName}`
          );
        };
        ws.onmessage = (event) => {
          let res = JSON.parse(event.data);
          resCodeStatus(res);
        };
        ws.onclose = (e) => {
          setData({ status: false, data: {}, msg: "webSocket已關閉" });
          // setReconnect(reconnect + 1);
          console.log("ws",closeCode(ws))
          console.log(
            `webSocket連線關閉 ${apiBasic.conn === "http" ? "ws" : "wss"}://${
              apiParams.url
            }/api/${apiParams.apiName}`
          );
        };
      } else {
        console.log("ws else ==> ", ws);
      }
    } catch (e) {
      setData({ status: false, data: {}, msg: "webSocke意外關閉連線" });
      setReconnect(reconnect + 1);
    }
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  useEffect(() => {
    console.log("reconnectreconnect",reconnect)
    //斷線重連3次
    if (reconnect < 3 && isOpen === true && data.status === false) {
      console.log(
        `webSocket嘗試重新連線${reconnect} ${
          apiBasic.conn === "http" ? "ws" : "wss"
        }://${apiParams.url}/api/${apiParams.apiName}`
      );
      const webSocketPath = createWebSocket();
      setWS(webSocketPath);
      setReconnect(0);
    }
  }, [reconnect]);

  return [data, changeParams, isOpenSwitch];
};

export default useWebSocket;
