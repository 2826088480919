import React, { useState, useEffect } from "react";
import { Row, Col, Table, message } from "antd";
import {
  MyComponent,
  MyH4,
  MyMainColorH2,
  MyMainColorH1,
  MyThemeH1,
  MyTriangle,
} from "../../styled/publicTag";
import { Modal, Button } from "antd";
import WOI from "../../styled/workOrderInformation";
import moment from "moment";
import timeGap from "../../utility/timeGap";
import MyIcon from "../Icon/pageIcon";
import ProductivityPopoverSVG from "../ProductivityTimeLiine/ProductivityPopoverSVG";

const Productivity = (data) => {
  const wpcCol = [
    {
      title: "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
    },
    {
      title: "插工碼",
      dataIndex: "addSN",
      key: "addSN",
    },
    {
      title: "過站碼",
      dataIndex: "sqc",
      key: "sqc",
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
    },
    {
      title: "下線碼",
      dataIndex: "offfix",
      key: "offfix",
    },
    {
      title: "多單碼",
      dataIndex: "woMix",
      key: "woMix",
    },
    {
      title: "線別碼",
      dataIndex: "lineMix",
      key: "lineMix",
    },
  ];

  const [productivityData, setProductivityData] = useState({
    openTime: "",
    pdtRate: 0.0,
    allTime: "",
  });

  const [isModal, setIsModal] = useState(false);

  const [svgModal, setSvgModal] = useState(false);

  function time() {
    const now = new Date();

    const after = new Date(data.data.materialT);
    return timeGap(now, after);
  }

  useEffect(() => {
    let timer = "";
    if (
      data.data !== undefined &&
      data.data !== null &&
      Object.getOwnPropertyNames(data.data).length !== 0
    ) {
      if (data.data.materialFinishT === "暫無資料") {
        //沒有物控完成時間
        timer = setInterval(() => {
          setProductivityData({
            openTime: data.data.materialT,
            pdtRate: data.data.pdtRate,
            allTime: "0天0時0分0秒",
          });
        }, 1000);
      } else {
        //有物控完成時間
        setProductivityData({
          openTime: data.data.materialT,
          pdtRate: data.data.pdtRate,
          allTime: Array.isArray(data.data.materialTotalT)?`${data.data.materialTotalT[0]}天${data.data.materialTotalT[1]}小時${data.data.materialTotalT[2]}分${data.data.materialTotalT[3]}秒`:"0天0時0分0秒",
        });
      }
    } else {
      clearInterval(timer);
      setProductivityData({
        openTime: "",
        pdtRate: 0.0,
        allTime: "暫無數據",
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [data.data]);

  const boxStyle = {
    textAlign: "center",
    width: "100%",
    height: "100px",
  };

  const iconBoxStyle = {
    width: "36px",
    height: "100%",
    position: "relative",
    display: "inline-block",
    margin: "0px 10px",
  };

  const iconStyle = {
    height: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
  };

  const headerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  };

  const lightBox = {
    width: "110px",
    height: "40px",
    backgroundColor: "rgba(255, 243, 210, 1)",
    lineHeight: "40px",
    borderRadius: "10px",
    textAlign: "center",
    border: ".5px solid rgba(0, 0, 0, 0.1)",
    boxShadow:
      "0px 10px 10px -15px #000"
  };

  return (
    <MyComponent className="productivity">
      <Row justify="center" align="middle" gutter={[24, 0]}>
        <Col span={24}>
          <div style={headerStyle}>
            <MyMainColorH1>生產計時</MyMainColorH1>
            <div style={lightBox}>
              <MyH4
                onClick={() => setIsModal(true)}
                style={{ cursor: "pointer" }}
              >
                途碼總覽
              </MyH4>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <WOI.WOIMainBoxItem style={boxStyle}>
            <WOI.WOIMainBoxItemTitle style={{ justifyContent: "center" }}>
              <span style={iconBoxStyle}>
                <MyIcon.ClockDesk style={iconStyle} />
              </span>
              物控登入時間
            </WOI.WOIMainBoxItemTitle>
            <WOI.WOIMainBoxItemContent>
              <MyMainColorH2>
                {data.data.materialT ? data.data.materialT : "暫無數據"}
              </MyMainColorH2>
            </WOI.WOIMainBoxItemContent>
          </WOI.WOIMainBoxItem>
        </Col>
        <Col span={12}>
          <WOI.WOIMainBoxItem style={boxStyle}>
            <WOI.WOIMainBoxItemTitle style={{ justifyContent: "center" }}>
              <span style={iconBoxStyle}>
                <MyIcon.StopWatch style={iconStyle} />
              </span>
              物控完成時間
            </WOI.WOIMainBoxItemTitle>
            <WOI.WOIMainBoxItemContent>
              <MyMainColorH2>
                {data.data.materialFinishT
                  ? data.data.materialFinishT
                  : "暫無數據"}
              </MyMainColorH2>
            </WOI.WOIMainBoxItemContent>
          </WOI.WOIMainBoxItem>
        </Col>
        <Col span={24}>
          <WOI.WOIMainBoxItem style={boxStyle}>
            <WOI.WOIMainBoxItemTitle
              style={{ justifyContent: "center", position: "relative" }}
            >
              <div
                style={{
                  positon: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                  left: "0",
                  margin: "auto",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={iconBoxStyle}>
                  <MyIcon.HourGlass style={iconStyle} />
                </span>
                物控總時長
              </div>
              <MyTriangle
                onClick={() => Object.getOwnPropertyNames(data.data).length !== 0?setSvgModal(true):message.error('請先選擇工單')}
                style={{
                  positon: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                }}
              />
            </WOI.WOIMainBoxItemTitle>
            <WOI.WOIMainBoxItemContent>
              <MyMainColorH2>{productivityData.allTime}</MyMainColorH2>
            </WOI.WOIMainBoxItemContent>
          </WOI.WOIMainBoxItem>
        </Col>
      </Row>
      <Modal
        title={<MyThemeH1>途碼總覽</MyThemeH1>}
        visible={isModal}
        onCancel={() => setIsModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            type="primary"
            onClick={() => {
              setIsModal(false);
            }}
          >
            確認
          </Button>,
        ]}
        width={"1000px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={wpcCol}
          dataSource={[{ ...data.data, key: "prefix456" }]}
          pagination={false}
        />
      </Modal>
      <Modal
        title={<MyThemeH1>生產計時詳情</MyThemeH1>}
        visible={svgModal}
        onCancel={() => setSvgModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            type="primary"
            onClick={() => {
              setSvgModal(false);
            }}
          >
            確認
          </Button>,
        ]}
        width={"1300px"}
        centered
        forceRender
      >
        <ProductivityPopoverSVG data={data.data} />
      </Modal>
    </MyComponent>
  );
};

export default Productivity;
