import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const StyleSVG = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: 100px;
  height: 700px; */

  .Propeller{
    animation: roateImg .5s linear infinite;
    transform: rotateZ(30px);
    transform-box: fill-box;
    transform-origin: center;
  }
  /* 順時針轉 */
  @keyframes roateImg {
    0% {
      transform: rotate3d(0, 1, 0.95, 0deg);
    }
    
    100% {
      transform: rotate3d(0, 5, 0.95, 360deg);
    }
  }

  .pointer {
    cursor: pointer;
  }

  .airtube {
    transform: ${({airtube}) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (8 - 0)/245;  // 

      if(airtube >= 8) {
        return `rotate(15deg)`
      }

      if(airtube <= 0) {
        return `rotate(-230deg)`
      }

      let target = parseInt((airtube/value).toFixed(1));

      return `rotate(${-230 + target}deg)`
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .o2tube {
    transform: ${({o2tube}) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0)/245;  // 

      if(o2tube >= 4) {  // 超過最大值時顯示最大值
        return `rotate(15deg)`
      }
      if(o2tube <= 0) {  // 超過最小值時顯示最小值
        return `rotate(-230deg)`
      }

      let target = parseInt((o2tube/value).toFixed(1));

      return `rotate(${-230 + target}deg)`
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .n2tube {
    transform: ${({n2tube}) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (8 - 0)/245;  // 

      if(n2tube >= 8) {  // 超過最大值時顯示最大值
        return `rotate(15deg)`
      }
      if(n2tube <= 0) {  // 超過最小值時顯示最小值
        return `rotate(-230deg)`
      }

      let target = parseInt((n2tube/value).toFixed(1));

      return `rotate(${-230 + target}deg)`
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .cwpipe {
    transform: ${({cwpipe}) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0)/245;  // 

      if(cwpipe >= 4) {  // 超過最大值時顯示最大值
        return `rotate(15deg)`
      }
      if(cwpipe <= 0) {  // 超過最小值時顯示最小值
        return `rotate(-230deg)`
      }

      let target = parseInt((cwpipe/value).toFixed(1));

      return `rotate(${-230 + target}deg)`
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .wpipe {
    transform: ${({wpipe}) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0)/245;  // 

      if(wpipe >= 4) {  // 超過最大值時顯示最大值
        return `rotate(15deg)`
      }
      if(wpipe <= 0) {  // 超過最小值時顯示最小值
        return `rotate(-230deg)`
      }

      let target = parseInt((wpipe/value).toFixed(1));

      return `rotate(${-230 + target}deg)`
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }
 
  .pointerx {
    animation: roatePointer 3s linear infinite;
    transform-box: fill-box;
    transform-origin: top left;
  }

  @keyframes roatePointer {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}
  
`

const SingleScada = ({s_showData, s_sink}) => {
  useEffect(()=>{
    console.log('s_showData = ',  s_showData)
  },[s_showData])
  return (
    <StyleSVG>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="377" height="601" viewBox="0 0 377 601">
      <defs>
          <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#58788a" />
            <stop offset="0.17" stopColor="#476373" />
            <stop offset="0.388" stopColor="#374f5d" />
            <stop offset="0.597" stopColor="#2e4450" />
            <stop offset="0.788" stopColor="#2b404c" />
          </linearGradient>
          <linearGradient id="b" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7098af" />
            <stop offset="0.033" stopColor="#6b92a9" />
            <stop offset="0.234" stopColor="#56778a" />
            <stop offset="0.429" stopColor="#476474" />
            <stop offset="0.617" stopColor="#3e5867" />
            <stop offset="0.788" stopColor="#3b5563" />
          </linearGradient>
          <linearGradient id="e" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0.217" stopColor="#fff" />
            <stop offset="1" stopColor="#7d7d7d" />
          </linearGradient>
          <linearGradient id="f" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0.27" stopColor="#fff" />
            <stop offset="0.551" stopColor="#838383" />
            <stop offset="0.683" stopColor="#4e4e4e" />
          </linearGradient>
          <linearGradient id="h" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fff" />
            <stop offset="0.053" stopColor="#ebebeb" />
            <stop offset="0.198" stopColor="#bcbcbc" />
            <stop offset="0.342" stopColor="#949494" />
            <stop offset="0.484" stopColor="#757575" />
            <stop offset="0.621" stopColor="#5f5f5f" />
            <stop offset="0.753" stopColor="#525252" />
            <stop offset="0.873" stopColor="#4e4e4e" />
          </linearGradient>
          <linearGradient id="i" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0.137" stopColor="#da950b" />
            <stop offset="0.832" stopColor="#ffce2c" />
          </linearGradient>
          <linearGradient id="y" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#aeaeae" />
            <stop offset="1" stopColor="#575757" />
          </linearGradient>
          <linearGradient id="ag" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fff" />
            <stop offset="0.27" stopColor="#d8d8d8" />
            <stop offset="0.619" stopColor="#cfcfcf" />
            <stop offset="1" stopColor="#979797" />
          </linearGradient>
          <clipPath id="aj">
            {/* <rect width={`${width - 40}`} height={`${height}`} viewBox={`0 0 ${width - 40} ${height}`} viewPort={`0 0 ${width - 40} ${height}`} /> */}
            <rect width="1920" height="732" />
          </clipPath>
        </defs>
  <g id="超級scada" clipPath="url(#clip-超級scada)">
    <rect width="377" height="601" fill="rgba(222,222,222,0.76)"/>
    <rect id="Rectangle_695" width="382" height="601" rx="4" transform="translate(-3)" fill="#fff"/>
    {/* <g id="Group_459" transform="translate(19.967 88.637)">
      <path id="Rectangle_80" d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(68.672 0)" fill="#649eb8"/>
      <rect id="Rectangle_573" width="204" height="16" transform="translate(68.61 15)" fill="#fff"/>
      <rect id="Rectangle_574"  width={`${s_showData.realsopno/s_showData.sopcount*196}`} height="16" transform="translate(75.671 15)" fill="#84b9d1" opacity="0.5"/>
      <text id="_75_" transform="translate(152.11 30)" fill="#1a1311" fontSize="18" fontFamily="Helvetica"><tspan x="0" y="0">{`${(s_showData.realsopno/s_showData.sopcount*100).toFixed(1)}%`}</tspan></text>
      <text id="_3_4" transform="translate(283.841 30.791)" fill="#fff" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">{`${s_showData.realsopno}/${s_showData.sopcount}`}</tspan></text>
      <path id="Rectangle_79" d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z" transform="translate(0 0)" fill="#84b9d1"/>
      <text id="投料序" transform="translate(5.672 30.791)" fill="#fff" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">投料序</tspan></text>
    </g> */}
    {s_sink === 'first' ? (
      <g transform="translate(19.967 88.637)">
        <path d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(68.672 0)" fill="#65a3a5" />
        <rect width="204" height="16" transform="translate(68.61 15)" fill="#fff" />
        {/* 綠色進度條 */}
        <rect width={`${s_showData.realsopno/s_showData.sopcount*196}`} height="16" transform="translate(75.671 15)" fill="#6cb8b0" opacity="0.5" /><text
          transform="translate(152.11 30)" fill="#1a1311" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          {/* <tspan x="0" y="0">{`${s_config.GreenBar_percent}%`}</tspan> */}
          <tspan x="0" y="0">{`${(s_showData.realsopno/s_showData.sopcount*100).toFixed(1)}%`}</tspan>
        </text><text transform="translate(283.841 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${s_showData.realsopno}/${s_showData.sopcount}`}</tspan>
        </text>
        <path d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0)" fill="#6cb8b0" /><text transform="translate(5.672 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">投料序</tspan>
        </text>
      </g>
    ) : null}

    {s_sink === 'second' ? (
      <g transform="translate(19.967 88.637)">
        <path d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(68.672 0)" fill="#649eb8" />
        <rect width="204" height="16" transform="translate(68.61 15)" fill="#fff" />
        {/* 淺藍進度條  100% = width:198 */}
        <rect width={`${s_showData.realsopno/s_showData.sopcount*196}`} height="16" transform="translate(75.671 15)" fill="#84b9d1" opacity="0.5" /><text
          transform="translate(152.11 30)" fill="#1a1311" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${(s_showData.realsopno/s_showData.sopcount*100).toFixed(1)}%`}</tspan>
        </text><text transform="translate(283.841 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${s_showData.realsopno}/${s_showData.sopcount}`}</tspan>
        </text>
        <path d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0)" fill="#84b9d1" /><text transform="translate(5.672 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">投料序</tspan>
        </text>
      </g>
    ) : null}

    {s_sink === 'third' ? (
      <g transform="translate(19.967 88.637)">
        <path d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(68.672 0)" fill="#5e7d9d" />
        <rect width="204" height="16" transform="translate(68.61 15)" fill="#fff" />
        {/* 藍色進度條  100% = width:198 */}
        <rect width={`${(s_showData.realsopno/s_showData.sopcount*196).toFixed(1)}`} height="16" transform="translate(75.671 15)" fill="#6c92b8" opacity="0.5" /><text
          transform="translate(152.11 30)" fill="#1a1311" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${(s_showData.realsopno/s_showData.sopcount*100).toFixed(1)}%`}</tspan>
        </text><text transform="translate(283.841 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${s_showData.realsopno}/${s_showData.sopcount}`}</tspan>
        </text>
        <path d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0)" fill="#6c92b8" /><text transform="translate(5.672 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">投料序</tspan>
        </text>
      </g>
    ) : null}

    {s_sink === 'fourth' ? (
      <g transform="translate(19.967 88.637)">
        <path d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(68.672 0)" fill="#70659e" />
        <rect width="204" height="16" transform="translate(68.61 15)" fill="#fff" />
        {/* 紫色進度條  100% = width:198 */}
        <rect className='PurpleBar' width={`${s_showData.realsopno/s_showData.sopcount*196}`} height="16" transform="translate(75.671 15)" fill="#8d81be" opacity="0.5" /><text
          transform="translate(152.11 30)" fill="#1a1311" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{`${(s_showData.realsopno/s_showData.sopcount*100).toFixed(1)}%`}</tspan>
        </text><text transform="translate(283.841 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{`${s_showData.realsopno}/${s_showData.sopcount}`}</tspan>
        </text>
        <path d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0)" fill="#8d81be" /><text transform="translate(5.672 30.791)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">投料序</tspan>
        </text>
      </g>
    ) : null}
        
    <g id="Group_575" transform="translate(-770.57 -298.987)">
      <path id="Path_460" d="M1958.066,854.19" transform="translate(-924.157 -174.09)" fill="none" stroke="#0092e5" strokeMiterlimit="10" strokeWidth="0.5"/>
      <path id="Path_356" d="M4562.566,854.19" transform="translate(-3694.373 -177.187)" fill="none" stroke="#0092e5" strokeMiterlimit="10" strokeWidth="0.5"/>
      <text id="扭力" transform="translate(1067.508 622.717)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">扭力</tspan></text>
      <text id="重量" transform="translate(1066.507 683.298)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">重量</tspan></text>
      <rect id="Rectangle_443" width="83.998" height="24.615" transform="translate(779.08 470.729)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <rect id="Rectangle_444" width="83.998" height="24.616" transform="translate(779.08 532.537)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <text id="氮氣流量" transform="translate(779.08 466.379)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">氮氣流量</tspan></text>
      <text id="氧氣流量" transform="translate(778.757 528.185)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">氧氣流量</tspan></text>
      <rect id="Rectangle_445" width="84.321" height="24.615" transform="translate(778.757 626.981)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <rect id="Rectangle_602" width="84.321" height="24.615" transform="translate(1046.347 626.981)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <rect id="Rectangle_603" width="84.321" height="24.615" transform="translate(1045.847 688.298)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <rect id="Rectangle_446" width="84.321" height="24.616" transform="translate(778.757 687.563)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
      <text id="電流" transform="translate(799.757 622.717)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">電流</tspan></text>
      <text id="頻率" transform="translate(799.757 683.298)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">頻率</tspan></text>
      <rect id="Rectangle_579" width="88.38" height="98.737" rx="10" transform="translate(1044.008 443.379)" fill="#ffef7e"/>
      <text id="段_數" transform="translate(1057.986 472.105)" fill="#1a1311" fontSize="21" fontFamily="PingFangTC-Semibold, PingFang TC" fontWeight="600"><tspan x="0" y="0">段</tspan><tspan y="0" xmlSpace="preserve" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700">    </tspan><tspan y="0">數</tspan></text>
      {/* <text id="_5" transform="translate(1074.986 524.39)" fill="#1a1311" fontSize="48" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><tspan x="0" y="0">5</tspan></text> */}
      <g transform="translate(1074.986 524.39)">
        <text textAnchor="middle" x="12" y="0" fontSize="48" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700">
          {s_showData.step}
        </text>
      </g>
      <g id="mixers" transform="translate(-12585.285 -10969.105)">
        <path id="Path_647" d="M4738.75,468.95V615.327c0,25.553-26.616,46.267-59.448,46.267h-40.881c-32.833,0-59.447-20.714-59.447-46.267V468.95Z" transform="translate(8877.688 11027.203)" fill="#8e8e8f" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
        <path id="Path_648" d="M4749.92,502.868V631.831c0,22.513-22.988,40.764-51.346,40.764h-35.305c-28.356,0-51.344-18.251-51.344-40.764V502.868Z" transform="translate(8855.633 11004.494)" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1" fill="url(#e)"/>
        <rect fill="url(#f)" id="Rectangle_645" width="160.973" height="11.23" transform="translate(13456.222 11469.025)" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1" />
        <rect id="Rectangle_646" width="160.973" height="11.228" transform="translate(13456.222 11480.251)" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1" fill="url(#f)"/>
        <path id="Path_649" d="M4749.59,328.657c-15.712-19.27-41.13-31.789-69.814-31.789s-54.074,12.5-69.789,31.752Z" transform="translate(8856.936 11140.343)" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1" fill="url(#h)"/>
        <g id="Group_540" transform="translate(157.562 -321.995)">
          <rect id="Rectangle_631" width="137.012" height="23" transform="translate(13310.499 11836.465)" fill="#f6cccc"/>
          <text id="槽溫" transform="translate(13318.999 11853.465)" fill="#1a1311" fontSize="14" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">槽溫</tspan></text>
          <text id="_" transform="translate(13425.011 11853.465)" fill="#1a1311" fontSize="14" fontFamily="Helvetica"><tspan x="0" y="0" xmlSpace="preserve"> ℃</tspan></text>
        </g>
        {/* 燈 */}
        <rect id="Rectangle_632" width="15.087" height="28.917" rx="3" transform="translate(13487.84 11424.699)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="0.5">
          {s_showData.lighton !== 'W' ? (
            <animate attributeType="CSS" attributeName="fill"
              from="#FFF" to={`${s_showData.lighton === 'B' ? 'blue' : s_showData.lighton === 'R' ? 'red' : 'green'}`} begin="0s" dur="1s"
              repeatCount="indefinite">
            </animate>
          ) : null}
        </rect>
        <path id="Path_641" d="M10678.2,6032.09h20.367v5.28H10678.2Z" transform="translate(2807.01 5418.004)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="0.5"/>
        <g id="Group_572" transform="translate(102.342 233.779)">
          <rect id="Rectangle_311" width="2.63" height="155.95" rx="1" transform="translate(13432.338 11273.583)" fill="#7a8397"/>
          <g className={s_showData.lighton === 'B' || s_showData.lighton === 'G' ? "Propeller" : null}>{/* 螺旋槳 */}
            <path id="Path_358" d="M0,4.855s25.831,6.157,30.177-2.782C32.677-3.07,7.774,2.713,0,4.855Z" transform="matrix(0.995, 0.105, -0.105, 0.995, 13431.098, 11420.582)" fill="#7a8397"/>
            <path id="Path_360" d="M4742.2,828.888s-19.826-13.187-27.246-5.5C4710.682,827.814,4734.593,828.888,4742.2,828.888Z" transform="translate(8691.578 10596.722)" fill="#7a8397"/>
          </g>
        </g>
        {/* <path id="Subtraction_58" d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z" transform="translate(27713.148 22897.871)" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1" opacity="0.5" fill="url(#i)"/> */}
        <g opacity="0.5">
          {s_showData.levelon === 0 ? (
            <>
              <path 
                d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z" 
                transform="translate(13469.933 11648.598)" fill="url(#i)"></path>
              <path 
                d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z" 
                transform="translate(13469.933 11648.598)" fill="#1a1311"></path>
            </>
          ) : null}

          {/* 中水位 */}
          {s_showData.levelon === 1 || s_showData.levelon === 3 ? (
            <>
              <path
                d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                transform="translate(27713.148 22897.871)" fill="url(#i)" />
              <path
                d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                transform="translate(27713.148 22897.871)" fill="#1a1311" /> 
            </>
          ) : null}

          {/* 高水位 */}
          {s_showData.levelon === 2 ? (
            <>
              <path
                d="M-14544.946-11215.783h-35.307a62.668,62.668,0,0,1-19.985-3.2,53.54,53.54,0,0,1-16.32-8.736,41.537,41.537,0,0,1-11-12.959,33.452,33.452,0,0,1-4.034-15.867v-87.824h138v87.824a33.436,33.436,0,0,1-4.035,15.867,41.549,41.549,0,0,1-11,12.959,53.522,53.522,0,0,1-16.321,8.736A62.678,62.678,0,0,1-14544.946-11215.783Z"
                transform="translate(28099.148 22892.871)" fill="url(#i)" />
              <path
                d="M-14544.946-11215.283h-35.307a63.167,63.167,0,0,1-20.145-3.229,54.023,54.023,0,0,1-16.472-8.818,42.027,42.027,0,0,1-11.134-13.115,33.947,33.947,0,0,1-4.093-16.1v-88.324h139v88.324a33.928,33.928,0,0,1-4.094,16.1,42.054,42.054,0,0,1-11.135,13.115,54.009,54.009,0,0,1-16.473,8.818A63.182,63.182,0,0,1-14544.946-11215.283Zm-86.149-128.59v87.324a32.958,32.958,0,0,0,3.976,15.632,41.046,41.046,0,0,0,10.872,12.8,53.045,53.045,0,0,0,16.169,8.654,62.187,62.187,0,0,0,19.826,3.177h35.307a62.2,62.2,0,0,0,19.827-3.177,53.052,53.052,0,0,0,16.17-8.654,41.048,41.048,0,0,0,10.873-12.8,32.936,32.936,0,0,0,3.977-15.632v-87.324Z"
                transform="translate(28099.148 22892.871)" fill="#1a1311" />
            </>
          ) : null}
        </g>
        {s_showData.levelon === 1 ? (
          <g transform="translate(13588.938 11555.999)"> 
            <path d="M14489.334,11598.8v80.393" transform="translate(-14485.334 -11583.798)" fill="#ffef7e" /> 
            <path d="M14490.834,11679.189h-3V11598.8h3Z" transform="translate(-14485.334 -11583.798)" fill="#ffef7e" /> 
            <path d="M4,0,8,5H0Z" transform="translate(0 11)" fill="#ffef7e" /> 
          </g>
        ) : null}
        {s_showData.levelon === 3 ? (
          <g transform="translate(13588.938 11568.999)">
            <path d="M14489.334,11679.189V11598.8" transform="translate(-14485.334 -11598.797)" fill="#ffef7e" /> 
            <path d="M14490.834,11679.189h-3V11598.8h3Z" transform="translate(-14485.334 -11598.797)" fill="#ffef7e" /> 
            <path d="M4,0,8,5H0Z" transform="translate(8 84.392) rotate(180)" fill="#ffef7e" /> 
          </g>
        ) : null}
      </g>
      <rect id="Rectangle_633" width="89.894" height="24.616" transform="translate(906.476 587.643)" fill="#fff" stroke="#1a1311" strokeMiterlimit="10" strokeWidth="1"/>
    </g>
    <rect id="Rectangle_688" width="353.631" height="138.891" rx="12" transform="translate(8.184 446.5)" fill="#ffe4bd"/>
    <line id="Line_313" y2="139" transform="translate(185.002 446.391)" fill="none" stroke="#fffefe" strokeWidth="1"/>
    <line id="Line_318" x2="353.501" transform="translate(8.184 482.5)" fill="none" stroke="#fff2f2" strokeWidth="1"/>
    <line id="Line_303" x2="353.501" transform="translate(8.316 518.891)" fill="none" stroke="#fff2f2" strokeWidth="1"/>
    <line id="Line_303-2" x2="353.501" transform="translate(8.184 551.5)" fill="none" stroke="#fff2f2" strokeWidth="1"/>
    <path id="Rectangle_693" d="M0,0H353.631a0,0,0,0,1,0,0V56.27a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z" transform="translate(8.053 517.365)" fill="#ffd592"/>
    <line id="Line_319" x2="353.501" transform="translate(8.314 551.5)" fill="none" stroke="#fff2f2" strokeWidth="1"/>
    <line id="Line_320" y2="139" transform="translate(185.002 446.391)" fill="none" stroke="#fffefe" strokeWidth="1"/>
    <text id="槽套溫度" transform="translate(54.906 472.514)" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">槽套溫度</tspan></text>
    <text id="設定溫度" transform="translate(54.906 507.486)" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">設定溫度</tspan></text>
    <text id="熱煤設定" transform="translate(54.906 543.877)" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">熱煤設定</tspan></text>
    <text id="熱煤溫度_" transform="translate(54.906 554.486)" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="22">熱煤溫度</tspan><tspan y="22" xmlSpace="preserve" fontFamily="Helvetica">  </tspan></text>
    <line id="Line_321" x2="353.501" transform="translate(8.053 517.473)" fill="none" stroke="#fff2f2" strokeWidth="1"/>
    <text id="_2" transform="translate(302 472)" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">℃</tspan></text>
    <g id="Group_616" transform="translate(-549.234 1071.496)">
      <path id="Path_568" d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z" transform="translate(0 0)" fill="#474747" fillRule="evenodd"/>
      <path id="Path_569" d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z" transform="translate(-14.947 -14.764)" fill="#474747" fillRule="evenodd"/>
    </g>
    <g id="Group_632" transform="translate(-727 -137)">
      {/* <g id="Rectangle_708" transform="translate(919 624)" fill="#fff" stroke="#707070" strokeWidth="1">
        <rect width="97" height="27" stroke="none"/>
        <rect x="0.5" y="0.5" width="96" height="26" fill="none"/>
      </g> */}
      <g id="Group_633">
        {/* <text id="_10" transform="translate(956 644)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">10</tspan></text> */}
        <g transform="translate(956 644)">
          <text textAnchor="middle" x="15" y="0" fontSize="22" fontFamily="Helvetica">
            {s_showData.setTemp}
          </text>
        </g>
      </g>
    </g>
    <text id="_3" transform="translate(302 576)" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">℃</tspan></text>
    <text id="_4" transform="translate(302 543.391)" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">℃</tspan></text>
    <text id="_5-2" transform="translate(302 509)" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">℃</tspan></text>
    <g id="Group_629" transform="translate(-549.234 1175.471)">
      <path id="Path_568-2" d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z" transform="translate(0 0)" fill="#474747" fillRule="evenodd"/>
      <path id="Path_569-2" d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z" transform="translate(-14.947 -14.764)" fill="#474747" fillRule="evenodd"/>
    </g>
    <g id="Group_630" transform="translate(-549.234 1142.859)">
      <path id="Path_568-3" d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z" transform="translate(0 0)" fill="#474747" fillRule="evenodd"/>
      <path id="Path_569-3" d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z" transform="translate(-14.947 -14.764)" fill="#474747" fillRule="evenodd"/>
    </g>
    <g id="Group_631" transform="translate(-549.234 1106.471)">
      <path id="Path_568-4" d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z" transform="translate(0 0)" fill="#474747" fillRule="evenodd"/>
      <path id="Path_569-4" d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z" transform="translate(-14.947 -14.764)" fill="#474747" fillRule="evenodd"/>
    </g>
    <g id="Group_634" transform="translate(-727 -173)">
      {/* <g id="Rectangle_708-2" transform="translate(919 624)" fill="#fff" stroke="#707070" strokeWidth="1">
        <rect width="97" height="27" stroke="none"/>
        <rect x="0.5" y="0.5" width="96" height="26" fill="none"/>
      </g> */}
      <g id="Group_633-2">
        {/* <text id="_100" transform="translate(957 644)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">100</tspan></text> */}
        <g transform="translate(957 644)">
          <text textAnchor="middle" x="15" y="0" fontSize="22" fontFamily="Helvetica">
            {s_showData.outTemp}
          </text>
        </g>
      </g>
    </g>
    <g id="Group_635" transform="translate(-727 -69)">
      {/* <g id="Rectangle_708-3" transform="translate(919 624)" fill="#fff" stroke="#707070" strokeWidth="1">
        <rect width="97" height="27" stroke="none"/>
        <rect x="0.5" y="0.5" width="96" height="26" fill="none"/>
      </g> */}
      <g id="Group_633-3">
        <g transform="translate(956 644)">
          <text textAnchor="middle" x="15" y="0" fontSize="22" fontFamily="Helvetica">
            {s_showData.keroTemp}
          </text>
        </g>
        {/* <text id="_10-2" transform="translate(956 644)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">10</tspan></text> */}
      </g>
    </g>
    <g id="Group_636" transform="translate(-727 -102)">
      {/* <g id="Rectangle_708-4" transform="translate(919 624)" fill="#fff" stroke="#707070" strokeWidth="1">
        <rect width="97" height="27" stroke="none"/>
        <rect x="0.5" y="0.5" width="96" height="26" fill="none"/>
      </g> */}
      <g id="Group_633-4">
        {/* <text id="_10-3" transform="translate(956 644)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">1x0</tspan></text> */}
        <g transform="translate(956 644)">
          <text textAnchor="middle" x="15" y="0" fontSize="22" fontFamily="Helvetica">
            {s_showData.setkeroTemp}
          </text>
        </g>
      </g>
    </g>
    {/* <text id="_20" transform="translate(39 192)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(39 192)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.flowN}
      </text>
    </g>
    {/* <text id="_20-2" transform="translate(39 410)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(39 410)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.frequency}
      </text>
    </g>
    {/* <text id="_20-3" transform="translate(39 347)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(39 347)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.current}
      </text>
    </g>
    {/* <text id="_20-4" transform="translate(39 253)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(39 253)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.flowO}
      </text>
    </g>
    {/* <text id="_20-5" transform="translate(306 347)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(306 347)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.torque}
      </text>
    </g>
    {/* <text id="_20-6" transform="translate(306 410)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">20</tspan></text> */}
    <g transform="translate(306 410)">
      <text textAnchor="middle" fontSize="22" fontFamily="Helvetica" x="10" y="0">
        {s_showData.weight}
      </text>
    </g>
    {/* <text id="_00:00:00" transform="translate(143 307)" fontSize="20" fontFamily="Helvetica"><tspan x="0" y="0">00:00:00</tspan></text> */}
    <g transform="translate(143 307)" >
      <text x="0" y="0" fontSize="20" fontFamily="Helvetica">
        {s_showData.timer}
      </text>
    </g>
    {/* <text id="_20-7" transform="translate(199 266)" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">2</tspan></text> */}
    <g transform="translate(199 266)" >
      <text textAnchor="middle" x="12" y="-2" fontSize="18">
        {s_showData.inTemp}
      </text>
    </g>
    {/* <g id="Group_458" transform="translate(19.965 11.5)">
      <path id="Rectangle_82" d="M0,0H163.671a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(163.672 31.616)" fill="#649eb8"/>
      <path id="Rectangle_79-2" d="M8,0H163.672a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z" transform="translate(0 0)" fill="#84b9d1"/>
      <path id="Rectangle_80-2" d="M0,0H155.671a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(163.671 0)" fill="#84b9d1"/>
      <text id="_2A批號" transform="translate(54.232 24.808)" fill="#fff" fontSize="21" fontFamily="Helvetica"><tspan x="0" y="0">#2A</tspan><tspan y="0" fontFamily="PingFangTC-Regular, PingFang TC">批號</tspan></text>
      <text id="時程" transform="translate(224.508 24.809)" fill="#fff" fontSize="21" fontFamily="PingFangTC-Regular, PingFang TC"><tspan x="0" y="0">時程</tspan></text>
      <path id="Rectangle_542" d="M0,0H163.671a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z" transform="translate(0 31.615)" fill="#649eb8"/>
    </g>
    <text id="AAA" transform="translate(86 68)" fill="#fff" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">AAAa</tspan></text>
    <text id="_00:00:00-2" transform="translate(233 68)" fill="#fff" fontSize="22" fontFamily="Helvetica"><tspan x="0" y="0">00:00:00</tspan></text> */}
    {s_sink === 'first' ? (
      <g transform="translate(19.965 11.5)">
        <path d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.868 31.617)" fill="#65a3a5" />
          <text transform="translate(232.868 31.617)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">00:00:00</tspan>
        </text>
        <path d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0.001)" fill="#6cb8b0" />
        <path d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.867)" fill="#6cb8b0" />
          {/* <text transform="translate(82.433 24.808)" fill="#fff"
            fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">#10批號</tspan>
          </text> */}
          <g transform="translate(82.433 24.808)">
            <text x="30" y="0" textAnchor="middle" fill="#fff" fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">#1反應槽批號</text>
          </g>
        <text transform="translate(259.105 24.809)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">時程</tspan>
        </text>
        <path d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
          transform="translate(0 31.616)" fill="#65a3a5" /><text transform="translate(53.934 55.88)" fill="#fff"
          fontSize="18" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="-30" y="0">{s_showData.pdtlot}</tspan>
        </text><text transform="translate(244.605 55.88)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.timer}</tspan>
        </text>
      </g>
    ) : null}

    {s_sink === 'second' ? (
      <g transform="translate(19.965 11.5)">
        <path d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.868 31.617)" fill="#649eb8" />
          {/* <text transform="translate(232.868 31.617)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">00:00:00</tspan>
        </text> */}
        <path d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0.001)" fill="#84b9d1" />
        <path d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.867)" fill="#84b9d1" /><text transform="translate(75.433 24.808)" fill="#fff"
          fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">#2A反應槽批號</tspan>
        </text>
        <text transform="translate(259.105 24.809)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">時程</tspan>
        </text>
        <path d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
          transform="translate(0 31.616)" fill="#649eb8" /><text transform="translate(53.934 55.88)" fill="#fff"
          fontSize="18" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.pdtlot}</tspan>
        </text><text transform="translate(244.604 55.88)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.timer}</tspan>
        </text>
      </g>
    ) : null}

    {s_sink === 'third' ? (
      <g transform="translate(19.965 11.5)">
        <path d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.868 31.617)" fill="#5e7d9d" />
        <path d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0.001)" fill="#6c92b8" />
        <path d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.867)" fill="#6c92b8" /><text transform="translate(76.432 24.808)" fill="#fff"
          fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">#2B反應槽批號</tspan>
        </text><text transform="translate(259.105 24.809)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">時程</tspan>
        </text>
        <path d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
          transform="translate(0 31.616)" fill="#5e7d9d" /><text transform="translate(53.933 55.88)" fill="#fff"
          fontSize="18" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.pdtlot}</tspan>
        </text>
        <text transform="translate(244.606 55.88)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.timer}</tspan>
        </text>
      </g>  
    ) : null}

    {s_sink === 'fourth' ? (
      <g transform="translate(19.965 11.5)">
        <path d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.869 31.617)" fill="#70659e" />
        <path d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
          transform="translate(0 0.001)" fill="#8d81be" />
        <path d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(232.868)" fill="#8d81be" /><text transform="translate(82.433 24.808)" fill="#fff"
          fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">#3反應槽批號</tspan>
        </text><text transform="translate(259.106 24.809)" fill="#fff" fontSize="21"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">時程</tspan>
        </text>
        <path d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
          transform="translate(0 31.616)" fill="#70659e" />
          <g>
            <text transform="translate(53.934 55.88)" fill="#fff"
              fontSize="18" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_showData.pdtlot}</tspan>
            </text>
          </g>
        <text transform="translate(244.606 55.88)" fill="#fff" fontSize="18"
          fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
          <tspan x="0" y="0">{s_showData.timer}</tspan>
        </text>
      </g>
    ) : null}

  </g>
</svg>

    </StyleSVG>
  )
};

export default SingleScada;