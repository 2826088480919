import React from 'react';
import SVGcomponent from './SVGcomponent'
//import scada from './scada.svg'
//import { ReactComponent as MySvg } from './scada.svg'

const TestSVG = () => {
  return (
    <div style={{width: '100%'}}>
      <SVGcomponent test="Ariean" />
    </div>
  )
}

export default TestSVG