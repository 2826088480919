import React, { useState, useEffect } from "react";
import MyAntdInput from "../../styled/myAntdInput";
import MyAntdSelect from "../../styled/myAntdSelect";
import { Row, Col, Select } from "antd";
const TestAntdInput = () => {
  const { Option } = Select;

  return (
    <div>
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={8}>
          <MyAntdSelect
            label="test"
            onChange={(e) => {
              console.log("test Antd Button", e);
            }}
            type="filled"
            width="150"
            placeholder="test123"
          >
            <Option value="name1">Wayne</Option>
            <Option value="name2">Jeff</Option>
            <Option value="name3">Jason</Option>
          </MyAntdSelect>
        </Col>
        <Col span={8}>
          <MyAntdInput
            label="test"
            onChange={(e) => {
              console.log("test Antd Button", e);
            }}
            type="filled"
            width="150"
            placeholder="test123"
            // disabled
          />
          <MyAntdInput
            label="test"
            onChange={(e) => {
              console.log("test Antd Button", e);
            }}
            type="outlined"
            width="150"
            placeholder="test123"
            // disabled
          />
          <MyAntdInput
            label="test"
            onChange={(e) => {
              console.log("test Antd Button", e);
            }}
            type="standard"
            width="150"
            placeholder="test123"
            // disabled
          />
        </Col>
      </Row>
    </div>
  );
};
export default TestAntdInput;
