import { instance } from "../handlerAPI";
import apiBasic from "../apiBasic";

export const getAllWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/qc/getAllWpc`, {
    params: _params,
  });
};
export const pass = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/pass`, data);
};
export const waive = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/waive`, data);
};
export const quarantine = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/qc/quarantine`,
    data
  );
};
export const confirm = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/qc/confirm`,
    data
  );
};
export const cancel = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/qc/cancel`,
    data
  );
};
