import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { message } from "antd";
// import { getSCADA } from "../../services/api";

// import useWebSocket from "../../utility/useWebSocket";

import CustomModal from "../../components/antdCustom/CustomModal";
import ModalContainer from "./ModalContainer";

import useWebSocket from "../../hooks/useWebSocket";
import apiBasic from "../../services/apiBasic";

// import './svg.css';
//component
import CustomTable from "./CustomTable";

const StyleSVG = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: 100px;
  height: 700px; */

  .Propeller {
    animation: roateImg 0.5s linear infinite;
    transform: rotateZ(30px);
    transform-box: fill-box;
    transform-origin: center;
  }
  /* 順時針轉 */
  @keyframes roateImg {
    0% {
      transform: rotate3d(0, 1, 0.95, 0deg);
    }

    100% {
      transform: rotate3d(0, 5, 0.95, 360deg);
    }
  }

  .pointer {
    cursor: pointer;
  }

  .airtube {
    transform: ${({ airtube }) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (8 - 0) / 245; //

      if (airtube >= 8) {
        return `rotate(15deg)`;
      }

      if (airtube <= 0) {
        return `rotate(-230deg)`;
      }

      let target = parseInt((airtube / value).toFixed(1));

      return `rotate(${-230 + target}deg)`;
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .o2tube {
    transform: ${({ o2tube }) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0) / 245; //

      if (o2tube >= 4) {
        // 超過最大值時顯示最大值
        return `rotate(15deg)`;
      }
      if (o2tube <= 0) {
        // 超過最小值時顯示最小值
        return `rotate(-230deg)`;
      }

      let target = parseInt((o2tube / value).toFixed(1));

      return `rotate(${-230 + target}deg)`;
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .n2tube {
    transform: ${({ n2tube }) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (8 - 0) / 245; //

      if (n2tube >= 8) {
        // 超過最大值時顯示最大值
        return `rotate(15deg)`;
      }
      if (n2tube <= 0) {
        // 超過最小值時顯示最小值
        return `rotate(-230deg)`;
      }

      let target = parseInt((n2tube / value).toFixed(1));

      return `rotate(${-230 + target}deg)`;
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .cwpipe {
    transform: ${({ cwpipe }) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0) / 245; //

      if (cwpipe >= 4) {
        // 超過最大值時顯示最大值
        return `rotate(15deg)`;
      }
      if (cwpipe <= 0) {
        // 超過最小值時顯示最小值
        return `rotate(-230deg)`;
      }

      let target = parseInt((cwpipe / value).toFixed(1));

      return `rotate(${-230 + target}deg)`;
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .wpipe {
    transform: ${({ wpipe }) => {
      // 計算出 1deg 佔多少值 (度數 min:-230deg, max:15deg, 總度數: 245)
      let value = (4 - 0) / 245; //

      if (wpipe >= 4) {
        // 超過最大值時顯示最大值
        return `rotate(15deg)`;
      }
      if (wpipe <= 0) {
        // 超過最小值時顯示最小值
        return `rotate(-230deg)`;
      }

      let target = parseInt((wpipe / value).toFixed(1));

      return `rotate(${-230 + target}deg)`;
    }};
    transform-box: fill-box;
    transform-origin: 5px 2px;
  }

  .pointerx {
    animation: roatePointer 3s linear infinite;
    transform-box: fill-box;
    transform-origin: top left;
  }

  @keyframes roatePointer {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const SVGcomponent = ({ test }) => {
  //const [DataSocket, changeParams, setData] = useWebSocket();
  const width = window.innerWidth - 60;
  const height = window.innerHeight - 100;

  const [Socket, changeParams, isOpenSwitch] = useWebSocket();

  const [testData, setTestData] = useState({});

  const [s_first, set_s_first] = useState({});
  const [s_second, set_s_second] = useState({});
  const [s_third, set_s_third] = useState({});
  const [s_fourth, set_s_fourth] = useState({});
  const [s_utility, set_s_utility] = useState({});

  const [s_sink, set_s_sink] = useState("");

  const [is_blueLine, set_is_blueLine] = useState(false);
  const [is_darkBlueLine, set_is_darkBlueLine] = useState(false);
  const [is_greenLine, set_is_greenLine] = useState(false);
  const [is_darkgreenLine, set_is_darkgreenLine] = useState(false);
  const [is_redLine, set_is_redLine] = useState(false);
  const [is_darkredLine, set_is_darkredLine] = useState(false);

  const [is_showModal, set_is_showModal] = useState(false);
  const [s_showData, set_s_showData] = useState({});

  const [is_showPurpleLigh, set_is_showPurpleLigh] = useState(false);
  const [is_showBlueLigh, set_is_showBlueLigh] = useState(false);

  const [is_showSkyBlueLigh, set_is_showSkyBlueLigh] = useState(false);
  const [is_showGreenLigh, set_is_showGreenLigh] = useState(false);

  const [s_config, set_s_config] = useState({
    PurpleBar_width: "196",
    PurpleBar_percent: "87",

    SkyBlueBar_width: "96",
    SkyBlueBar_percent: "871",

    BlueBar_width: "196",
    BlueBar_percent: "872",

    GreenBar_width: "196",
    GreenBar_percent: "873",
  });

  useEffect(() => {
    changeParams({
      url: apiBasic.url,
      apiName: "scada/socket",
      params: ``,
    });

    isOpenSwitch(true);
  }, []);

  useEffect(() => {
    if (Socket.status) {
      console.log("Socket.data = ", Socket.data);
      setTestData(Socket.data);

      set_s_first(Socket.data.wg[0]);
      set_s_second(Socket.data.wg[1]);
      set_s_third(Socket.data.wg[2]);
      set_s_fourth(Socket.data.wg[3]);

      set_is_darkBlueLine(Socket.data.wg[0].wgTran_1);
      set_is_blueLine(Socket.data.wg[0].wgTran_2);

      set_is_greenLine(Socket.data.wg[1].wgTran_1);
      set_is_darkgreenLine(Socket.data.wg[2].wgTran_1);

      set_is_redLine(Socket.data.wg[1].wgTran_2);
      set_is_darkredLine(Socket.data.wg[2].wgTran_2);

      set_s_utility(Socket.data.utility);
    } else {
    }
    console.log("xxxxx");
  }, [Socket]);

  const openModal = (data, sink) => {
    if (data.pdtlot === "") {
      message.error("此槽尚無原料");
    } else {
      set_s_showData(data);
      set_s_sink(sink);
      set_is_showModal(true);
    }
  };

  useEffect(() => {
    const data = {
      wg: [
        {
          wpcmainSN: 104,
          gpcSN: 1,
          wgID: "SMAD4301",
          wgNM: "1反應槽",
          pdtlot: "QTEST_0818_00180",
          woN: "",
          step: 10,
          timer: "00:00:01",
          formulaSN: 0,
          sopcount: 8,
          realsopno: 4,
          wgTran_1: true,
          wgTran_2: true,
          wgTran_3: true,
          lighton: "W",
          levelon: 2,
          current: 0,
          frequency: 0,
          torque: 102,
          weight: 2.8,
          flowN: 0,
          flowO: 0,
          inTemp: 29,
          outTemp: 28,
          setTemp: 0,
          setkeroTemp: 250,
          keroTemp: 30,
          createT: "0001-01-01T00:00:00Z",
          updtT: "0001-01-01T00:00:00Z",
          sliceSN: 0,
          sliceT: "2021-08-30T16:28:59+08:00",
          temp_wgTran_1: false,
          temp_wgTran_2: false,
          temp_wgTran_3: false,
        },

        {
          wpcmainSN: 0,
          gpcSN: 0,
          wgID: "SMAD4311B",
          wgNM: "2B反應槽",
          pdtlot: "QWE-123345354",
          woN: "",
          step: 0,
          timer: "00:00:02",
          formulaSN: 0,
          sopcount: 8,
          realsopno: 0,
          wgTran_1: true,
          wgTran_2: true,
          wgTran_3: true,
          lighton: "B",
          levelon: 3,
          current: 0,
          frequency: 0,
          torque: 0,
          weight: -2.4,
          flowN: 0,
          flowO: 0,
          inTemp: 28,
          outTemp: 28,
          setTemp: 0,
          setkeroTemp: 150,
          keroTemp: 28,
          createT: "0001-01-01T00:00:00Z",
          updtT: "0001-01-01T00:00:00Z",
          sliceSN: 0,
          sliceT: "2021-08-30T16:28:59+08:00",
          temp_wgTran_1: false,
          temp_wgTran_2: false,
          temp_wgTran_3: false,
        },

        {
          wpcmainSN: 0,
          gpcSN: 0,
          wgID: "SMAD4322",
          wgNM: "3反應槽",
          pdtlot: "TEST_0818_00VDFE",
          woN: "",
          step: 0,
          timer: "00:00:13",
          formulaSN: 0,
          sopcount: 8,
          realsopno: 6,
          wgTran_1: true,
          wgTran_2: true,
          wgTran_3: true,
          lighton: "B",
          levelon: 0,
          current: 0,
          frequency: 0,
          torque: 0,
          weight: 10.6,
          flowN: 0,
          flowO: 0,
          inTemp: 28,
          outTemp: 0,
          setTemp: 0,
          setkeroTemp: 0,
          keroTemp: 0,
          createT: "0001-01-01T00:00:00Z",
          updtT: "0001-01-01T00:00:00Z",
          sliceSN: 0,
          sliceT: "2021-08-30T16:28:59+08:00",
          temp_wgTran_1: false,
          temp_wgTran_2: false,
          temp_wgTran_3: false,
        },

        {
          wpcmainSN: 0,
          gpcSN: 0,
          wgID: "SMAD4311A",
          wgNM: "2A反應槽",
          pdtlot: "TEST_0818_0000",
          woN: "",
          step: 0,
          timer: "00:00:04",
          formulaSN: 0,
          sopcount: 9,
          realsopno: 3,
          wgTran_1: true,
          wgTran_2: true,
          wgTran_3: true,
          lighton: "B",
          levelon: 1,
          current: 0,
          frequency: 0,
          torque: 0,
          weight: -0.1,
          flowN: 0,
          flowO: 0,
          inTemp: 59,
          outTemp: 29,
          setTemo: 0,
          setkeroTemp: 150,
          keroTemp: 29,
          createT: "0001-01-01T00:00:00Z",
          updtT: "0001-01-01T00:00:00Z",
          sliceSN: 0,
          sliceT: "2021-08-30T16:28:59+08:00",
          temp_wgTran_1: false,
          temp_wgTran_2: false,
          temp_wgTran_3: false,
        },
      ],
      utility: [
        {
          wgID: "PSPIU1",
          wgNM: "PSPI 一號線",
          woN: "",
          wpipe: 1.7,
          cwpipe: 1.2,
          n2tube: 7.9,
          o2tube: 1.9,
          airtube: 5.5,
          amtemp: 0,
          humidity: 0,
          catchW: 0.8,
          wa: 0,
          wa0: 44,
          wamax: 80,
          wb: 0,
          wb0: 10,
          wbmax: 50,
          updtT: "0001-01-01T00:00:00Z",
          sliceSN: 0,
          sliceT: "2021-08-30T16:28:51+08:00",
          createT: "0001-01-01T00:00:00Z",
        },
      ],
    };
    // setTestData(data);

    // set_s_first(data.wg[0])
    // set_s_second(data.wg[1])
    // set_s_third(data.wg[2])
    // set_s_fourth(data.wg[3])

    // set_is_darkBlueLine(data.wg[0].wgTran_1)
    // set_is_blueLine(data.wg[0].wgTran_2)

    // set_is_greenLine(data.wg[1].wgTran_1)
    // set_is_darkgreenLine(data.wg[2].wgTran_1)

    // set_is_redLine(data.wg[1].wgTran_2)
    // set_is_darkredLine(data.wg[2].wgTran_2)

    // set_s_utility(data.utility[0])

    console.log(data);
  }, []);

  const lightMap = {
    R: "red",
    G: "green",
    B: "blue",
    Y: "yellow",
    W: "#FFF",
  };

  /*useEffect(() => {
    changeParams({
      url: url,
      apiName: "getScadaSocket",
    });
    setData(true);
  }, []);

  useEffect(() => {
    if (DataSocket.status) {
      const data = DataSocket.data
      console.log(data)
    } else {
      console.log("----------------error-------------")
    }
  }, [DataSocket]);*/
  // width={`${width - 40}`} height={`${height - 143}`}
  return (
    <StyleSVG
      airtube={s_utility.airtube}
      o2tube={s_utility.o2tube}
      n2tube={s_utility.n2tube}
      cwpipe={s_utility.cwpipe}
      wpipe={s_utility.wpipe}
    >
      {/* <CustomTable /> */}
      {is_showModal ? (
        <CustomModal
          width="80%"
          title="SCADA"
          visible={is_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
        >
          <ModalContainer
            set_is_showModal={set_is_showModal}
            s_showData={
              s_sink === "first"
                ? s_first
                : s_sink === "second"
                ? s_second
                : s_sink === "third"
                ? s_third
                : s_fourth
            }
            set_s_showData={set_s_showData}
            s_sink={s_sink}
          />
        </CustomModal>
      ) : null}
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="1900"
          height="732"
          viewBox="0 0 1920 732"
        >
          <defs>
            <linearGradient
              id="a"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#58788a" />
              <stop offset="0.17" stopColor="#476373" />
              <stop offset="0.388" stopColor="#374f5d" />
              <stop offset="0.597" stopColor="#2e4450" />
              <stop offset="0.788" stopColor="#2b404c" />
            </linearGradient>
            <linearGradient
              id="b"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#7098af" />
              <stop offset="0.033" stopColor="#6b92a9" />
              <stop offset="0.234" stopColor="#56778a" />
              <stop offset="0.429" stopColor="#476474" />
              <stop offset="0.617" stopColor="#3e5867" />
              <stop offset="0.788" stopColor="#3b5563" />
            </linearGradient>
            <linearGradient
              id="e"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0.217" stopColor="#fff" />
              <stop offset="1" stopColor="#7d7d7d" />
            </linearGradient>
            <linearGradient
              id="f"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0.27" stopColor="#fff" />
              <stop offset="0.551" stopColor="#838383" />
              <stop offset="0.683" stopColor="#4e4e4e" />
            </linearGradient>
            <linearGradient
              id="h"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#fff" />
              <stop offset="0.053" stopColor="#ebebeb" />
              <stop offset="0.198" stopColor="#bcbcbc" />
              <stop offset="0.342" stopColor="#949494" />
              <stop offset="0.484" stopColor="#757575" />
              <stop offset="0.621" stopColor="#5f5f5f" />
              <stop offset="0.753" stopColor="#525252" />
              <stop offset="0.873" stopColor="#4e4e4e" />
            </linearGradient>
            <linearGradient
              id="i"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0.137" stopColor="#da950b" />
              <stop offset="0.832" stopColor="#ffce2c" />
            </linearGradient>
            <linearGradient
              id="y"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#aeaeae" />
              <stop offset="1" stopColor="#575757" />
            </linearGradient>
            <linearGradient
              id="ag"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#fff" />
              <stop offset="0.27" stopColor="#d8d8d8" />
              <stop offset="0.619" stopColor="#cfcfcf" />
              <stop offset="1" stopColor="#979797" />
            </linearGradient>
            <clipPath id="aj">
              {/* <rect width={`${width - 40}`} height={`${height}`} viewBox={`0 0 ${width - 40} ${height}`} viewPort={`0 0 ${width - 40} ${height}`} /> */}
              <rect width="1920" height="732" />
            </clipPath>
          </defs>
          <g id="ai" clipPath="url(#aj)">
            {/* <g> */}
            <rect width="1920" height="732" fill="#FFF" />
            <path
              d="M17342.566,12461.149h-287.58v-185.608h-121.545v-5h126.545v185.608h277.58v-30.633h5Z"
              transform="translate(-15518.592 -11860.422)"
              fill={is_darkBlueLine ? null : "#082246"}
            >
              {is_darkBlueLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#aaaebd"
                  to="#082246"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <rect
              width="754.518"
              height="688.067"
              rx="10"
              transform="translate(395.983 21.966)"
              fill="#fffad9"
            />
            <g transform="translate(40 33.542)">
              <path
                d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.869 31.617)"
                fill="#70659e"
              />
              <path
                d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0.001)"
                fill="#8d81be"
              />
              <path
                d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.868)"
                fill="#8d81be"
              />
              <text
                transform="translate(82.433 24.808)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  #3反應槽批號
                </tspan>
              </text>
              <text
                transform="translate(259.106 24.809)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  時程
                </tspan>
              </text>
              <path
                d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                transform="translate(0 31.616)"
                fill="#70659e"
              />
              <g>
                <text
                  transform="translate(53.934 55.88)"
                  fill="#fff"
                  fontSize="18"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    {s_fourth.pdtlot}
                  </tspan>
                </text>
              </g>
              <text
                transform="translate(244.606 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_fourth.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(421 33.542)">
              <path
                d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.868 31.617)"
                fill="#5e7d9d"
              />
              <path
                d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0.001)"
                fill="#6c92b8"
              />
              <path
                d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.867)"
                fill="#6c92b8"
              />
              <text
                transform="translate(76.432 24.808)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  #2B反應槽批號
                </tspan>
              </text>
              <text
                transform="translate(259.105 24.809)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  時程
                </tspan>
              </text>
              <path
                d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                transform="translate(0 31.616)"
                fill="#5e7d9d"
              />
              <text
                transform="translate(53.933 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_third.pdtlot}
                </tspan>
              </text>
              <text
                transform="translate(244.606 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_third.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(796 33.542)">
              <path
                d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.868 31.617)"
                fill="#649eb8"
              />
              {/* <text transform="translate(232.868 31.617)" fill="#fff" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">00:00:00</tspan>
            </text> */}
              <path
                d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0.001)"
                fill="#84b9d1"
              />
              <path
                d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.867)"
                fill="#84b9d1"
              />
              <text
                transform="translate(75.433 24.808)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  #2A反應槽批號
                </tspan>
              </text>
              <text
                transform="translate(259.105 24.809)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  時程
                </tspan>
              </text>
              <path
                d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                transform="translate(0 31.616)"
                fill="#649eb8"
              />
              <text
                transform="translate(53.934 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_second.pdtlot}
                </tspan>
              </text>
              <text
                transform="translate(244.604 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_second.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(1170.773 33.542)">
              <path
                d="M0,0H94.475a0,0,0,0,1,0,0V26.529a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.868 31.617)"
                fill="#65a3a5"
              />
              <text
                transform="translate(232.868 31.617)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  00:00:00
                </tspan>
              </text>
              <path
                d="M8,0H232.867a0,0,0,0,1,0,0V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0.001)"
                fill="#6cb8b0"
              />
              <path
                d="M0,0H86.475a8,8,0,0,1,8,8V31.615a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(232.867)"
                fill="#6cb8b0"
              />
              {/* <text transform="translate(82.433 24.808)" fill="#fff"
                fontSize="21" fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
                <tspan x="0" y="0">#10批號</tspan>
              </text> */}
              <g transform="translate(82.433 24.808)">
                <text
                  x="30"
                  y="0"
                  textAnchor="middle"
                  fill="#fff"
                  fontSize="21"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  #1反應槽批號
                </text>
              </g>
              <text
                transform="translate(259.105 24.809)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  時程
                </tspan>
              </text>
              <path
                d="M0,0H232.866a0,0,0,0,1,0,0V34.529a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                transform="translate(0 31.616)"
                fill="#65a3a5"
              />
              <text
                transform="translate(53.934 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="-30" y="0">
                  {s_first.pdtlot}
                </tspan>
              </text>
              <text
                transform="translate(244.605 55.88)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_first.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(40 121.28)">
              <path
                d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(68.672 0)"
                fill="#70659e"
              />
              <rect
                width="204"
                height="16"
                transform="translate(68.61 15)"
                fill="#fff"
              />
              {/* 紫色進度條  100% = width:198 */}
              <rect
                className="PurpleBar"
                width={`${(s_fourth.realsopno / s_fourth.sopcount) * 196}`}
                height="16"
                transform="translate(75.671 15)"
                fill="#8d81be"
                opacity="0.5"
              />
              <text
                transform="translate(152.11 30)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">{`${(
                  (s_fourth.realsopno / s_fourth.sopcount) *
                  100
                ).toFixed(1)}%`}</tspan>
              </text>
              <text
                transform="translate(283.841 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan
                  x="0"
                  y="0"
                >{`${s_fourth.realsopno}/${s_fourth.sopcount}`}</tspan>
              </text>
              <path
                d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0)"
                fill="#8d81be"
              />
              <text
                transform="translate(5.672 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  投料序
                </tspan>
              </text>
            </g>
            <g transform="translate(421 121.28)">
              <path
                d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(68.672 0)"
                fill="#5e7d9d"
              />
              <rect
                width="204"
                height="16"
                transform="translate(68.61 15)"
                fill="#fff"
              />
              {/* 藍色進度條  100% = width:198 */}
              <rect
                width={`${(
                  (s_third.realsopno / s_third.sopcount) *
                  196
                ).toFixed(1)}`}
                height="16"
                transform="translate(75.671 15)"
                fill="#6c92b8"
                opacity="0.5"
              />
              <text
                transform="translate(152.11 30)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">{`${
                  ((s_third.realsopno / s_third.sopcount) * 100).toFixed(1)
                }%`}</tspan>
              </text>
              <text
                transform="translate(283.841 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan
                  x="0"
                  y="0"
                >{`${s_third.realsopno}/${s_third.sopcount}`}</tspan>
              </text>
              <path
                d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0)"
                fill="#6c92b8"
              />
              <text
                transform="translate(5.672 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  投料序
                </tspan>
              </text>
            </g>
            <g transform="translate(796 121.28)">
              <path
                d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(68.672 0)"
                fill="#649eb8"
              />
              <rect
                width="204"
                height="16"
                transform="translate(68.61 15)"
                fill="#fff"
              />
              {/* 淺藍進度條  100% = width:198 */}
              <rect
                width={`${(s_second.realsopno / s_second.sopcount) * 196}`}
                height="16"
                transform="translate(75.671 15)"
                fill="#84b9d1"
                opacity="0.5"
              />
              <text
                transform="translate(152.11 30)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">{`${
                  ((s_second.realsopno / s_second.sopcount) * 100).toFixed(1)
                }%`}</tspan>
              </text>
              <text
                transform="translate(283.841 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan
                  x="0"
                  y="0"
                >{`${s_second.realsopno}/${s_second.sopcount}`}</tspan>
              </text>
              <path
                d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0)"
                fill="#84b9d1"
              />
              <text
                transform="translate(5.672 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  投料序
                </tspan>
              </text>
            </g>
            <g transform="translate(1170.774 121.28)">
              <path
                d="M0,0H250.67a8,8,0,0,1,8,8V36.76a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(68.672 0)"
                fill="#65a3a5"
              />
              <rect
                width="204"
                height="16"
                transform="translate(68.61 15)"
                fill="#fff"
              />
              {/* 綠色進度條 */}
              <rect
                width={`${(s_first.realsopno / s_first.sopcount) * 196}`}
                height="16"
                transform="translate(75.671 15)"
                fill="#6cb8b0"
                opacity="0.5"
              />
              <text
                transform="translate(152.11 30)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{`${s_config.GreenBar_percent}%`}</tspan> */}
                <tspan x="0" y="0">{`${(
                  (s_first.realsopno / s_first.sopcount) *
                  100
                ).toFixed(1)}%`}</tspan>
              </text>
              <text
                transform="translate(283.841 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan
                  x="0"
                  y="0"
                >{`${s_first.realsopno}/${s_first.sopcount}`}</tspan>
              </text>
              <path
                d="M8,0H75.672a0,0,0,0,1,0,0V44.76a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(0 0)"
                fill="#6cb8b0"
              />
              <text
                transform="translate(5.672 30.791)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  投料序
                </tspan>
              </text>
            </g>
            <path
              d="M16936.264,12302.661l-.008-5,135.279-.161.008,5Z"
              transform="translate(-15521.41 -11901.887)"
              fill={is_blueLine ? null : "#082246"}
            >
              {is_blueLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#083fef"
                  to="#082246"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <g transform="translate(1534.578 367.584)">
              <rect
                width="144.047"
                height="202.408"
                rx="10"
                transform="translate(11.962 4.902)"
                fill="url(#a)"
              />
              <path
                d="M10,1a9.01,9.01,0,0,0-9,9V192.408a9.01,9.01,0,0,0,9,9H134.047a9.01,9.01,0,0,0,9-9V10a9.01,9.01,0,0,0-9-9H10m0-1H134.047a10,10,0,0,1,10,10V192.408a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10V10A10,10,0,0,1,10,0Z"
                transform="translate(11.962 4.902)"
                fill="#1a1311"
              />
              <rect
                width="167.707"
                height="36.749"
                rx="3"
                transform="translate(0 -20.771)"
                fill="url(#b)"
              />
              <path
                d="M3-.5H164.707a3.5,3.5,0,0,1,3.5,3.5V33.749a3.5,3.5,0,0,1-3.5,3.5H3a3.5,3.5,0,0,1-3.5-3.5V3A3.5,3.5,0,0,1,3-.5ZM164.707,36.249a2.5,2.5,0,0,0,2.5-2.5V3a2.5,2.5,0,0,0-2.5-2.5H3A2.5,2.5,0,0,0,.5,3V33.749a2.5,2.5,0,0,0,2.5,2.5Z"
                transform="translate(0 -20.771)"
                fill="#1a1311"
              />
              <text
                transform="translate(42.853 6.729)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  {" "}
                  溶劑槽B
                </tspan>
              </text>
              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 51.029)"
                fill="#FFF"
              />
              <g transform="translate(40.399 51.029)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {/*溶劑槽B:目前存量 */}
                  {s_utility.wb}
                </text>
              </g>
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 51.029)"
                fill="#1a1311"
              />
              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 111.031)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 111.031)"
                fill="#1a1311"
              />
              <g transform="translate(40.399 111.031)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_utility.wb0}
                </text>{" "}
                {/*溶劑槽B:目前存量 */}
              </g>
              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 171.033)"
                fill="#fff"
              />
              <g transform="translate(40.399 171.033)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_utility.wbmax}
                </text>{" "}
                {/*溶劑槽B:最大載量 */}
              </g>
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 171.033)"
                fill="#1a1311"
              />
              <text
                transform="translate(40.399 42.892)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  目前存量
                </tspan>
              </text>
              {/* <text transform="translate(65.048 70.434)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wb}</tspan>
            </text> */}
              {/* <text transform="translate(65.048 131.031)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wb0}</tspan>
            </text> */}
              {/* <text transform="translate(65.048 190.438)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wbmax}</tspan>
            </text> */}
              <text
                transform="translate(40.399 102.894)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  預設扣重
                </tspan>
              </text>
              <text
                transform="translate(40.399 162.896)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  最大載重
                </tspan>
              </text>
              <text
                transform="translate(113.308 68.731)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              <text
                transform="translate(113.308 128.733)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              <text
                transform="translate(113.308 188.735)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
            </g>
            <g transform="translate(1737.619 367.584)">
              <rect
                width="144.047"
                height="202.408"
                rx="10"
                transform="translate(11.962 4.902)"
                fill="url(#a)"
              />
              <path
                d="M10,1a9.01,9.01,0,0,0-9,9V192.408a9.01,9.01,0,0,0,9,9H134.047a9.01,9.01,0,0,0,9-9V10a9.01,9.01,0,0,0-9-9H10m0-1H134.047a10,10,0,0,1,10,10V192.408a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10V10A10,10,0,0,1,10,0Z"
                transform="translate(11.962 4.902)"
                fill="#1a1311"
              />
              <rect
                width="167.707"
                height="36.749"
                rx="3"
                transform="translate(0 -20.771)"
                fill="url(#b)"
              />
              <path
                d="M3-.5H164.707a3.5,3.5,0,0,1,3.5,3.5V33.749a3.5,3.5,0,0,1-3.5,3.5H3a3.5,3.5,0,0,1-3.5-3.5V3A3.5,3.5,0,0,1,3-.5ZM164.707,36.249a2.5,2.5,0,0,0,2.5-2.5V3a2.5,2.5,0,0,0-2.5-2.5H3A2.5,2.5,0,0,0,.5,3V33.749a2.5,2.5,0,0,0,2.5,2.5Z"
                transform="translate(0 -20.771)"
                fill="#1a1311"
              />
              <text
                transform="translate(42.853 6.729)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  {" "}
                  溶劑槽A
                </tspan>
              </text>
              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 51.029)"
                fill="#fff"
              />
              <g transform="translate(40.399 51.029)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_utility.wa}
                </text>
              </g>
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 51.029)"
                fill="#1a1311"
              />

              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 111.031)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 111.031)"
                fill="#1a1311"
              />
              <g transform="translate(40.399 111.031)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_utility.wa0}
                </text>
              </g>
              <rect
                width="70.301"
                height="25.405"
                transform="translate(40.399 171.033)"
                fill="#fff"
              />
              <g transform="translate(40.399 171.033)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_utility.wamax}
                </text>
              </g>
              <path
                d="M-.5-.5H70.8V25.905H-.5ZM69.8.5H.5V24.905H69.8Z"
                transform="translate(40.399 171.033)"
                fill="#1a1311"
              />
              <text
                transform="translate(40.399 42.892)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  目前存量
                </tspan>
              </text>
              <text
                transform="translate(40.399 102.894)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  預設扣重
                </tspan>
              </text>
              <text
                transform="translate(40.399 162.896)"
                fill="#fff"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  最大載重
                </tspan>
              </text>
              <text
                transform="translate(113.308 68.731)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              <text
                transform="translate(113.308 128.733)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              <text
                transform="translate(113.308 188.735)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              {/* <text transform="translate(65.049 70.434)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wa}</tspan>
            </text> */}
              {/* <text transform="translate(65.049 130.733)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wa0}</tspan>
            </text>
            <text transform="translate(65.049 190.734)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wamax}</tspan>
            </text> */}
            </g>
            <path
              d="M15385.485,11831.358H15366v-241.2l-108.24-.4.018-5,113.223.416v241.186h14.482Z"
              transform="translate(-13848.303 -11158.154)"
              fill="#707070"
            />
            <path transform="translate(-4081.072 -365.376)" fill="#0092e5" />
            <g transform="translate(1667.503 660.54)">
              <rect
                width="72.595"
                height="28"
                transform="translate(36.233 -3.789)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H73.1v29H-.5ZM72.1.5H.5v27H72.1Z"
                transform="translate(36.233 -3.789)"
                fill="#1a1311"
              />
              <text
                transform="translate(-19 19.211)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
            </g>
            <g transform="translate(23.543 -218.402)">
              <path
                d="M0,0H316.2a8,8,0,0,1,8,8V129.585a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(1557.582 246.857)"
                fill="#cdeef5"
              />
              <path
                d="M8,0H46.548V137.585H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(1511.034 246.857)"
                fill="#bddee5"
              />
              <text
                transform="translate(1522.308 311.718)"
                fill="#1a1311"
                fontSize="24"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氣
                </tspan>
                <tspan x="0" y="15" xmlSpace="preserve">
                  {" "}
                </tspan>
                <tspan x="0" y="30">
                  路
                </tspan>
              </text>
              <g transform="translate(1670.115 267.475)">
                <path
                  d="M178.756,1592.673a47.982,47.982,0,1,0,47.982-47.982A47.981,47.981,0,0,0,178.756,1592.673Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#dcd8d9"
                />
                <path
                  d="M226.738,1641.654a48.831,48.831,0,1,1,19.066-3.85A48.676,48.676,0,0,1,226.738,1641.654Zm0-95.963a46.982,46.982,0,1,0,46.981,46.982A47.035,47.035,0,0,0,226.738,1545.691Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#1a1311"
                />
                <path
                  d="M202.186,1606.938a39.125,39.125,0,1,0,39.125-39.125A39.125,39.125,0,0,0,202.186,1606.938Z"
                  transform="translate(-193.329 -1559.073)"
                  fill="#fff"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(75.3 27.546)"
                  fill="#1a1311"
                />
                <path
                  d="M5.364,4.941-.768,1.289.768-1.289,6.9,2.363Z"
                  transform="translate(75.029 64.694)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(34.044 40.326)"
                  fill="#1a1311"
                  fontSize="14"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    氧氣
                  </tspan>
                </text>
                <rect
                  width="68.836"
                  height="23.795"
                  rx="6"
                  transform="translate(14.261 75.092)"
                  fill="#fffad9"
                />
                <g transform="translate(14.261 75.092)">
                  <text textAnchor="middle" x="30" y="20" fontSize="21">
                    {s_utility.o2tube}
                  </text>
                </g>
                <path
                  d="M6-.5H62.836a6.507,6.507,0,0,1,6.5,6.5V17.795a6.507,6.507,0,0,1-6.5,6.5H6a6.507,6.507,0,0,1-6.5-6.5V6A6.507,6.507,0,0,1,6-.5ZM62.836,23.295a5.506,5.506,0,0,0,5.5-5.5V6a5.506,5.506,0,0,0-5.5-5.5H6A5.506,5.506,0,0,0,.5,6V17.795a5.506,5.506,0,0,0,5.5,5.5Z"
                  transform="translate(14.261 75.092)"
                />
                <g transform="translate(-187.001 -1620.048)" className="o2tube">
                  <path
                    d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                    transform="translate(-187.001 -1620.048)"
                    fill="red"
                  />
                </g>
                <ellipse
                  cx="3.657"
                  cy="3.657"
                  rx="3.657"
                  ry="3.657"
                  transform="translate(44.357 44.325)"
                  fill="#0062ae"
                />
                <path
                  d="M1.5,7.137h-3V0h3Z"
                  transform="translate(47.982 8.872)"
                  fill="#1a1311"
                />
                <path
                  d="M8.036,20.366a30.4,30.4,0,0,1,4.982-16.03L6.23,0A39.211,39.211,0,0,0,0,20.368,36.24,36.24,0,0,0,6.088,40.522l7.332-3.971A29.29,29.29,0,0,1,8.036,20.366Z"
                  transform="translate(7.41 29.079) rotate(-2)"
                  fill="#c32511"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(14.609 65.44)"
                  fill="#1a1311"
                />
                <path
                  d="M-1616.47,28.648a45.221,45.221,0,0,1,11.019-12.722l-3.525-6.113c-6.159,3.363-10.545,8.456-14.306,14.726Z"
                  transform="translate(1636.989 4.361)"
                  fill="#f27f03"
                />
                <path
                  d="M5.364,4.94-.767,1.289.767-1.289,6.9,2.363Z"
                  transform="translate(12.911 30.103)"
                  fill="#1a1311"
                />
                <g transform="translate(17.891 9.783)">
                  <path
                    d="M324.108,1573.77l-.607-3.2-.579.116.675,3.182Z"
                    transform="translate(-300.53 -1570.574)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M343.426,1578.171l1.4,2.948.491-.234-1.395-2.971Z"
                    transform="translate(-329.485 -1575.14)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M380.368,1608.753l-2.672-2.061-.327.421,2.656,2.08Z"
                    transform="translate(-377.37 -1593.04)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M364.46,1592.606l-2.029-2.683-.413.283,1.973,2.728Z"
                    transform="translate(-355.658 -1582.609)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(53.588 9.657)">
                  <path
                    d="M287.945,1573.451q.261-1.605.521-3.211l.582.1q-.3,1.6-.59,3.2Z"
                    transform="translate(-287.945 -1570.24)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M267.2,1577.223q-.66,1.492-1.317,2.983l-.5-.22,1.316-3.007Z"
                    transform="translate(-257.574 -1574.432)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M227.745,1606.887l2.616-2.129.338.412-2.6,2.15Z"
                    transform="translate(-206.84 -1591.711)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M245.015,1591.183q.978-1.369,1.957-2.736l.421.271-1.9,2.779Z"
                    transform="translate(-230.063 -1581.565)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(80.471 34.397)">
                  <path
                    d="M211.186,1702.97l3.022,1.2c.07-.182.142-.367.212-.55l-3.047-1.138Z"
                    transform="translate(-211.186 -1677.237)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M209,1681.825l-3.237-.4c-.022.18-.045.359-.067.54l3.256.417Q208.972,1682.1,209,1681.825Z"
                    transform="translate(-203.686 -1664.136)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M210.267,1637.342l3.171-1.149c-.059-.167-.119-.335-.18-.5l-3.18,1.125Z"
                    transform="translate(-209.785 -1635.691)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M205.1,1659.854l3.352-.273c-.006-.166-.011-.333-.017-.5l-3.36.2Q205.089,1659.569,205.1,1659.854Z"
                    transform="translate(-202.911 -1650.24)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(10.11 35.935)">
                  <path
                    d="M395.9,1640.85l-3.064-1.09c-.064.186-.129.372-.192.558l3.087,1.024Z"
                    transform="translate(-390.889 -1639.76)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M397.166,1663.52l3.25.283c.015-.181.031-.361.047-.542l-3.269-.3C397.184,1663.149,397.175,1663.335,397.166,1663.52Z"
                    transform="translate(-397.166 -1654.194)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M394.118,1706.358l-3.128,1.267c.067.164.132.329.2.494l3.136-1.242Z"
                    transform="translate(-388.694 -1681.185)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M400.062,1685.473l-3.34.4q.019.249.036.5,1.675-.161,3.35-.327C400.093,1685.853,400.077,1685.663,400.062,1685.473Z"
                    transform="translate(-396.644 -1668.194)"
                    fillRule="evenodd"
                  />
                </g>
                <text
                  transform="translate(24.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    0
                  </tspan>
                </text>
                <text
                  transform="translate(68.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    4
                  </tspan>
                </text>
                <text
                  transform="translate(22.832 37.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    1
                  </tspan>
                </text>
                <text
                  transform="translate(46.832 22.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    2
                  </tspan>
                </text>
                <text
                  transform="translate(69.832 35.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    3
                  </tspan>
                </text>
              </g>
              <g transform="translate(1566.882 267.475)">
                <path
                  d="M178.756,1592.673a47.982,47.982,0,1,0,47.982-47.982A47.981,47.981,0,0,0,178.756,1592.673Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#dcd8d9"
                />
                <path
                  d="M226.738,1641.654a48.831,48.831,0,1,1,19.066-3.85A48.676,48.676,0,0,1,226.738,1641.654Zm0-95.963a46.982,46.982,0,1,0,46.981,46.982A47.035,47.035,0,0,0,226.738,1545.691Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#1a1311"
                />
                <path
                  d="M202.186,1606.938a39.125,39.125,0,1,0,39.125-39.125A39.125,39.125,0,0,0,202.186,1606.938Z"
                  transform="translate(-193.329 -1559.073)"
                  fill="#fff"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(75.3 27.546)"
                  fill="#1a1311"
                />
                <path
                  d="M5.364,4.941-.768,1.289.768-1.289,6.9,2.363Z"
                  transform="translate(75.029 64.694)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(34.044 40.326)"
                  fill="#1a1311"
                  fontSize="14"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    氮氣
                  </tspan>
                </text>
                <rect
                  width="68.836"
                  height="23.795"
                  rx="6"
                  transform="translate(14.261 75.092)"
                  fill="#fffad9"
                />
                <g transform="translate(14.261 75.092)">
                  <text textAnchor="middle" x="35" y="20" fontSize="21">
                    {s_utility.n2tube}
                  </text>
                </g>
                <path
                  d="M6-.5H62.836a6.507,6.507,0,0,1,6.5,6.5V17.795a6.507,6.507,0,0,1-6.5,6.5H6a6.507,6.507,0,0,1-6.5-6.5V6A6.507,6.507,0,0,1,6-.5ZM62.836,23.295a5.506,5.506,0,0,0,5.5-5.5V6a5.506,5.506,0,0,0-5.5-5.5H6A5.506,5.506,0,0,0,.5,6V17.795a5.506,5.506,0,0,0,5.5,5.5Z"
                  transform="translate(14.261 75.092)"
                />
                {/* 氮氣的針 */}
                {/* <g cx="3.657" cy="3.657" rx="3.657" ry="3.657" transform="translate(44.357 44.325)" className="pointer" >
                <path d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                  transform="translate(-182.001 -1618.048)" fill="#1a1311" />
              </g> */}
                <g transform="translate(-187.001 -1620.048)" className="n2tube">
                  <path
                    d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                    transform="translate(-187.001 -1620.048)"
                    fill="red"
                  />
                </g>

                <ellipse
                  cx="3.657"
                  cy="3.657"
                  rx="3.657"
                  ry="3.657"
                  transform="translate(44.357 44.325)"
                  fill="#0062ae"
                />
                {/* <circle cx="3.657" cy="3.657" rx="3.657" ry="3.657" transform="translate(44.357 44.325)" id="center" fill="blue" r="10" /> */}
                {/* 氮氣的針 */}
                <path
                  d="M-1913.987,55.325a30.354,30.354,0,0,1-5.412-17.339,30.5,30.5,0,0,1,30.5-30.5,31.1,31.1,0,0,1,15.6,4.051l4.279-7.425c-6.024-3.785-12.237-5.261-19.877-5.261a39.138,39.138,0,0,0-39.139,39.139A38.942,38.942,0,0,0-1921.52,59.6Z"
                  transform="translate(1936.891 10.024)"
                  fill="#c32511"
                />
                <path
                  d="M5.364,4.94-.767,1.289.767-1.289,6.9,2.363Z"
                  transform="translate(12.911 30.103)"
                  fill="#1a1311"
                />
                <path
                  d="M1.5,7.137h-3V0h3Z"
                  transform="translate(47.982 8.872)"
                  fill="#1a1311"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(14.609 65.44)"
                  fill="#1a1311"
                />
                <path
                  d="M0,7.053a32.385,32.385,0,0,1,7.557,5.878L13.5,7.167A37.67,37.67,0,0,0,4.928,0Z"
                  transform="translate(62.332 13.951) rotate(-2)"
                  fill="#f27f03"
                />
                <g transform="translate(17.891 9.783)">
                  <path
                    d="M324.108,1573.77l-.607-3.2-.579.116.675,3.182Z"
                    transform="translate(-300.53 -1570.574)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M343.426,1578.171l1.4,2.948.491-.234-1.395-2.971Z"
                    transform="translate(-329.485 -1575.14)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M380.368,1608.753l-2.672-2.061-.327.421,2.656,2.08Z"
                    transform="translate(-377.37 -1593.04)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M364.46,1592.606l-2.029-2.683-.413.283,1.973,2.728Z"
                    transform="translate(-355.658 -1582.609)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(53.588 9.657)">
                  <path
                    d="M287.945,1573.451q.261-1.605.521-3.211l.582.1q-.3,1.6-.59,3.2Z"
                    transform="translate(-287.945 -1570.24)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M267.2,1577.223q-.66,1.492-1.317,2.983l-.5-.22,1.316-3.007Z"
                    transform="translate(-257.574 -1574.432)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M227.745,1606.887l2.616-2.129.338.412-2.6,2.15Z"
                    transform="translate(-206.84 -1591.711)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M245.015,1591.183q.978-1.369,1.957-2.736l.421.271-1.9,2.779Z"
                    transform="translate(-230.063 -1581.565)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(80.471 34.397)">
                  <path
                    d="M211.186,1702.97l3.022,1.2c.07-.182.142-.367.212-.55l-3.047-1.138Z"
                    transform="translate(-211.186 -1677.237)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M209,1681.825l-3.237-.4c-.022.18-.045.359-.067.54l3.256.417Q208.972,1682.1,209,1681.825Z"
                    transform="translate(-203.686 -1664.136)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M210.267,1637.342l3.171-1.149c-.059-.167-.119-.335-.18-.5l-3.18,1.125Z"
                    transform="translate(-209.785 -1635.691)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M205.1,1659.854l3.352-.273c-.006-.166-.011-.333-.017-.5l-3.36.2Q205.089,1659.569,205.1,1659.854Z"
                    transform="translate(-202.911 -1650.24)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(10.11 35.935)">
                  <path
                    d="M395.9,1640.85l-3.064-1.09c-.064.186-.129.372-.192.558l3.087,1.024Z"
                    transform="translate(-390.889 -1639.76)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M397.166,1663.52l3.25.283c.015-.181.031-.361.047-.542l-3.269-.3C397.184,1663.149,397.175,1663.335,397.166,1663.52Z"
                    transform="translate(-397.166 -1654.194)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M394.118,1706.358l-3.128,1.267c.067.164.132.329.2.494l3.136-1.242Z"
                    transform="translate(-388.694 -1681.185)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M400.062,1685.473l-3.34.4q.019.249.036.5,1.675-.161,3.35-.327C400.093,1685.853,400.077,1685.663,400.062,1685.473Z"
                    transform="translate(-396.644 -1668.194)"
                    fillRule="evenodd"
                  />
                </g>
                <text
                  transform="translate(24.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    0
                  </tspan>
                </text>
                <text
                  transform="translate(68.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    8
                  </tspan>
                </text>
                <text
                  transform="translate(22.832 37.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    2
                  </tspan>
                </text>
                <text
                  transform="translate(46.832 22.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    4
                  </tspan>
                </text>
                <text
                  transform="translate(69.832 35.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    6
                  </tspan>
                </text>
              </g>
              <g transform="translate(1776.045 267.475)">
                <path
                  d="M178.756,1592.673a47.982,47.982,0,1,0,47.982-47.982A47.981,47.981,0,0,0,178.756,1592.673Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#dcd8d9"
                />
                <path
                  d="M226.738,1641.654a48.831,48.831,0,1,1,19.066-3.85A48.676,48.676,0,0,1,226.738,1641.654Zm0-95.963a46.982,46.982,0,1,0,46.981,46.982A47.035,47.035,0,0,0,226.738,1545.691Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#1a1311"
                />
                <path
                  d="M202.186,1606.938a39.125,39.125,0,1,0,39.125-39.125A39.125,39.125,0,0,0,202.186,1606.938Z"
                  transform="translate(-193.329 -1559.073)"
                  fill="#fff"
                />
                <path
                  d="M5.364,4.941-.768,1.289.768-1.289,6.9,2.363Z"
                  transform="translate(75.029 64.694)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(34.044 40.326)"
                  fill="#1a1311"
                  fontSize="14"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    空壓
                  </tspan>
                </text>
                <rect
                  width="68.836"
                  height="23.795"
                  rx="6"
                  transform="translate(14.261 75.092)"
                  fill="#fffad9"
                />
                <g transform="translate(14.261 75.092)">
                  <text textAnchor="middle" x="35" y="20" fontSize="21">
                    {s_utility.airtube}
                  </text>
                </g>
                <path
                  d="M6-.5H62.836a6.507,6.507,0,0,1,6.5,6.5V17.795a6.507,6.507,0,0,1-6.5,6.5H6a6.507,6.507,0,0,1-6.5-6.5V6A6.507,6.507,0,0,1,6-.5ZM62.836,23.295a5.506,5.506,0,0,0,5.5-5.5V6a5.506,5.506,0,0,0-5.5-5.5H6A5.506,5.506,0,0,0,.5,6V17.795a5.506,5.506,0,0,0,5.5,5.5Z"
                  transform="translate(14.261 75.092)"
                />
                <g
                  transform="translate(-187.001 -1620.048)"
                  className="airtube"
                >
                  <path
                    d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                    transform="translate(-187.001 -1620.048)"
                    fill="red"
                  />
                </g>
                <ellipse
                  cx="3.657"
                  cy="3.657"
                  rx="3.657"
                  ry="3.657"
                  transform="translate(44.357 44.325)"
                  fill="#0062ae"
                />
                <path
                  d="M14.169,56.81A30.665,30.665,0,0,1,39.355,8.642c16.94,0,30.672,12.732,30.672,29.673,0,.654,0,.712,0,.932l8.635.014c.023-.374,0-.4,0-1.035C78.662,16.49,61.091,0,39.355,0A39.36,39.36,0,0,0,6.709,61.341Z"
                  transform="translate(8.397 8.74)"
                  fill="#c32511"
                />
                <path
                  d="M1.5,7.137h-3V0h3Z"
                  transform="translate(47.982 8.872)"
                  fill="#1a1311"
                />
                <path
                  d="M5.364,4.94-.767,1.289.767-1.289,6.9,2.363Z"
                  transform="translate(12.911 30.103)"
                  fill="#1a1311"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(75.3 27.546)"
                  fill="#1a1311"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(14.609 65.44)"
                  fill="#1a1311"
                />
                <path
                  d="M1.393,0A32.793,32.793,0,0,1,0,7.608L8.13,10.7A39.178,39.178,0,0,0,10.057.263Z"
                  transform="translate(77.01 47.974) rotate(-3)"
                  fill="#f27f03"
                />
                <g transform="translate(17.891 9.783)">
                  <path
                    d="M324.108,1573.77l-.607-3.2-.579.116.675,3.182Z"
                    transform="translate(-300.53 -1570.574)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M343.426,1578.171l1.4,2.948.491-.234-1.395-2.971Z"
                    transform="translate(-329.485 -1575.14)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M380.368,1608.753l-2.672-2.061-.327.421,2.656,2.08Z"
                    transform="translate(-377.37 -1593.04)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M364.46,1592.606l-2.029-2.683-.413.283,1.973,2.728Z"
                    transform="translate(-355.658 -1582.609)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(53.588 9.657)">
                  <path
                    d="M287.945,1573.451q.261-1.605.521-3.211l.582.1q-.3,1.6-.59,3.2Z"
                    transform="translate(-287.945 -1570.24)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M267.2,1577.223q-.66,1.492-1.317,2.983l-.5-.22,1.316-3.007Z"
                    transform="translate(-257.574 -1574.432)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M227.745,1606.887l2.616-2.129.338.412-2.6,2.15Z"
                    transform="translate(-206.84 -1591.711)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M245.015,1591.183q.978-1.369,1.957-2.736l.421.271-1.9,2.779Z"
                    transform="translate(-230.063 -1581.565)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(80.471 34.397)">
                  <path
                    d="M211.186,1702.97l3.022,1.2c.07-.182.142-.367.212-.55l-3.047-1.138Z"
                    transform="translate(-211.186 -1677.237)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M209,1681.825l-3.237-.4c-.022.18-.045.359-.067.54l3.256.417Q208.972,1682.1,209,1681.825Z"
                    transform="translate(-203.686 -1664.136)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M210.267,1637.342l3.171-1.149c-.059-.167-.119-.335-.18-.5l-3.18,1.125Z"
                    transform="translate(-209.785 -1635.691)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M205.1,1659.854l3.352-.273c-.006-.166-.011-.333-.017-.5l-3.36.2Q205.089,1659.569,205.1,1659.854Z"
                    transform="translate(-202.911 -1650.24)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(10.11 35.935)">
                  <path
                    d="M395.9,1640.85l-3.064-1.09c-.064.186-.129.372-.192.558l3.087,1.024Z"
                    transform="translate(-390.889 -1639.76)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M397.166,1663.52l3.25.283c.015-.181.031-.361.047-.542l-3.269-.3C397.184,1663.149,397.175,1663.335,397.166,1663.52Z"
                    transform="translate(-397.166 -1654.194)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M394.118,1706.358l-3.128,1.267c.067.164.132.329.2.494l3.136-1.242Z"
                    transform="translate(-388.694 -1681.185)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M400.062,1685.473l-3.34.4q.019.249.036.5,1.675-.161,3.35-.327C400.093,1685.853,400.077,1685.663,400.062,1685.473Z"
                    transform="translate(-396.644 -1668.194)"
                    fillRule="evenodd"
                  />
                </g>
                <text
                  transform="translate(24.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    0
                  </tspan>
                </text>
                <text
                  transform="translate(68.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    8
                  </tspan>
                </text>
                <text
                  transform="translate(22.832 37.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    2
                  </tspan>
                </text>
                <text
                  transform="translate(46.832 22.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    4
                  </tspan>
                </text>
                <text
                  transform="translate(69.832 35.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    6
                  </tspan>
                </text>
              </g>
              {/* <text transform="translate(1597.364 360.704)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan className="Z2" x="0" y="0">{s_utility.n2tube}</tspan>
            </text>
            <text transform="translate(1702.184 360.704)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.o2tube}</tspan>
            </text>
            <text transform="translate(1806.525 360.704)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.airtube}</tspan>
            </text> */}
            </g>
            <g transform="translate(23.545 -212.074)">
              <rect
                width="370.75"
                height="137.397"
                rx="8"
                transform="translate(1511.033 399.895)"
                fill="#cdeef5"
              />
              <path
                d="M8,0H46.548V137.585H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                transform="translate(1511.034 399.708)"
                fill="#bddee5"
              />
              <text
                transform="translate(1522.308 464.567)"
                fill="#1a1311"
                fontSize="24"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  水
                </tspan>
                <tspan x="0" y="15" xmlSpace="preserve">
                  {" "}
                </tspan>
                <tspan x="0" y="30">
                  路
                </tspan>
              </text>
              <g transform="translate(1600.445 420.125)">
                <path
                  d="M178.756,1592.673a47.982,47.982,0,1,0,47.982-47.982A47.981,47.981,0,0,0,178.756,1592.673Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#dcd8d9"
                />
                <path
                  d="M226.738,1641.654a48.831,48.831,0,1,1,19.066-3.85A48.676,48.676,0,0,1,226.738,1641.654Zm0-95.963a46.982,46.982,0,1,0,46.981,46.982A47.035,47.035,0,0,0,226.738,1545.691Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#1a1311"
                />
                <path
                  d="M202.186,1606.938a39.125,39.125,0,1,0,39.125-39.125A39.125,39.125,0,0,0,202.186,1606.938Z"
                  transform="translate(-193.329 -1559.073)"
                  fill="#fff"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(75.3 27.546)"
                  fill="#1a1311"
                />
                <path
                  d="M5.364,4.941-.768,1.289.768-1.289,6.9,2.363Z"
                  transform="translate(75.029 64.694)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(27.679 40.326)"
                  fill="#1a1311"
                  fontSize="14"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    冷卻水
                  </tspan>
                </text>
                <rect
                  width="68.836"
                  height="23.795"
                  rx="6"
                  transform="translate(14.261 75.092)"
                  fill="#fffad9"
                />
                <g transform="translate(14.261 75.092)">
                  <text textAnchor="middle" x="35" y="20" fontSize="21">
                    {s_utility.cwpipe}
                  </text>
                </g>
                <path
                  d="M6-.5H62.836a6.507,6.507,0,0,1,6.5,6.5V17.795a6.507,6.507,0,0,1-6.5,6.5H6a6.507,6.507,0,0,1-6.5-6.5V6A6.507,6.507,0,0,1,6-.5ZM62.836,23.295a5.506,5.506,0,0,0,5.5-5.5V6a5.506,5.506,0,0,0-5.5-5.5H6A5.506,5.506,0,0,0,.5,6V17.795a5.506,5.506,0,0,0,5.5,5.5Z"
                  transform="translate(14.261 75.092)"
                />
                <g transform="translate(-187.001 -1620.048)" className="cwpipe">
                  <path
                    d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                    transform="translate(-187.001 -1620.048)"
                    fill="red"
                  />
                </g>
                <ellipse
                  cx="3.657"
                  cy="3.657"
                  rx="3.657"
                  ry="3.657"
                  transform="translate(44.357 44.325)"
                  fill="#0062ae"
                />
                <path
                  d="M-1913.987,55.325a30.354,30.354,0,0,1-5.412-17.339,30.5,30.5,0,0,1,30.5-30.5,31.1,31.1,0,0,1,15.6,4.051l4.279-7.425c-6.024-3.785-12.237-5.261-19.877-5.261a39.138,39.138,0,0,0-39.139,39.139A38.942,38.942,0,0,0-1921.52,59.6Z"
                  transform="translate(1936.891 10.024)"
                  fill="#c32511"
                />
                <path
                  d="M5.364,4.94-.767,1.289.767-1.289,6.9,2.363Z"
                  transform="translate(12.911 30.103)"
                  fill="#1a1311"
                />
                <path
                  d="M1.5,7.137h-3V0h3Z"
                  transform="translate(47.982 8.872)"
                  fill="#1a1311"
                />
                <path
                  d="M8.293,14.309l6.693-5A41.251,41.251,0,0,0,4.83,0L0,7.143A45.69,45.69,0,0,1,8.293,14.309Z"
                  transform="translate(62.627 14.045) rotate(-2)"
                  fill="#f27f03"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(14.609 65.44)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(24.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    0
                  </tspan>
                </text>
                <text
                  transform="translate(68.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    4
                  </tspan>
                </text>
                <text
                  transform="translate(22.832 37.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    1
                  </tspan>
                </text>
                <text
                  transform="translate(46.832 22.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    2
                  </tspan>
                </text>
                <text
                  transform="translate(69.832 35.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    3
                  </tspan>
                </text>
                <g transform="translate(17.891 9.783)">
                  <path
                    d="M324.108,1573.77l-.607-3.2-.579.116.675,3.182Z"
                    transform="translate(-300.53 -1570.574)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M343.426,1578.171l1.4,2.948.491-.234-1.395-2.971Z"
                    transform="translate(-329.485 -1575.14)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M380.368,1608.753l-2.672-2.061-.327.421,2.656,2.08Z"
                    transform="translate(-377.37 -1593.04)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M364.46,1592.606l-2.029-2.683-.413.283,1.973,2.728Z"
                    transform="translate(-355.658 -1582.609)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(53.588 9.657)">
                  <path
                    d="M287.945,1573.451q.261-1.605.521-3.211l.582.1q-.3,1.6-.59,3.2Z"
                    transform="translate(-287.945 -1570.24)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M267.2,1577.223q-.66,1.492-1.317,2.983l-.5-.22,1.316-3.007Z"
                    transform="translate(-257.574 -1574.432)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M227.745,1606.887l2.616-2.129.338.412-2.6,2.15Z"
                    transform="translate(-206.84 -1591.711)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M245.015,1591.183q.978-1.369,1.957-2.736l.421.271-1.9,2.779Z"
                    transform="translate(-230.063 -1581.565)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(80.471 34.397)">
                  <path
                    d="M211.186,1702.97l3.022,1.2c.07-.182.142-.367.212-.55l-3.047-1.138Z"
                    transform="translate(-211.186 -1677.237)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M209,1681.825l-3.237-.4c-.022.18-.045.359-.067.54l3.256.417Q208.972,1682.1,209,1681.825Z"
                    transform="translate(-203.686 -1664.136)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M210.267,1637.342l3.171-1.149c-.059-.167-.119-.335-.18-.5l-3.18,1.125Z"
                    transform="translate(-209.785 -1635.691)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M205.1,1659.854l3.352-.273c-.006-.166-.011-.333-.017-.5l-3.36.2Q205.089,1659.569,205.1,1659.854Z"
                    transform="translate(-202.911 -1650.24)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(10.11 35.935)">
                  <path
                    d="M395.9,1640.85l-3.064-1.09c-.064.186-.129.372-.192.558l3.087,1.024Z"
                    transform="translate(-390.889 -1639.76)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M397.166,1663.52l3.25.283c.015-.181.031-.361.047-.542l-3.269-.3C397.184,1663.149,397.175,1663.335,397.166,1663.52Z"
                    transform="translate(-397.166 -1654.194)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M394.118,1706.358l-3.128,1.267c.067.164.132.329.2.494l3.136-1.242Z"
                    transform="translate(-388.694 -1681.185)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M400.062,1685.473l-3.34.4q.019.249.036.5,1.675-.161,3.35-.327C400.093,1685.853,400.077,1685.663,400.062,1685.473Z"
                    transform="translate(-396.644 -1668.194)"
                    fillRule="evenodd"
                  />
                </g>
              </g>
              <g transform="translate(1739.736 420.125)">
                <path
                  d="M178.756,1592.673a47.982,47.982,0,1,0,47.982-47.982A47.981,47.981,0,0,0,178.756,1592.673Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#dcd8d9"
                />
                <path
                  d="M226.738,1641.654a48.831,48.831,0,1,1,19.066-3.85A48.676,48.676,0,0,1,226.738,1641.654Zm0-95.963a46.982,46.982,0,1,0,46.981,46.982A47.035,47.035,0,0,0,226.738,1545.691Z"
                  transform="translate(-178.756 -1544.691)"
                  fill="#1a1311"
                />
                <path
                  d="M202.186,1606.938a39.125,39.125,0,1,0,39.125-39.125A39.125,39.125,0,0,0,202.186,1606.938Z"
                  transform="translate(-193.329 -1559.073)"
                  fill="#fff"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(75.3 27.546)"
                  fill="#1a1311"
                />
                <path
                  d="M5.364,4.941-.768,1.289.768-1.289,6.9,2.363Z"
                  transform="translate(75.029 64.694)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(34.331 40.326)"
                  fill="#1a1311"
                  fontSize="14"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  <tspan x="0" y="0">
                    冰水
                  </tspan>
                </text>
                <rect
                  width="68.836"
                  height="23.795"
                  rx="6"
                  transform="translate(14.261 75.092)"
                  fill="#fffad9"
                />
                <g transform="translate(14.261 75.092)">
                  <text textAnchor="middle" x="35" y="20" fontSize="21">
                    {s_utility.wpipe}
                  </text>
                </g>
                <path
                  d="M6-.5H62.836a6.507,6.507,0,0,1,6.5,6.5V17.795a6.507,6.507,0,0,1-6.5,6.5H6a6.507,6.507,0,0,1-6.5-6.5V6A6.507,6.507,0,0,1,6-.5ZM62.836,23.295a5.506,5.506,0,0,0,5.5-5.5V6a5.506,5.506,0,0,0-5.5-5.5H6A5.506,5.506,0,0,0,.5,6V17.795a5.506,5.506,0,0,0,5.5,5.5Z"
                  transform="translate(14.261 75.092)"
                />

                <g transform="translate(-187.001 -1620.048)" className="wpipe">
                  <path
                    d="M263.527,1676.192s-32.261-12.515-33.314-10.023S252.076,1674.564,263.527,1676.192Z"
                    transform="translate(-187.001 -1620.048)"
                    fill="red"
                  />
                </g>
                <ellipse
                  cx="3.657"
                  cy="3.657"
                  rx="3.657"
                  ry="3.657"
                  transform="translate(44.357 44.325)"
                  fill="#0062ae"
                />
                <path
                  d="M-1913.987,55.325a30.354,30.354,0,0,1-5.412-17.339,30.5,30.5,0,0,1,30.5-30.5,31.1,31.1,0,0,1,15.6,4.051l4.279-7.425c-6.024-3.785-12.237-5.261-19.877-5.261a39.138,39.138,0,0,0-39.139,39.139A38.942,38.942,0,0,0-1921.52,59.6Z"
                  transform="translate(1936.891 10.024)"
                  fill="#c32511"
                />
                <path
                  d="M5.364,4.94-.767,1.289.767-1.289,6.9,2.363Z"
                  transform="translate(12.911 30.103)"
                  fill="#1a1311"
                />
                <path
                  d="M1.5,7.137h-3V0h3Z"
                  transform="translate(47.982 8.872)"
                  fill="#1a1311"
                />
                <path
                  d="M8.293,14.309l6.693-5A41.251,41.251,0,0,0,4.83,0L0,7.143A45.69,45.69,0,0,1,8.293,14.309Z"
                  transform="translate(62.627 14.045) rotate(-2)"
                  fill="#f27f03"
                />
                <path
                  d="M.767,4.94-.767,2.363,5.364-1.289,6.9,1.289Z"
                  transform="translate(14.609 65.44)"
                  fill="#1a1311"
                />
                <text
                  transform="translate(24.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    0
                  </tspan>
                </text>
                <text
                  transform="translate(68.832 68.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    4
                  </tspan>
                </text>
                <text
                  transform="translate(22.832 37.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    1
                  </tspan>
                </text>
                <text
                  transform="translate(46.832 22.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    2
                  </tspan>
                </text>
                <text
                  transform="translate(69.832 35.788)"
                  fill="#707070"
                  fontSize="6"
                  fontFamily="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    3
                  </tspan>
                </text>
                <g transform="translate(17.891 9.783)">
                  <path
                    d="M324.108,1573.77l-.607-3.2-.579.116.675,3.182Z"
                    transform="translate(-300.53 -1570.574)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M343.426,1578.171l1.4,2.948.491-.234-1.395-2.971Z"
                    transform="translate(-329.485 -1575.14)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M380.368,1608.753l-2.672-2.061-.327.421,2.656,2.08Z"
                    transform="translate(-377.37 -1593.04)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M364.46,1592.606l-2.029-2.683-.413.283,1.973,2.728Z"
                    transform="translate(-355.658 -1582.609)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(53.588 9.657)">
                  <path
                    d="M287.945,1573.451q.261-1.605.521-3.211l.582.1q-.3,1.6-.59,3.2Z"
                    transform="translate(-287.945 -1570.24)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M267.2,1577.223q-.66,1.492-1.317,2.983l-.5-.22,1.316-3.007Z"
                    transform="translate(-257.574 -1574.432)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M227.745,1606.887l2.616-2.129.338.412-2.6,2.15Z"
                    transform="translate(-206.84 -1591.711)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M245.015,1591.183q.978-1.369,1.957-2.736l.421.271-1.9,2.779Z"
                    transform="translate(-230.063 -1581.565)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(80.471 34.397)">
                  <path
                    d="M211.186,1702.97l3.022,1.2c.07-.182.142-.367.212-.55l-3.047-1.138Z"
                    transform="translate(-211.186 -1677.237)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M209,1681.825l-3.237-.4c-.022.18-.045.359-.067.54l3.256.417Q208.972,1682.1,209,1681.825Z"
                    transform="translate(-203.686 -1664.136)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M210.267,1637.342l3.171-1.149c-.059-.167-.119-.335-.18-.5l-3.18,1.125Z"
                    transform="translate(-209.785 -1635.691)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M205.1,1659.854l3.352-.273c-.006-.166-.011-.333-.017-.5l-3.36.2Q205.089,1659.569,205.1,1659.854Z"
                    transform="translate(-202.911 -1650.24)"
                    fillRule="evenodd"
                  />
                </g>
                <g transform="translate(10.11 35.935)">
                  <path
                    d="M395.9,1640.85l-3.064-1.09c-.064.186-.129.372-.192.558l3.087,1.024Z"
                    transform="translate(-390.889 -1639.76)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M397.166,1663.52l3.25.283c.015-.181.031-.361.047-.542l-3.269-.3C397.184,1663.149,397.175,1663.335,397.166,1663.52Z"
                    transform="translate(-397.166 -1654.194)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M394.118,1706.358l-3.128,1.267c.067.164.132.329.2.494l3.136-1.242Z"
                    transform="translate(-388.694 -1681.185)"
                    fillRule="evenodd"
                  />
                  <path
                    d="M400.062,1685.473l-3.34.4q.019.249.036.5,1.675-.161,3.35-.327C400.093,1685.853,400.077,1685.663,400.062,1685.473Z"
                    transform="translate(-396.644 -1668.194)"
                    fillRule="evenodd"
                  />
                </g>
              </g>
              {/* <text transform="translate(1630.926 514.012)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.wpipe}</tspan>
            </text> */}
              {/* <text transform="translate(1770.218 514.012)" fill="#1a1311" fontSize="18"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_utility.cwpipe}</tspan>
            </text> */}
            </g>
            <g transform="translate(-751.757 -164.344)">
              <path transform="translate(-924.157 -174.09)" fill="#0092e5" />
              <path transform="translate(-3694.373 -177.187)" fill="#0092e5" />
              <text
                transform="translate(1067.508 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  扭力
                </tspan>
              </text>
              <text
                transform="translate(1066.507 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
              <rect
                width="83.998"
                height="24.615"
                transform="translate(779.08 470.729)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.115H-.5Zm84,1H.5V24.115h83Z"
                transform="translate(779.08 470.729)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 470.729)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_fourth.flowN}
                </text>
              </g>
              <rect
                width="83.998"
                height="24.616"
                transform="translate(779.08 532.537)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.116H-.5Zm84,1H.5V24.116h83Z"
                transform="translate(779.08 532.537)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 532.537)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_fourth.flowO}
                </text>
              </g>
              <text
                transform="translate(779.08 466.379)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氮氣流量
                </tspan>
              </text>
              <text
                transform="translate(778.757 528.185)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氧氣流量
                </tspan>
              </text>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(778.757 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(778.757 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_fourth.current}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1046.347 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1046.347 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(1046.347 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_fourth.torque}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1045.847 688.298)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1045.847 688.298)"
                fill="#1a1311"
              />
              <g transform="translate(1045.847 688.298)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_fourth.weight}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.616"
                transform="translate(778.757 687.563)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.116H-.5ZM83.821.5H.5V24.116H83.821Z"
                transform="translate(778.757 687.563)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 687.563)">
                <text textAnchor="middle" x="35" y="20" fontSize="21">
                  {s_fourth.frequency}
                </text>
              </g>
              <text
                transform="translate(799.757 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  電流
                </tspan>
              </text>
              <text
                transform="translate(799.757 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  轉速
                </tspan>
              </text>
              <rect
                width="88.38"
                height="98.737"
                rx="10"
                transform="translate(1044.008 443.379)"
                fill="#ffef7e"
              />
              <text
                transform="translate(1057.986 472.105)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  段 數
                </tspan>
              </text>
              <text
                transform="translate(1074.986 524.39)"
                fill="#1a1311"
                fontSize="48"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="-5" y="0">
                  {s_fourth.step}
                </tspan>
              </text>
              <g transform="translate(-12585.285 -10969.105)">
                <path
                  d="M4738.75,468.95V615.327c0,25.553-26.616,46.267-59.448,46.267h-40.881c-32.833,0-59.447-20.714-59.447-46.267V468.95Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#8e8e8f"
                />
                <path
                  d="M4578.474,468.45H4739.25V615.327a37.866,37.866,0,0,1-4.732,18.248,44.827,44.827,0,0,1-5.543,7.948,52.012,52.012,0,0,1-7.33,6.914,62.853,62.853,0,0,1-19.047,10,74.365,74.365,0,0,1-23.3,3.661h-40.881a74.368,74.368,0,0,1-23.3-3.661,62.852,62.852,0,0,1-19.046-10,52.012,52.012,0,0,1-7.33-6.914,44.849,44.849,0,0,1-5.542-7.948,37.87,37.87,0,0,1-4.732-18.248Zm159.776,1H4579.474V615.327c0,12.2,6.115,23.68,17.218,32.321,11.14,8.671,25.96,13.446,41.729,13.446H4679.3c15.769,0,30.588-4.775,41.729-13.446,11.1-8.642,17.219-20.12,17.219-32.321Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.92,502.868V631.831c0,22.513-22.988,40.764-51.346,40.764h-35.305c-28.356,0-51.344-18.251-51.344-40.764V502.868Z"
                  transform="translate(8855.633 11004.494)"
                  fill="url(#e)"
                />
                <path
                  d="M4611.424,502.368h139V631.831a33.941,33.941,0,0,1-4.094,16.1,42.046,42.046,0,0,1-11.134,13.114,54,54,0,0,1-16.473,8.819,63.149,63.149,0,0,1-20.146,3.229h-35.305a63.146,63.146,0,0,1-20.145-3.229,54.007,54.007,0,0,1-16.472-8.819,42.051,42.051,0,0,1-11.134-13.114,33.941,33.941,0,0,1-4.094-16.1Zm138,1h-137V631.831c0,10.733,5.273,20.83,14.85,28.433,9.61,7.629,22.393,11.831,35.995,11.831h35.305c13.6,0,26.387-4.2,36-11.831,9.576-7.6,14.85-17.7,14.85-28.433Z"
                  transform="translate(8855.633 11004.494)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.23"
                  transform="translate(13456.222 11469.025)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.73H-.5ZM160.473.5H.5V10.73H160.473Z"
                  transform="translate(13456.222 11469.025)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.228"
                  transform="translate(13456.222 11480.251)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.728H-.5ZM160.473.5H.5V10.728H160.473Z"
                  transform="translate(13456.222 11480.251)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.59,328.657c-15.712-19.27-41.13-31.789-69.814-31.789s-54.074,12.5-69.789,31.752Z"
                  transform="translate(8856.936 11140.343)"
                  fill="url(#h)"
                />
                <path
                  d="M4750.643,329.157l-141.709-.038.666-.816a82.5,82.5,0,0,1,13.583-13.163,88.312,88.312,0,0,1,16.625-10.07,92.836,92.836,0,0,1,19.065-6.437,97.515,97.515,0,0,1,41.812,0,92.863,92.863,0,0,1,19.074,6.446,88.341,88.341,0,0,1,16.633,10.081,82.491,82.491,0,0,1,13.586,13.177Zm-139.594-1.037,137.478.036c-16.179-19.3-41.793-30.789-68.751-30.789S4627.228,308.846,4611.049,328.12Z"
                  transform="translate(8856.936 11140.343)"
                  fill="#1a1311"
                />
                <g transform="translate(157.562 -321.995)">
                  <rect
                    width="137.012"
                    height="23"
                    transform="translate(13310.499 11836.465)"
                    fill="#f6cccc"
                  />
                  <text
                    transform="translate(13318.999 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0">
                      槽溫
                    </tspan>
                  </text>
                  <text
                    transform="translate(13425.011 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0" xmlSpace="preserve">
                      {" "}
                      ℃
                    </tspan>
                  </text>
                  <g transform="translate(13425.011 11853.465)">
                    <text textAnchor="middle" x="10" y="-3" fontSize="18">
                      {/* 3槽溫  */}
                    </text>
                  </g>
                </g>
                {/* 紫色的燈 */}
                <rect
                  width="15.087"
                  height="28.917"
                  rx="3"
                  transform="translate(13487.84 11424.699)"
                  fill="#FFF"
                >
                  {s_fourth.lighton !== "W" ? (
                    <animate
                      attributeType="CSS"
                      attributeName="fill"
                      from="#FFF"
                      to={`${
                        s_fourth.lighton === "B"
                          ? "blue"
                          : s_fourth.lighton === "R"
                          ? "red"
                          : s_fourth.lighton === "G"
                          ? "green"
                          : ""
                      }`}
                      begin="0s"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animate>
                  ) : null}
                </rect>
                <path
                  d="M3-.25h9.087A3.254,3.254,0,0,1,15.337,3V25.917a3.254,3.254,0,0,1-3.25,3.25H3a3.254,3.254,0,0,1-3.25-3.25V3A3.254,3.254,0,0,1,3-.25Zm9.087,28.917a2.753,2.753,0,0,0,2.75-2.75V3A2.753,2.753,0,0,0,12.087.25H3A2.753,2.753,0,0,0,.25,3V25.917A2.753,2.753,0,0,0,3,28.667Z"
                  transform="translate(13487.84 11424.699)"
                  fill="#1a1311"
                />
                <path
                  d="M10678.2,6032.09h20.367v5.28H10678.2Z"
                  transform="translate(2807.01 5418.004)"
                  fill={!is_showPurpleLigh ? "#FFF" : "red"}
                >
                  {/*is_showPurpleLigh ? (
                  <animate attributeType="CSS" attributeName="fill"
                    from="#FFF" to="red" begin="0s" dur="1s"
                    repeatCount="indefinite">
                  </animate>
                ) : null*/}
                </path>
                <path
                  d="M10677.95,6031.84h20.867v5.78h-20.867Zm20.367.5h-19.867v4.78h19.867Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#1a1311"
                />

                {/* 反應槽#3 箭頭 */}
                {s_fourth.levelon === 1 ? (
                  <g transform="translate(13587.646 11557.999)">
                    <path
                      d="M14489.334,11598.8v80.393"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(0 11)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                {s_fourth.levelon === 3 ? (
                  <g transform="translate(13588.938 11568.999)">
                    <path
                      d="M14489.334,11679.189V11598.8"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(8 84.392) rotate(180)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                {/* ⬇第1隻螺旋槳 */}
                <g transform="translate(102.342 233.779)">
                  <rect
                    width="2.63"
                    height="155.95"
                    rx="1"
                    transform="translate(13432.338 11273.583)"
                    fill="#7a8397"
                  />
                  {/*柱子 */}
                  <g
                    className={
                      s_fourth.lighton === "B" || s_fourth.lighton === "G"
                        ? "Propeller"
                        : null
                    }
                  >
                    {" "}
                    {/* 螺旋翼 */}
                    <path
                      d="M0,4.855s25.831,6.157,30.177-2.782C32.677-3.07,7.774,2.713,0,4.855Z"
                      transform="matrix(0.995, 0.105, -0.105, 0.995, 13431.098, 11420.582)"
                      fill="#7a8397"
                    />
                    <path
                      d="M4742.2,828.888s-19.826-13.187-27.246-5.5C4710.682,827.814,4734.593,828.888,4742.2,828.888Z"
                      transform="translate(8691.578 10596.722)"
                      fill="#7a8397"
                    />
                  </g>{" "}
                  {/* 螺旋翼 */}
                </g>
                <g opacity="0.5">
                  {/* 反應槽#3 藥水 */}
                  {/* 低水位 */}
                  {s_fourth.levelon === 0 ? (
                    <>
                      <path
                        d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z"
                        transform="translate(13469.933 11648.598)"
                        fill="url(#i)"
                      ></path>
                      <path
                        d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z"
                        transform="translate(13469.933 11648.598)"
                        fill="#1a1311"
                      ></path>
                    </>
                  ) : null}

                  {/* 中水位 */}
                  {s_fourth.levelon === 1 || s_fourth.levelon === 3 ? (
                    <>
                      <path
                        d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                        transform="translate(27713.148 22897.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                        transform="translate(27713.148 22897.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}

                  {/* 高水位 */}
                  {s_fourth.levelon === 2 ? (
                    <>
                      <path
                        d="M-14544.946-11215.783h-35.307a62.668,62.668,0,0,1-19.985-3.2,53.54,53.54,0,0,1-16.32-8.736,41.537,41.537,0,0,1-11-12.959,33.452,33.452,0,0,1-4.034-15.867v-87.824h138v87.824a33.436,33.436,0,0,1-4.035,15.867,41.549,41.549,0,0,1-11,12.959,53.522,53.522,0,0,1-16.321,8.736A62.678,62.678,0,0,1-14544.946-11215.783Z"
                        transform="translate(28099.148 22892.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14544.946-11215.283h-35.307a63.167,63.167,0,0,1-20.145-3.229,54.023,54.023,0,0,1-16.472-8.818,42.027,42.027,0,0,1-11.134-13.115,33.947,33.947,0,0,1-4.093-16.1v-88.324h139v88.324a33.928,33.928,0,0,1-4.094,16.1,42.054,42.054,0,0,1-11.135,13.115,54.009,54.009,0,0,1-16.473,8.818A63.182,63.182,0,0,1-14544.946-11215.283Zm-86.149-128.59v87.324a32.958,32.958,0,0,0,3.976,15.632,41.046,41.046,0,0,0,10.872,12.8,53.045,53.045,0,0,0,16.169,8.654,62.187,62.187,0,0,0,19.826,3.177h35.307a62.2,62.2,0,0,0,19.827-3.177,53.052,53.052,0,0,0,16.17-8.654,41.048,41.048,0,0,0,10.873-12.8,32.936,32.936,0,0,0,3.977-15.632v-87.324Z"
                        transform="translate(28099.148 22892.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}

                  {/* <path
                  d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z"
                  transform="translate(13469.933 11648.598)" fill="url(#i)" />
                <path
                  d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z"
                  transform="translate(13469.933 11648.598)" fill="#1a1311" /> */}
                </g>
                {/* ⬆第1隻螺旋槳 */}
                <text
                  transform="translate(13551.626 11534.969)"
                  fill="#1a1311"
                  fontSize="21"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  {/* <tspan x="0" y="0">{s_fourth.inTemp}</tspan> */}
                </text>
                <g transform="translate(13551.626 11534.969)">
                  <text textAnchor="middle" x="12" y="-2" fontSize="18">
                    {s_fourth.inTemp}
                  </text>
                </g>
              </g>
              <rect
                width="89.894"
                height="24.616"
                transform="translate(906.476 587.643)"
                fill="#fff"
              />
              <text
                transform="translate(906.476 587.643)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="10" y="18">
                  {s_fourth.timer}
                </tspan>
              </text>
              <path
                d="M-.5-.5H90.394V25.116H-.5ZM89.394.5H.5V24.116H89.394Z"
                transform="translate(906.476 587.643)"
                fill="#1a1311"
              />
              <text
                transform="translate(809.082 492.036)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(809.082 553.844)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(809.082 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(1076.508 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(1076.508 709.605)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(809.082 708.871)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">25</tspan> */}
              </text>
              <text
                transform="translate(915.922 606.95)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">00:00:00</tspan> */}
              </text>
            </g>
            <g transform="translate(-370.756 -164.344)">
              <path transform="translate(-924.157 -174.09)" fill="#0092e5" />
              <path transform="translate(-3694.373 -177.187)" fill="#0092e5" />
              <text
                transform="translate(1067.508 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  扭力
                </tspan>
              </text>
              <text
                transform="translate(1066.507 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
              <rect
                width="83.998"
                height="24.615"
                transform="translate(779.08 470.729)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.115H-.5Zm84,1H.5V24.115h83Z"
                transform="translate(779.08 470.729)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 470.729)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_third.flowN}
                </text>
              </g>
              <rect
                width="83.998"
                height="24.616"
                transform="translate(779.08 532.537)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.116H-.5Zm84,1H.5V24.116h83Z"
                transform="translate(779.08 532.537)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 532.537)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_third.flowO}
                </text>
              </g>
              <text
                transform="translate(779.08 466.379)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氮氣流量
                </tspan>
              </text>
              <text
                transform="translate(778.757 528.185)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氧氣流量
                </tspan>
              </text>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(778.757 626.981)"
                fill="#FFF"
              />
              <g transform="translate(778.757 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_third.current}
                </text>
              </g>
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(778.757 626.981)"
                fill="#1a1311"
              />
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1046.347 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1046.347 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(1046.347 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_third.torque}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1045.847 688.298)"
                fill="#FFF"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1045.847 688.298)"
                fill="#1a1311"
              />
              <g transform="translate(1045.847 688.298)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 重量 */}
                  {s_third.weight}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.616"
                transform="translate(778.757 687.563)"
                fill="#FFF"
              />
              <g transform="translate(778.757 687.563)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {s_third.frequency}
                </text>
              </g>
              <path
                d="M-.5-.5H84.821V25.116H-.5ZM83.821.5H.5V24.116H83.821Z"
                transform="translate(778.757 687.563)"
                fill="#1a1311"
              />
              <text
                transform="translate(799.757 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  電流
                </tspan>
              </text>
              <text
                transform="translate(799.757 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  轉速
                </tspan>
              </text>
              <rect
                width="88.38"
                height="98.737"
                rx="10"
                transform="translate(1044.008 443.379)"
                fill="#ffef7e"
              />
              <text
                transform="translate(1057.986 472.105)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  段 數
                </tspan>
              </text>
              <text
                transform="translate(1074.986 524.39)"
                fill="#1a1311"
                fontSize="48"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="-5" y="0">
                  {s_third.step}
                </tspan>
              </text>
              <g transform="translate(-12585.285 -10969.105)">
                <path
                  d="M4738.75,468.95V615.327c0,25.553-26.616,46.267-59.448,46.267h-40.881c-32.833,0-59.447-20.714-59.447-46.267V468.95Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#8e8e8f"
                />
                <path
                  d="M4578.474,468.45H4739.25V615.327a37.866,37.866,0,0,1-4.732,18.248,44.827,44.827,0,0,1-5.543,7.948,52.012,52.012,0,0,1-7.33,6.914,62.853,62.853,0,0,1-19.047,10,74.365,74.365,0,0,1-23.3,3.661h-40.881a74.368,74.368,0,0,1-23.3-3.661,62.852,62.852,0,0,1-19.046-10,52.012,52.012,0,0,1-7.33-6.914,44.849,44.849,0,0,1-5.542-7.948,37.87,37.87,0,0,1-4.732-18.248Zm159.776,1H4579.474V615.327c0,12.2,6.115,23.68,17.218,32.321,11.14,8.671,25.96,13.446,41.729,13.446H4679.3c15.769,0,30.588-4.775,41.729-13.446,11.1-8.642,17.219-20.12,17.219-32.321Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.92,502.868V631.831c0,22.513-22.988,40.764-51.346,40.764h-35.305c-28.356,0-51.344-18.251-51.344-40.764V502.868Z"
                  transform="translate(8855.633 11004.494)"
                  fill="url(#e)"
                />
                <path
                  d="M4611.424,502.368h139V631.831a33.941,33.941,0,0,1-4.094,16.1,42.046,42.046,0,0,1-11.134,13.114,54,54,0,0,1-16.473,8.819,63.149,63.149,0,0,1-20.146,3.229h-35.305a63.146,63.146,0,0,1-20.145-3.229,54.007,54.007,0,0,1-16.472-8.819,42.051,42.051,0,0,1-11.134-13.114,33.941,33.941,0,0,1-4.094-16.1Zm138,1h-137V631.831c0,10.733,5.273,20.83,14.85,28.433,9.61,7.629,22.393,11.831,35.995,11.831h35.305c13.6,0,26.387-4.2,36-11.831,9.576-7.6,14.85-17.7,14.85-28.433Z"
                  transform="translate(8855.633 11004.494)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.23"
                  transform="translate(13456.222 11469.025)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.73H-.5ZM160.473.5H.5V10.73H160.473Z"
                  transform="translate(13456.222 11469.025)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.228"
                  transform="translate(13456.222 11480.251)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.728H-.5ZM160.473.5H.5V10.728H160.473Z"
                  transform="translate(13456.222 11480.251)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.59,328.657c-15.712-19.27-41.13-31.789-69.814-31.789s-54.074,12.5-69.789,31.752Z"
                  transform="translate(8856.936 11140.343)"
                  fill="url(#h)"
                />
                <path
                  d="M4750.643,329.157l-141.709-.038.666-.816a82.5,82.5,0,0,1,13.583-13.163,88.312,88.312,0,0,1,16.625-10.07,92.836,92.836,0,0,1,19.065-6.437,97.515,97.515,0,0,1,41.812,0,92.863,92.863,0,0,1,19.074,6.446,88.341,88.341,0,0,1,16.633,10.081,82.491,82.491,0,0,1,13.586,13.177Zm-139.594-1.037,137.478.036c-16.179-19.3-41.793-30.789-68.751-30.789S4627.228,308.846,4611.049,328.12Z"
                  transform="translate(8856.936 11140.343)"
                  fill="#1a1311"
                />
                <g transform="translate(157.562 -321.995)">
                  <rect
                    width="137.012"
                    height="23"
                    transform="translate(13310.499 11836.465)"
                    fill="#f6cccc"
                  />
                  <text
                    transform="translate(13318.999 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0">
                      槽溫
                    </tspan>
                  </text>
                  <text
                    transform="translate(13425.011 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0" xmlSpace="preserve">
                      {" "}
                      ℃
                    </tspan>
                  </text>
                </g>
                {/* 藍色的燈 */}
                <rect
                  className="light"
                  width="15.087"
                  height="28.917"
                  rx="3"
                  transform="translate(13487.84 11424.699)"
                  fill="#FFF"
                >
                  {s_third.lighton !== "W" ? (
                    <animate
                      attributeType="CSS"
                      attributeName="fill"
                      from="#FFF"
                      to={`${
                        s_third.lighton === "B"
                          ? "blue"
                          : s_third.lighton === "R"
                          ? "red"
                          : s_third.lighton === "G"
                          ? "green"
                          : ""
                      }`}
                      begin="0s"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animate>
                  ) : null}
                </rect>
                <path
                  d="M3-.25h9.087A3.254,3.254,0,0,1,15.337,3V25.917a3.254,3.254,0,0,1-3.25,3.25H3a3.254,3.254,0,0,1-3.25-3.25V3A3.254,3.254,0,0,1,3-.25Zm9.087,28.917a2.753,2.753,0,0,0,2.75-2.75V3A2.753,2.753,0,0,0,12.087.25H3A2.753,2.753,0,0,0,.25,3V25.917A2.753,2.753,0,0,0,3,28.667Z"
                  transform="translate(13487.84 11424.699)"
                  fill="#1a1311"
                />
                <path
                  d="M10678.2,6032.09h20.367v5.28H10678.2Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#FFF"
                >
                  {/*is_showBlueLigh ? (
                  <animate attributeType="CSS" attributeName="fill"
                    from="#FFF" to="blue" begin="0s" dur="1s"
                    repeatCount="indefinite">
                  </animate>
                ) : null*/}
                </path>
                <path
                  d="M10677.95,6031.84h20.867v5.78h-20.867Zm20.367.5h-19.867v4.78h19.867Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#1a1311"
                />
                {/* ⬇第2隻螺旋槳 */}
                <g transform="translate(102.342 233.779)">
                  <rect
                    width="2.63"
                    height="155.95"
                    rx="1"
                    transform="translate(13432.338 11273.583)"
                    fill="#7a8397"
                  />
                  <g
                    className={
                      s_third.lighton === "B" || s_third.lighton === "G"
                        ? "Propeller"
                        : null
                    }
                  >
                    <path
                      d="M0,4.855s25.831,6.157,30.177-2.782C32.677-3.07,7.774,2.713,0,4.855Z"
                      transform="matrix(0.995, 0.105, -0.105, 0.995, 13431.098, 11420.582)"
                      fill="#7a8397"
                    />
                    <path
                      d="M4742.2,828.888s-19.826-13.187-27.246-5.5C4710.682,827.814,4734.593,828.888,4742.2,828.888Z"
                      transform="translate(8691.578 10596.722)"
                      fill="#7a8397"
                    />
                  </g>
                </g>
                {/* ⬆第2隻螺旋槳 */}
                <g opacity="0.5">
                  {/* 第二槽 藥水 */}
                  {/* 低水位 */}
                  {s_third.levelon === 0 ? (
                    <>
                      <path
                        d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z"
                        transform="translate(13469.933 11648.598)"
                        fill="url(#i)"
                      ></path>
                      <path
                        d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z"
                        transform="translate(13469.933 11648.598)"
                        fill="#1a1311"
                      ></path>
                    </>
                  ) : null}

                  {/* 中水位 */}
                  {s_third.levelon === 1 || s_third.levelon === 3 ? (
                    <>
                      <path
                        d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                        transform="translate(27713.148 22897.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                        transform="translate(27713.148 22897.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}

                  {/* 高水位 */}
                  {s_third.levelon === 2 ? (
                    <>
                      <path
                        d="M-14544.946-11215.783h-35.307a62.668,62.668,0,0,1-19.985-3.2,53.54,53.54,0,0,1-16.32-8.736,41.537,41.537,0,0,1-11-12.959,33.452,33.452,0,0,1-4.034-15.867v-87.824h138v87.824a33.436,33.436,0,0,1-4.035,15.867,41.549,41.549,0,0,1-11,12.959,53.522,53.522,0,0,1-16.321,8.736A62.678,62.678,0,0,1-14544.946-11215.783Z"
                        transform="translate(28099.148 22892.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14544.946-11215.283h-35.307a63.167,63.167,0,0,1-20.145-3.229,54.023,54.023,0,0,1-16.472-8.818,42.027,42.027,0,0,1-11.134-13.115,33.947,33.947,0,0,1-4.093-16.1v-88.324h139v88.324a33.928,33.928,0,0,1-4.094,16.1,42.054,42.054,0,0,1-11.135,13.115,54.009,54.009,0,0,1-16.473,8.818A63.182,63.182,0,0,1-14544.946-11215.283Zm-86.149-128.59v87.324a32.958,32.958,0,0,0,3.976,15.632,41.046,41.046,0,0,0,10.872,12.8,53.045,53.045,0,0,0,16.169,8.654,62.187,62.187,0,0,0,19.826,3.177h35.307a62.2,62.2,0,0,0,19.827-3.177,53.052,53.052,0,0,0,16.17-8.654,41.048,41.048,0,0,0,10.873-12.8,32.936,32.936,0,0,0,3.977-15.632v-87.324Z"
                        transform="translate(28099.148 22892.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}
                  {/* <path
                  d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                  transform="translate(27711.855 22897.871)" fill="url(#i)" />
                <path
                  d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                  transform="translate(27711.855 22897.871)" fill="#1a1311" /> */}
                </g>
                {/* 第二槽 藥水 */}
                {s_third.levelon === 1 ? (
                  <g transform="translate(13587.646 11557.999)">
                    <path
                      d="M14489.334,11598.8v80.393"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(0 11)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                {s_third.levelon === 3 ? (
                  <g transform="translate(13588.938 11568.999)">
                    <path
                      d="M14489.334,11679.189V11598.8"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(8 84.392) rotate(180)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                <text
                  transform="translate(13551.627 11534.969)"
                  fill="#1a1311"
                  fontSize="21"
                  fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                >
                  {/* <tspan x="0" y="0">{s_third.inTemp}</tspan> */}
                </text>
                <g transform="translate(13551.627 11534.969)">
                  <text textAnchor="middle" x="12" y="-2" fontSize="18">
                    {s_third.inTemp}
                  </text>
                </g>
              </g>
              <rect
                width="89.894"
                height="24.616"
                transform="translate(906.476 587.643)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H90.394V25.116H-.5ZM89.394.5H.5V24.116H89.394Z"
                transform="translate(906.476 587.643)"
                fill="#1a1311"
              />
              <text
                transform="translate(809.08 492.035)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* 註解 <tspan x="0" y="0">{s_third.flowN}</tspan> */}
              </text>
              <text
                transform="translate(808.756 553.843)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* 註解 <tspan x="0" y="0">{s_third.flowO}</tspan> */}
              </text>
              <text
                transform="translate(808.756 648.289)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_third.current}</tspan> */}
              </text>
              <text
                transform="translate(808.756 708.87)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_third.frequency}</tspan> */}
              </text>

              {/* <text transform="translate(1075.506 708.87)" fill="#1a1311" fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
              <tspan x="0" y="0">{s_third.weight}</tspan>
            </text> */}
              {/*註解 <g transform="translate(1075.506 708.87)">
              <text  fill="#1a1311" fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
                <tspan x="0%" y="0">{s_third.weight}</tspan>
              </text>
            </g> */}

              <text
                transform="translate(1075.506 648.289)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* 註解 <tspan x="0" y="0">{s_third.torque}</tspan> */}
              </text>
              <text
                transform="translate(915.922 606.949)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_third.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(4.243 -164.344)">
              <path transform="translate(-924.157 -174.09)" fill="#0092e5" />
              <path transform="translate(-3694.373 -177.187)" fill="#0092e5" />
              <text
                transform="translate(1067.508 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  扭力
                </tspan>
              </text>
              <text
                transform="translate(1066.507 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
              <rect
                width="83.998"
                height="24.615"
                transform="translate(779.08 470.729)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.115H-.5Zm84,1H.5V24.115h83Z"
                transform="translate(779.08 470.729)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 470.729)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.flowN}
                </text>
              </g>
              <rect
                width="83.998"
                height="24.616"
                transform="translate(779.08 532.537)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.116H-.5Zm84,1H.5V24.116h83Z"
                transform="translate(779.08 532.537)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 532.537)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.flowO}
                </text>
              </g>
              <text
                transform="translate(779.08 466.379)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氮氣流量
                </tspan>
              </text>
              <text
                transform="translate(778.757 528.185)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氧氣流量
                </tspan>
              </text>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(778.757 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(778.757 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.current}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1046.347 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1046.347 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(1046.347 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.torque}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1045.847 688.298)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1045.847 688.298)"
                fill="#1a1311"
              />
              <g transform="translate(1045.847 688.298)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.weight}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.616"
                transform="translate(778.757 687.563)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.116H-.5ZM83.821.5H.5V24.116H83.821Z"
                transform="translate(778.757 687.563)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 687.563)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_second.frequency}
                </text>
              </g>
              <text
                transform="translate(799.757 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  電流
                </tspan>
              </text>
              <text
                transform="translate(799.757 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  轉速
                </tspan>
              </text>
              <rect
                width="88.38"
                height="98.737"
                rx="10"
                transform="translate(1044.008 443.379)"
                fill="#ffef7e"
              />
              <text
                transform="translate(1057.986 472.105)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  段 數
                </tspan>
              </text>
              <text
                transform="translate(1074.986 524.39)"
                fill="#1a1311"
                fontSize="48"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="-5" y="0">
                  {s_second.step}
                </tspan>
              </text>
              <g transform="translate(-12585.285 -10969.105)">
                <path
                  d="M4738.75,468.95V615.327c0,25.553-26.616,46.267-59.448,46.267h-40.881c-32.833,0-59.447-20.714-59.447-46.267V468.95Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#8e8e8f"
                />
                <path
                  d="M4578.474,468.45H4739.25V615.327a37.866,37.866,0,0,1-4.732,18.248,44.827,44.827,0,0,1-5.543,7.948,52.012,52.012,0,0,1-7.33,6.914,62.853,62.853,0,0,1-19.047,10,74.365,74.365,0,0,1-23.3,3.661h-40.881a74.368,74.368,0,0,1-23.3-3.661,62.852,62.852,0,0,1-19.046-10,52.012,52.012,0,0,1-7.33-6.914,44.849,44.849,0,0,1-5.542-7.948,37.87,37.87,0,0,1-4.732-18.248Zm159.776,1H4579.474V615.327c0,12.2,6.115,23.68,17.218,32.321,11.14,8.671,25.96,13.446,41.729,13.446H4679.3c15.769,0,30.588-4.775,41.729-13.446,11.1-8.642,17.219-20.12,17.219-32.321Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.92,502.868V631.831c0,22.513-22.988,40.764-51.346,40.764h-35.305c-28.356,0-51.344-18.251-51.344-40.764V502.868Z"
                  transform="translate(8855.633 11004.494)"
                  fill="url(#e)"
                />
                <path
                  d="M4611.424,502.368h139V631.831a33.941,33.941,0,0,1-4.094,16.1,42.046,42.046,0,0,1-11.134,13.114,54,54,0,0,1-16.473,8.819,63.149,63.149,0,0,1-20.146,3.229h-35.305a63.146,63.146,0,0,1-20.145-3.229,54.007,54.007,0,0,1-16.472-8.819,42.051,42.051,0,0,1-11.134-13.114,33.941,33.941,0,0,1-4.094-16.1Zm138,1h-137V631.831c0,10.733,5.273,20.83,14.85,28.433,9.61,7.629,22.393,11.831,35.995,11.831h35.305c13.6,0,26.387-4.2,36-11.831,9.576-7.6,14.85-17.7,14.85-28.433Z"
                  transform="translate(8855.633 11004.494)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.23"
                  transform="translate(13456.222 11469.025)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.73H-.5ZM160.473.5H.5V10.73H160.473Z"
                  transform="translate(13456.222 11469.025)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.228"
                  transform="translate(13456.222 11480.251)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.728H-.5ZM160.473.5H.5V10.728H160.473Z"
                  transform="translate(13456.222 11480.251)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.59,328.657c-15.712-19.27-41.13-31.789-69.814-31.789s-54.074,12.5-69.789,31.752Z"
                  transform="translate(8856.936 11140.343)"
                  fill="url(#h)"
                />
                <path
                  d="M4750.643,329.157l-141.709-.038.666-.816a82.5,82.5,0,0,1,13.583-13.163,88.312,88.312,0,0,1,16.625-10.07,92.836,92.836,0,0,1,19.065-6.437,97.515,97.515,0,0,1,41.812,0,92.863,92.863,0,0,1,19.074,6.446,88.341,88.341,0,0,1,16.633,10.081,82.491,82.491,0,0,1,13.586,13.177Zm-139.594-1.037,137.478.036c-16.179-19.3-41.793-30.789-68.751-30.789S4627.228,308.846,4611.049,328.12Z"
                  transform="translate(8856.936 11140.343)"
                  fill="#1a1311"
                />
                <g transform="translate(157.562 -321.995)">
                  <rect
                    width="137.012"
                    height="23"
                    transform="translate(13310.499 11836.465)"
                    fill="#f6cccc"
                  />
                  <text
                    transform="translate(13318.999 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0">
                      槽溫
                    </tspan>
                  </text>
                  <text
                    transform="translate(13425.011 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0" xmlSpace="preserve">
                      {" "}
                      ℃
                    </tspan>
                  </text>
                  <text
                    transform="translate(13394.066 11856.965)"
                    fill="#1a1311"
                    fontSize="18"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    {/* <tspan x="5" y="0">{s_second.inTemp}</tspan>  */}
                  </text>
                  <g transform="translate(13394.066 11856.965)">
                    <text textAnchor="middle" x="12" y="-2" fontSize="18">
                      {s_second.inTemp}
                    </text>
                  </g>
                </g>
                {/* 淺藍的燈 */}
                <rect
                  width="15.087"
                  height="28.917"
                  rx="3"
                  transform="translate(13487.84 11424.699)"
                  fill="#FFF"
                >
                  {s_second.lighton !== "W" ? (
                    <animate
                      attributeType="CSS"
                      attributeName="fill"
                      from="#FFF"
                      to={`${
                        s_second.lighton === "B"
                          ? "blue"
                          : s_second.lighton === "R"
                          ? "red"
                          : s_second.lighton === "G"
                          ? "green"
                          : ""
                      }`}
                      begin="0s"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animate>
                  ) : null}
                </rect>
                <path
                  d="M3-.25h9.087A3.254,3.254,0,0,1,15.337,3V25.917a3.254,3.254,0,0,1-3.25,3.25H3a3.254,3.254,0,0,1-3.25-3.25V3A3.254,3.254,0,0,1,3-.25Zm9.087,28.917a2.753,2.753,0,0,0,2.75-2.75V3A2.753,2.753,0,0,0,12.087.25H3A2.753,2.753,0,0,0,.25,3V25.917A2.753,2.753,0,0,0,3,28.667Z"
                  transform="translate(13487.84 11424.699)"
                  fill="#1a1311"
                />
                <path
                  d="M10678.2,6032.09h20.367v5.28H10678.2Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#FFF"
                />
                <path
                  d="M10677.95,6031.84h20.867v5.78h-20.867Zm20.367.5h-19.867v4.78h19.867Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#1a1311"
                />
                {/* ⬇第3隻螺旋槳 */}
                <g transform="translate(102.342 233.779)">
                  <rect
                    width="2.63"
                    height="155.95"
                    rx="1"
                    transform="translate(13432.338 11273.583)"
                    fill="#7a8397"
                  />
                  <g
                    className={
                      s_second.lighton === "B" || s_second.lighton === "G"
                        ? "Propeller"
                        : null
                    }
                  >
                    <path
                      d="M0,4.855s25.831,6.157,30.177-2.782C32.677-3.07,7.774,2.713,0,4.855Z"
                      transform="matrix(0.995, 0.105, -0.105, 0.995, 13431.098, 11420.582)"
                      fill="#7a8397"
                    />
                    <path
                      d="M4742.2,828.888s-19.826-13.187-27.246-5.5C4710.682,827.814,4734.593,828.888,4742.2,828.888Z"
                      transform="translate(8691.578 10596.722)"
                      fill="#7a8397"
                    />
                  </g>
                </g>
                {/* ⬆第3隻螺旋槳 */}
                {/* 2A向上 */}
                {s_second.levelon === 1 ? (
                  <g transform="translate(13587.646 11557.999)">
                    <path
                      d="M14489.334,11598.8v80.393"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(0 11)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                {s_second.levelon === 3 ? (
                  <g transform="translate(13588.938 11568.999)">
                    {/* 2A向下 */}
                    <path
                      d="M14489.334,11679.189V11598.8"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(8 84.392) rotate(180)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                <g opacity="0.5">
                  {/* 第3槽 藥水 */}
                  {/* 低水位 */}
                  {s_second.levelon === 0 ? (
                    <>
                      <path
                        d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z"
                        transform="translate(13469.933 11648.598)"
                        fill="url(#i)"
                      ></path>
                      <path
                        d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z"
                        transform="translate(13469.933 11648.598)"
                        fill="#1a1311"
                      ></path>
                    </>
                  ) : null}

                  {/* 中水位 */}
                  {s_second.levelon === 1 || s_second.levelon === 3 ? (
                    <>
                      <path
                        d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                        transform="translate(27713.148 22897.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                        transform="translate(27713.148 22897.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}

                  {/* 高水位 */}
                  {s_second.levelon === 2 ? (
                    <>
                      <path
                        d="M-14544.946-11215.783h-35.307a62.668,62.668,0,0,1-19.985-3.2,53.54,53.54,0,0,1-16.32-8.736,41.537,41.537,0,0,1-11-12.959,33.452,33.452,0,0,1-4.034-15.867v-87.824h138v87.824a33.436,33.436,0,0,1-4.035,15.867,41.549,41.549,0,0,1-11,12.959,53.522,53.522,0,0,1-16.321,8.736A62.678,62.678,0,0,1-14544.946-11215.783Z"
                        transform="translate(28099.148 22892.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14544.946-11215.283h-35.307a63.167,63.167,0,0,1-20.145-3.229,54.023,54.023,0,0,1-16.472-8.818,42.027,42.027,0,0,1-11.134-13.115,33.947,33.947,0,0,1-4.093-16.1v-88.324h139v88.324a33.928,33.928,0,0,1-4.094,16.1,42.054,42.054,0,0,1-11.135,13.115,54.009,54.009,0,0,1-16.473,8.818A63.182,63.182,0,0,1-14544.946-11215.283Zm-86.149-128.59v87.324a32.958,32.958,0,0,0,3.976,15.632,41.046,41.046,0,0,0,10.872,12.8,53.045,53.045,0,0,0,16.169,8.654,62.187,62.187,0,0,0,19.826,3.177h35.307a62.2,62.2,0,0,0,19.827-3.177,53.052,53.052,0,0,0,16.17-8.654,41.048,41.048,0,0,0,10.873-12.8,32.936,32.936,0,0,0,3.977-15.632v-87.324Z"
                        transform="translate(28099.148 22892.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}
                </g>
                {/* 第3槽 藥水 */}
              </g>
              <rect
                width="89.894"
                height="24.616"
                transform="translate(906.476 587.643)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H90.394V25.116H-.5ZM89.394.5H.5V24.116H89.394Z"
                transform="translate(906.476 587.643)"
                fill="#1a1311"
              />
              <text
                transform="translate(809.082 492.036)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* 註解<tspan x="0" y="0">{s_second.flowN}</tspan> */}
              </text>

              <text
                transform="translate(809.082 553.844)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_second.flowO}</tspan> */}
              </text>
              <text
                transform="translate(809.082 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_second.current}</tspan> */}
              </text>
              <text
                transform="translate(1076.197 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_second.torque}</tspan> */}
              </text>
              <text
                transform="translate(1076.197 709.605)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_second.weight}</tspan> */}
              </text>
              <text
                transform="translate(809.082 708.871)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/*註解 <tspan x="0" y="0">{s_second.frequency}</tspan> */}
              </text>
              <text
                transform="translate(915.922 606.949)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_second.timer}
                </tspan>
              </text>
            </g>
            <g transform="translate(378.243 -164.344)">
              <path transform="translate(-924.157 -174.09)" fill="#0092e5" />
              <path transform="translate(-3694.373 -177.187)" fill="#0092e5" />
              <text
                transform="translate(1067.508 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  扭力
                </tspan>
              </text>
              <text
                transform="translate(1066.507 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
              <rect
                width="83.998"
                height="24.615"
                transform="translate(779.08 470.729)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.115H-.5Zm84,1H.5V24.115h83Z"
                transform="translate(779.08 470.729)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 470.729)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.flowN}
                </text>
              </g>
              <rect
                width="83.998"
                height="24.616"
                transform="translate(779.08 532.537)"
                fill="#fff"
              />
              <path
                d="M-.5-.5h85V25.116H-.5Zm84,1H.5V24.116h83Z"
                transform="translate(779.08 532.537)"
                fill="#1a1311"
              />
              <g transform="translate(779.08 532.537)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.flowO}
                </text>
              </g>
              <text
                transform="translate(779.08 466.379)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氮氣流量
                </tspan>
              </text>
              <text
                transform="translate(778.757 528.185)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  氧氣流量
                </tspan>
              </text>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(778.757 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(778.757 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.current}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1046.347 626.981)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1046.347 626.981)"
                fill="#1a1311"
              />
              <g transform="translate(1046.347 626.981)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.torque}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(1045.847 688.298)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(1045.847 688.298)"
                fill="#1a1311"
              />
              <g transform="translate(1045.847 688.298)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.weight}
                </text>
              </g>
              <rect
                width="84.321"
                height="24.616"
                transform="translate(778.757 687.563)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.116H-.5ZM83.821.5H.5V24.116H83.821Z"
                transform="translate(778.757 687.563)"
                fill="#1a1311"
              />
              <g transform="translate(778.757 687.563)">
                <text textAnchor="middle" x="40" y="20" fontSize="21">
                  {/* 2B批號 扭力 */}
                  {s_first.frequency}
                </text>
              </g>
              <text
                transform="translate(799.757 622.717)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  電流
                </tspan>
              </text>
              <text
                transform="translate(799.757 683.298)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  轉速
                </tspan>
              </text>
              <rect
                width="88.38"
                height="98.737"
                rx="10"
                transform="translate(1044.008 443.379)"
                fill="#ffef7e"
              />
              <text
                transform="translate(1057.986 472.105)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0" xmlSpace="preserve">
                  段 數
                </tspan>
              </text>
              <text
                transform="translate(1074.986 524.39)"
                fill="#1a1311"
                fontSize="48"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="-12" y="0">
                  {s_first.step}
                </tspan>
              </text>
              <g transform="translate(-12585.285 -10969.105)">
                <path
                  d="M4738.75,468.95V615.327c0,25.553-26.616,46.267-59.448,46.267h-40.881c-32.833,0-59.447-20.714-59.447-46.267V468.95Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#8e8e8f"
                />
                <path
                  d="M4578.474,468.45H4739.25V615.327a37.866,37.866,0,0,1-4.732,18.248,44.827,44.827,0,0,1-5.543,7.948,52.012,52.012,0,0,1-7.33,6.914,62.853,62.853,0,0,1-19.047,10,74.365,74.365,0,0,1-23.3,3.661h-40.881a74.368,74.368,0,0,1-23.3-3.661,62.852,62.852,0,0,1-19.046-10,52.012,52.012,0,0,1-7.33-6.914,44.849,44.849,0,0,1-5.542-7.948,37.87,37.87,0,0,1-4.732-18.248Zm159.776,1H4579.474V615.327c0,12.2,6.115,23.68,17.218,32.321,11.14,8.671,25.96,13.446,41.729,13.446H4679.3c15.769,0,30.588-4.775,41.729-13.446,11.1-8.642,17.219-20.12,17.219-32.321Z"
                  transform="translate(8877.688 11027.203)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.92,502.868V631.831c0,22.513-22.988,40.764-51.346,40.764h-35.305c-28.356,0-51.344-18.251-51.344-40.764V502.868Z"
                  transform="translate(8855.633 11004.494)"
                  fill="url(#e)"
                />
                <path
                  d="M4611.424,502.368h139V631.831a33.941,33.941,0,0,1-4.094,16.1,42.046,42.046,0,0,1-11.134,13.114,54,54,0,0,1-16.473,8.819,63.149,63.149,0,0,1-20.146,3.229h-35.305a63.146,63.146,0,0,1-20.145-3.229,54.007,54.007,0,0,1-16.472-8.819,42.051,42.051,0,0,1-11.134-13.114,33.941,33.941,0,0,1-4.094-16.1Zm138,1h-137V631.831c0,10.733,5.273,20.83,14.85,28.433,9.61,7.629,22.393,11.831,35.995,11.831h35.305c13.6,0,26.387-4.2,36-11.831,9.576-7.6,14.85-17.7,14.85-28.433Z"
                  transform="translate(8855.633 11004.494)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.23"
                  transform="translate(13456.222 11469.025)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.73H-.5ZM160.473.5H.5V10.73H160.473Z"
                  transform="translate(13456.222 11469.025)"
                  fill="#1a1311"
                />
                <rect
                  width="160.973"
                  height="11.228"
                  transform="translate(13456.222 11480.251)"
                  fill="url(#f)"
                />
                <path
                  d="M-.5-.5H161.473V11.728H-.5ZM160.473.5H.5V10.728H160.473Z"
                  transform="translate(13456.222 11480.251)"
                  fill="#1a1311"
                />
                <path
                  d="M4749.59,328.657c-15.712-19.27-41.13-31.789-69.814-31.789s-54.074,12.5-69.789,31.752Z"
                  transform="translate(8856.936 11140.343)"
                  fill="url(#h)"
                />
                <path
                  d="M4750.643,329.157l-141.709-.038.666-.816a82.5,82.5,0,0,1,13.583-13.163,88.312,88.312,0,0,1,16.625-10.07,92.836,92.836,0,0,1,19.065-6.437,97.515,97.515,0,0,1,41.812,0,92.863,92.863,0,0,1,19.074,6.446,88.341,88.341,0,0,1,16.633,10.081,82.491,82.491,0,0,1,13.586,13.177Zm-139.594-1.037,137.478.036c-16.179-19.3-41.793-30.789-68.751-30.789S4627.228,308.846,4611.049,328.12Z"
                  transform="translate(8856.936 11140.343)"
                  fill="#1a1311"
                />
                <g transform="translate(157.562 -321.995)">
                  <rect
                    width="137.012"
                    height="23"
                    transform="translate(13310.499 11836.465)"
                    fill="#f6cccc"
                  />
                  <text
                    transform="translate(13318.999 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0">
                      槽溫
                    </tspan>
                  </text>
                  <text
                    transform="translate(13425.011 11853.465)"
                    fill="#1a1311"
                    fontSize="14"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    <tspan x="0" y="0" xmlSpace="preserve">
                      {" "}
                      ℃
                    </tspan>
                  </text>
                  <text
                    transform="translate(13394.066 11856.965)"
                    fill="#1a1311"
                    fontSize="21"
                    fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                  >
                    {/* <tspan x="0" y="0">{s_first.inTemp}</tspan> */}
                  </text>
                  <g transform="translate(13394.066 11856.965)">
                    <text textAnchor="middle" x="12" y="-2" fontSize="18">
                      {s_first.inTemp}
                    </text>
                  </g>
                </g>

                {/* 綠色的燈 */}
                <rect
                  width="15.087"
                  height="28.917"
                  rx="3"
                  transform="translate(13487.84 11424.699)"
                  fill="#FFF"
                >
                  {s_first.lighton !== "W" ? (
                    <animate
                      attributeType="CSS"
                      attributeName="fill"
                      from="#FFF"
                      to={`${
                        s_first.lighton === "B"
                          ? "blue"
                          : s_first.lighton === "R"
                          ? "red"
                          : s_first.lighton === "G"
                          ? "green"
                          : ""
                      }`}
                      begin="0s"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animate>
                  ) : null}
                </rect>
                <path
                  d="M3-.25h9.087A3.254,3.254,0,0,1,15.337,3V25.917a3.254,3.254,0,0,1-3.25,3.25H3a3.254,3.254,0,0,1-3.25-3.25V3A3.254,3.254,0,0,1,3-.25Zm9.087,28.917a2.753,2.753,0,0,0,2.75-2.75V3A2.753,2.753,0,0,0,12.087.25H3A2.753,2.753,0,0,0,.25,3V25.917A2.753,2.753,0,0,0,3,28.667Z"
                  transform="translate(13487.84 11424.699)"
                  fill="#1a1311"
                />
                <path
                  d="M10678.2,6032.09h20.367v5.28H10678.2Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#FFF"
                />
                <path
                  d="M10677.95,6031.84h20.867v5.78h-20.867Zm20.367.5h-19.867v4.78h19.867Z"
                  transform="translate(2807.01 5418.004)"
                  fill="#1a1311"
                />
                {/* ⬇第4隻螺旋槳 */}
                <g transform="translate(102.342 233.779)">
                  <rect
                    width="2.63"
                    height="155.95"
                    rx="1"
                    transform="translate(13432.338 11273.583)"
                    fill="#7a8397"
                  />
                  <g
                    className={
                      s_first.lighton === "B" || s_first.lighton === "G"
                        ? "Propeller"
                        : null
                    }
                  >
                    <path
                      d="M0,4.855s25.831,6.157,30.177-2.782C32.677-3.07,7.774,2.713,0,4.855Z"
                      transform="matrix(0.995, 0.105, -0.105, 0.995, 13431.098, 11420.582)"
                      fill="#7a8397"
                    />
                    <path
                      d="M4742.2,828.888s-19.826-13.187-27.246-5.5C4710.682,827.814,4734.593,828.888,4742.2,828.888Z"
                      transform="translate(8691.578 10596.722)"
                      fill="#7a8397"
                    />
                  </g>
                </g>
                {/* ⬆第4隻螺旋槳 */}
                <g opacity="0.5">
                  {/* 第4槽 藥水 */}
                  {/* 低水位 */}
                  {s_first.levelon === 0 ? (
                    <>
                      <path
                        d="M84.391,28.49H48.839a62.709,62.709,0,0,1-12.465-1.242,58.777,58.777,0,0,1-11.367-3.528A53.656,53.656,0,0,1,15.1,18.205,47.4,47.4,0,0,1,7.017,11h119.2a47.393,47.393,0,0,1-8.083,7.205,53.656,53.656,0,0,1-9.907,5.515,58.783,58.783,0,0,1-11.368,3.528A62.722,62.722,0,0,1,84.391,28.49Z"
                        transform="translate(13469.933 11648.598)"
                        fill="url(#i)"
                      ></path>
                      <path
                        d="M84.391,28.99H48.839a63.211,63.211,0,0,1-12.565-1.252A59.278,59.278,0,0,1,24.81,24.18a54.156,54.156,0,0,1-10-5.567,47.9,47.9,0,0,1-8.168-7.282L5.906,10.5H127.328l-.738.832a47.894,47.894,0,0,1-8.169,7.282,54.156,54.156,0,0,1-10,5.567,59.284,59.284,0,0,1-11.465,3.558A63.223,63.223,0,0,1,84.391,28.99ZM8.148,11.5a47.253,47.253,0,0,0,7.241,6.3A53.156,53.156,0,0,0,25.2,23.261a58.275,58.275,0,0,0,11.27,3.5A62.207,62.207,0,0,0,48.839,27.99H84.391a62.221,62.221,0,0,0,12.367-1.232,58.283,58.283,0,0,0,11.271-3.5,53.154,53.154,0,0,0,9.815-5.464,47.252,47.252,0,0,0,7.241-6.3Z"
                        transform="translate(13469.933 11648.598)"
                        fill="#1a1311"
                      ></path>
                    </>
                  ) : null}
                  {/* 中水位 */}
                  {s_first.levelon === 1 || s_first.levelon === 3 ? (
                    <>
                      <path
                        d="M-14158.948-11220.784h-35.3a62.644,62.644,0,0,1-19.937-3.187,53.556,53.556,0,0,1-16.3-8.694,41.632,41.632,0,0,1-11.016-12.9,33.474,33.474,0,0,1-4.1-15.8h137.992a33.479,33.479,0,0,1-4.1,15.8,41.627,41.627,0,0,1-11.015,12.9,53.554,53.554,0,0,1-16.3,8.694A62.652,62.652,0,0,1-14158.948-11220.784Z"
                        transform="translate(27713.148 22897.871)"
                        fill="url(#i)"
                      />
                      <path
                        d="M-14158.948-11220.284h-35.3a63.13,63.13,0,0,1-20.1-3.214,54.063,54.063,0,0,1-16.447-8.775,42.143,42.143,0,0,1-11.146-13.058,33.961,33.961,0,0,1-4.155-16.038l0-.5h139l0,.5a33.967,33.967,0,0,1-4.155,16.038,42.126,42.126,0,0,1-11.145,13.058,54.047,54.047,0,0,1-16.447,8.775A63.133,63.133,0,0,1-14158.948-11220.284Zm-86.141-40.588a33.01,33.01,0,0,0,4.029,15.068,41.128,41.128,0,0,0,10.886,12.745,53.055,53.055,0,0,0,16.145,8.613,62.15,62.15,0,0,0,19.777,3.161h35.3a62.153,62.153,0,0,0,19.779-3.161,53.06,53.06,0,0,0,16.145-8.613,41.127,41.127,0,0,0,10.885-12.745,33.013,33.013,0,0,0,4.029-15.068Z"
                        transform="translate(27713.148 22897.871)"
                        fill="#1a1311"
                      />
                    </>
                  ) : null}
                  {/* 高水位 */}
                  {s_first.levelon === 2 ? (
                    <>
                      <path
                        d="M-14544.946-11215.783h-35.307a62.668,62.668,0,0,1-19.985-3.2,53.54,53.54,0,0,1-16.32-8.736,41.537,41.537,0,0,1-11-12.959,33.452,33.452,0,0,1-4.034-15.867v-87.824h138v87.824a33.436,33.436,0,0,1-4.035,15.867,41.549,41.549,0,0,1-11,12.959,53.522,53.522,0,0,1-16.321,8.736A62.678,62.678,0,0,1-14544.946-11215.783Z"
                        transform="translate(28099.148 22892.871)"
                        fill="url(#i)"
                      ></path>
                      <path
                        d="M-14544.946-11215.283h-35.307a63.167,63.167,0,0,1-20.145-3.229,54.023,54.023,0,0,1-16.472-8.818,42.027,42.027,0,0,1-11.134-13.115,33.947,33.947,0,0,1-4.093-16.1v-88.324h139v88.324a33.928,33.928,0,0,1-4.094,16.1,42.054,42.054,0,0,1-11.135,13.115,54.009,54.009,0,0,1-16.473,8.818A63.182,63.182,0,0,1-14544.946-11215.283Zm-86.149-128.59v87.324a32.958,32.958,0,0,0,3.976,15.632,41.046,41.046,0,0,0,10.872,12.8,53.045,53.045,0,0,0,16.169,8.654,62.187,62.187,0,0,0,19.826,3.177h35.307a62.2,62.2,0,0,0,19.827-3.177,53.052,53.052,0,0,0,16.17-8.654,41.048,41.048,0,0,0,10.873-12.8,32.936,32.936,0,0,0,3.977-15.632v-87.324Z"
                        transform="translate(28099.148 22892.871)"
                        fill="#1a1311"
                      ></path>
                    </>
                  ) : null}
                </g>
                {/* 第4槽 藥水 */}
                {s_first.levelon === 1 ? (
                  <g transform="translate(13587.646 11557.999)">
                    <path
                      d="M14489.334,11598.8v80.393"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11583.798)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(0 11)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
                {s_first.levelon === 3 ? (
                  <g transform="translate(13588.938 11568.999)">
                    <path
                      d="M14489.334,11679.189V11598.8"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M14490.834,11679.189h-3V11598.8h3Z"
                      transform="translate(-14485.334 -11598.797)"
                      fill="#ffef7e"
                    />
                    <path
                      d="M4,0,8,5H0Z"
                      transform="translate(8 84.392) rotate(180)"
                      fill="#ffef7e"
                    />
                  </g>
                ) : null}
              </g>
              <rect
                width="89.894"
                height="24.616"
                transform="translate(906.476 587.643)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H90.394V25.116H-.5ZM89.394.5H.5V24.116H89.394Z"
                transform="translate(906.476 587.643)"
                fill="#1a1311"
              />
              <text
                transform="translate(906.476 587.643)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="10" y="18">
                  {s_first.timer}
                </tspan>
              </text>
              <text
                transform="translate(809.08 492.035)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.flowN}</tspan> */}
              </text>
              <text
                transform="translate(809.08 553.344)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.flowO}</tspan> */}
              </text>
              <text
                transform="translate(809.08 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.current}</tspan> */}
              </text>
              <text
                transform="translate(809.08 708.871)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.frequency}</tspan> */}
              </text>
              <text
                transform="translate(1076.508 708.871)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.weight}</tspan> */}
              </text>
              <text
                transform="translate(1076.508 648.288)"
                fill="#1a1311"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.torque}</tspan> */}
              </text>
              <text
                transform="translate(915.924 606.949)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                {/* <tspan x="0" y="0">{s_first.timer}</tspan> */}
              </text>
            </g>
            <g transform="translate(-6.205 -213.657)">
              <rect
                width="160.973"
                height="91.871"
                rx="10"
                transform="translate(1259.205 401.291)"
                fill="url(#y)"
              />
              <path
                d="M150.973,45.753H10a10.012,10.012,0,0,1-10-10V0H160.973V35.753A10.012,10.012,0,0,1,150.973,45.753Z"
                transform="translate(1259.205 447.227)"
                fill="url(#y)"
              />
              <text
                transform="translate(1293.191 432.436)"
                fill="#fff"
                fontSize="22"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  反應槽#1
                </tspan>
              </text>
              <text
                transform="translate(1287.098 479.227)"
                fill="#fff"
                fontSize="21"
                className="pointer"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                onClick={() => openModal(testData.wg[0], "first")}
              >
                <tspan x="0" y="0">
                  查看詳情
                </tspan>
              </text>
              <g transform="translate(1379.777 461.227)">
                <path
                  d="M367.8-868.378c-.882-.864-1.746-1.747-2.624-2.616a1.926,1.926,0,0,0-1.408-.605c-2.267.007-4.535,0-6.8.007a1.612,1.612,0,0,0-1.677,1.635q-.019,7.106,0,14.212a1.6,1.6,0,0,0,1.609,1.639q4.889.024,9.778,0a1.622,1.622,0,0,0,1.57-1.024,4.279,4.279,0,0,0-1.585-.08c-3.2.035-6.4.015-9.6.021-.49,0-.692-.221-.692-.7q.007-6.954,0-13.909c0-.479.195-.708.688-.706,1.8.008,3.6.012,5.405,0,.381,0,.485.132.478.494-.019.971,0,1.943-.008,2.915,0,.55.263.938.8.954,1.436.042,2.875.015,4.312.018.206,0,.344-.042.36-.294A2.322,2.322,0,0,0,367.8-868.378Zm-.7,1.028c-.127.325-.689.1-1.058.12-.525.031-1.053,0-1.579.012-.3.008-.441-.081-.435-.41.016-.911.006-1.821.006-2.731l.137-.1c.75.755,1.509,1.5,2.245,2.27C366.668-867.917,367.211-867.653,367.093-867.35Z"
                  transform="translate(-355.275 871.599)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  d="M438.016-807.745c-.826-.806-1.629-1.636-2.456-2.44-.257-.249-.326-.435-.1-.769a4.133,4.133,0,0,0,.679-2.333,3.835,3.835,0,0,0-.11-.74,4.364,4.364,0,0,0-4.542-3.56,4.384,4.384,0,0,0-4.09,4.336,4.177,4.177,0,0,0,2.038,3.714,4.108,4.108,0,0,0,4.537.047.572.572,0,0,1,.866.11c.784.819,1.609,1.6,2.393,2.417.306.319.522.343.846.017S438.288-807.479,438.016-807.745Zm-6.259-2.2a3.322,3.322,0,0,1-3.277-3.295,3.325,3.325,0,0,1,3.31-3.261,3.328,3.328,0,0,1,3.246,3.265A3.321,3.321,0,0,1,431.757-809.943Z"
                  transform="translate(-418.633 824.16)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </g>
            <g transform="translate(-369.34 -213.657)">
              <rect
                width="160.973"
                height="91.871"
                rx="10"
                transform="translate(873.34 401.291)"
                fill="url(#y)"
              />
              <path
                d="M150.973,45.753H10a10.012,10.012,0,0,1-10-10V0H160.973V35.753A10.012,10.012,0,0,1,150.973,45.753Z"
                transform="translate(873.34 447.227)"
                fill="url(#y)"
              />
              <text
                transform="translate(901.115 432.436)"
                fill="#fff"
                fontSize="22"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  反應槽#2B
                </tspan>
              </text>
              <text
                transform="translate(901.232 479.227)"
                fill="#fff"
                fontSize="21"
                className="pointer"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                onClick={() => openModal(testData.wg[2], "third")}
              >
                <tspan x="0" y="0">
                  查看詳情
                </tspan>
              </text>
              <g transform="translate(993.911 461.227)">
                <path
                  d="M367.8-868.378c-.882-.864-1.746-1.747-2.624-2.616a1.926,1.926,0,0,0-1.408-.605c-2.267.007-4.535,0-6.8.007a1.612,1.612,0,0,0-1.677,1.635q-.019,7.106,0,14.212a1.6,1.6,0,0,0,1.609,1.639q4.889.024,9.778,0a1.622,1.622,0,0,0,1.57-1.024,4.279,4.279,0,0,0-1.585-.08c-3.2.035-6.4.015-9.6.021-.49,0-.692-.221-.692-.7q.007-6.954,0-13.909c0-.479.195-.708.688-.706,1.8.008,3.6.012,5.405,0,.381,0,.485.132.478.494-.019.971,0,1.943-.008,2.915,0,.55.263.938.8.954,1.436.042,2.875.015,4.312.018.206,0,.344-.042.36-.294A2.322,2.322,0,0,0,367.8-868.378Zm-.7,1.028c-.127.325-.689.1-1.058.12-.525.031-1.053,0-1.579.012-.3.008-.441-.081-.435-.41.016-.911.006-1.821.006-2.731l.137-.1c.75.755,1.509,1.5,2.245,2.27C366.668-867.917,367.211-867.653,367.093-867.35Z"
                  transform="translate(-355.275 871.599)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  d="M438.016-807.745c-.826-.806-1.629-1.636-2.456-2.44-.257-.249-.326-.435-.1-.769a4.133,4.133,0,0,0,.679-2.333,3.835,3.835,0,0,0-.11-.74,4.364,4.364,0,0,0-4.542-3.56,4.384,4.384,0,0,0-4.09,4.336,4.177,4.177,0,0,0,2.038,3.714,4.108,4.108,0,0,0,4.537.047.572.572,0,0,1,.866.11c.784.819,1.609,1.6,2.393,2.417.306.319.522.343.846.017S438.288-807.479,438.016-807.745Zm-6.259-2.2a3.322,3.322,0,0,1-3.277-3.295,3.325,3.325,0,0,1,3.31-3.261,3.328,3.328,0,0,1,3.246,3.265A3.321,3.321,0,0,1,431.757-809.943Z"
                  transform="translate(-418.633 824.16)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </g>
            <g transform="translate(5.661 -213.657)">
              <rect
                width="160.973"
                height="91.871"
                rx="10"
                transform="translate(873.34 401.291)"
                fill="url(#y)"
              />
              <path
                d="M150.973,45.753H10a10.012,10.012,0,0,1-10-10V0H160.973V35.753A10.012,10.012,0,0,1,150.973,45.753Z"
                transform="translate(873.34 447.227)"
                fill="url(#y)"
              />
              <text
                transform="translate(901.115 432.436)"
                fill="#fff"
                fontSize="22"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  反應槽#2A
                </tspan>
              </text>
              <text
                transform="translate(901.232 479.227)"
                fill="#fff"
                fontSize="21"
                className="pointer"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                onClick={() => openModal(testData.wg[1], "second")}
              >
                <tspan x="0" y="0">
                  查看詳情
                </tspan>
              </text>
              <g transform="translate(993.911 461.227)">
                <path
                  d="M367.8-868.378c-.882-.864-1.746-1.747-2.624-2.616a1.926,1.926,0,0,0-1.408-.605c-2.267.007-4.535,0-6.8.007a1.612,1.612,0,0,0-1.677,1.635q-.019,7.106,0,14.212a1.6,1.6,0,0,0,1.609,1.639q4.889.024,9.778,0a1.622,1.622,0,0,0,1.57-1.024,4.279,4.279,0,0,0-1.585-.08c-3.2.035-6.4.015-9.6.021-.49,0-.692-.221-.692-.7q.007-6.954,0-13.909c0-.479.195-.708.688-.706,1.8.008,3.6.012,5.405,0,.381,0,.485.132.478.494-.019.971,0,1.943-.008,2.915,0,.55.263.938.8.954,1.436.042,2.875.015,4.312.018.206,0,.344-.042.36-.294A2.322,2.322,0,0,0,367.8-868.378Zm-.7,1.028c-.127.325-.689.1-1.058.12-.525.031-1.053,0-1.579.012-.3.008-.441-.081-.435-.41.016-.911.006-1.821.006-2.731l.137-.1c.75.755,1.509,1.5,2.245,2.27C366.668-867.917,367.211-867.653,367.093-867.35Z"
                  transform="translate(-355.275 871.599)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  d="M438.016-807.745c-.826-.806-1.629-1.636-2.456-2.44-.257-.249-.326-.435-.1-.769a4.133,4.133,0,0,0,.679-2.333,3.835,3.835,0,0,0-.11-.74,4.364,4.364,0,0,0-4.542-3.56,4.384,4.384,0,0,0-4.09,4.336,4.177,4.177,0,0,0,2.038,3.714,4.108,4.108,0,0,0,4.537.047.572.572,0,0,1,.866.11c.784.819,1.609,1.6,2.393,2.417.306.319.522.343.846.017S438.288-807.479,438.016-807.745Zm-6.259-2.2a3.322,3.322,0,0,1-3.277-3.295,3.325,3.325,0,0,1,3.31-3.261,3.328,3.328,0,0,1,3.246,3.265A3.321,3.321,0,0,1,431.757-809.943Z"
                  transform="translate(-418.633 824.16)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </g>
            <g transform="translate(-750.34 -213.657)">
              <rect
                width="160.973"
                height="91.871"
                rx="10"
                transform="translate(873.34 401.291)"
                fill="url(#y)"
              />
              <path
                d="M150.973,45.753H10a10.012,10.012,0,0,1-10-10V0H160.973V35.753A10.012,10.012,0,0,1,150.973,45.753Z"
                transform="translate(873.34 447.227)"
                fill="url(#y)"
              />
              <text
                transform="translate(907.326 432.436)"
                fill="#fff"
                fontSize="22"
                fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  反應槽#3
                </tspan>
              </text>
              <text
                className="pointer"
                transform="translate(901.232 479.227)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
                onClick={() => openModal(testData.wg[3], "fourth")}
              >
                <tspan x="0" y="0">
                  查看詳情
                </tspan>
              </text>
              <g transform="translate(993.911 461.227)">
                <path
                  d="M367.8-868.378c-.882-.864-1.746-1.747-2.624-2.616a1.926,1.926,0,0,0-1.408-.605c-2.267.007-4.535,0-6.8.007a1.612,1.612,0,0,0-1.677,1.635q-.019,7.106,0,14.212a1.6,1.6,0,0,0,1.609,1.639q4.889.024,9.778,0a1.622,1.622,0,0,0,1.57-1.024,4.279,4.279,0,0,0-1.585-.08c-3.2.035-6.4.015-9.6.021-.49,0-.692-.221-.692-.7q.007-6.954,0-13.909c0-.479.195-.708.688-.706,1.8.008,3.6.012,5.405,0,.381,0,.485.132.478.494-.019.971,0,1.943-.008,2.915,0,.55.263.938.8.954,1.436.042,2.875.015,4.312.018.206,0,.344-.042.36-.294A2.322,2.322,0,0,0,367.8-868.378Zm-.7,1.028c-.127.325-.689.1-1.058.12-.525.031-1.053,0-1.579.012-.3.008-.441-.081-.435-.41.016-.911.006-1.821.006-2.731l.137-.1c.75.755,1.509,1.5,2.245,2.27C366.668-867.917,367.211-867.653,367.093-867.35Z"
                  transform="translate(-355.275 871.599)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  d="M438.016-807.745c-.826-.806-1.629-1.636-2.456-2.44-.257-.249-.326-.435-.1-.769a4.133,4.133,0,0,0,.679-2.333,3.835,3.835,0,0,0-.11-.74,4.364,4.364,0,0,0-4.542-3.56,4.384,4.384,0,0,0-4.09,4.336,4.177,4.177,0,0,0,2.038,3.714,4.108,4.108,0,0,0,4.537.047.572.572,0,0,1,.866.11c.784.819,1.609,1.6,2.393,2.417.306.319.522.343.846.017S438.288-807.479,438.016-807.745Zm-6.259-2.2a3.322,3.322,0,0,1-3.277-3.295,3.325,3.325,0,0,1,3.31-3.261,3.328,3.328,0,0,1,3.246,3.265A3.321,3.321,0,0,1,431.757-809.943Z"
                  transform="translate(-418.633 824.16)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </g>
            <circle
              cx="13"
              cy="13"
              r="13"
              transform="translate(383.731 419.114)"
              fill="#971616"
            >
              {is_darkredLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f7dada"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </circle>
            <circle
              cx="13"
              cy="13"
              r="13"
              transform="translate(1121.922 419.114)"
              fill="#065729"
            >
              {is_darkgreenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </circle>
            <circle
              cx="13"
              cy="13"
              r="13"
              transform="translate(383.731 390.114)"
              fill="#971616"
            >
              {is_redLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f3d6d6"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </circle>
            <circle
              cx="13"
              cy="13"
              r="13"
              transform="translate(1121.922 390.114)"
              fill="#065729"
            >
              {is_greenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </circle>
            <text
              transform="translate(389.714 436.114)"
              fill="#fff"
              fontSize="11"
              fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                2B
              </tspan>
            </text>
            <text
              transform="translate(1127.939 436.114)"
              fill="#fff"
              fontSize="11"
              fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                2B
              </tspan>
            </text>
            <text
              transform="translate(389.714 407.114)"
              fill="#fff"
              fontSize="11"
              fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                2A
              </tspan>
            </text>
            <text
              transform="translate(1127.939 407.114)"
              fill="#fff"
              fontSize="11"
              fontFamily="MicrosoftJhengHeiBold, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                2A
              </tspan>
            </text>
            <path
              d="M88.9,2.5H0v-5H88.9Z"
              transform="translate(289.853 432.698)"
              fill="#971616"
            >
              {is_darkredLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f7dada"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M88.9,2.5H0v-5H88.9Z"
              transform="translate(1157.506 432.698)"
              fill="#065729"
            >
              {is_darkgreenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M88.9,2.5H0v-5H88.9Z"
              transform="translate(289.853 403.561)"
              fill="#971616"
            >
              {is_redLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f33535"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M88.9,2.5H0v-5H88.9Z"
              transform="translate(1157.506 403.561)"
              fill="#065729"
            >
              {is_greenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(285.25 408.562) rotate(-90)"
              fill="#971616"
            >
              {is_redLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f33535"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(1152.903 408.562) rotate(-90)"
              fill="#065729"
            >
              {is_greenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(1409.943 403.275) rotate(-90)"
              fill={is_blueLine ? null : "#082246"}
            >
              {is_blueLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#083fef"
                  to="#082246"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(285.25 437.697) rotate(-90)"
              fill="#971616"
            >
              {is_darkredLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#f33535"
                  to="#971616"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(1152.903 437.697) rotate(-90)"
              fill="#065729"
            >
              {is_darkgreenLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#74f349"
                  to="#065729"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(1409.943 417.619) rotate(-90)"
              fill={s_first.wgTran_1 ? null : "#082246"}
            >
              {is_darkBlueLine ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#aaaebd"
                  to="#082246"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M5,0l5,6H0Z"
              transform="translate(1540.713 665.705) rotate(90)"
              fill="#707070"
            />
            <g transform="translate(30.771 629.558)">
              <path
                d="M317.343,72.475H10a10.011,10.011,0,0,1-10-10V10A10.011,10.011,0,0,1,10,0H151.443c.116,6.905,6.7,12.523,14.677,12.523S180.682,6.9,180.8,0H317.343a10.011,10.011,0,0,1,10,10V62.475A10.011,10.011,0,0,1,317.343,72.475Z"
                transform="translate(0 0)"
                fill="url(#ag)"
              />
              <path
                d="M317.343,71.475a9.01,9.01,0,0,0,9-9V10a9.01,9.01,0,0,0-9-9H181.74c-.7,6.99-7.479,12.523-15.62,12.523S151.2,7.99,150.5,1H10a9.01,9.01,0,0,0-9,9V62.475a9.01,9.01,0,0,0,9,9H317.343m0,1H10a10.011,10.011,0,0,1-10-10V10A10.011,10.011,0,0,1,10,0H151.443c.116,6.905,6.7,12.523,14.677,12.523S180.682,6.9,180.8,0H317.343a10.011,10.011,0,0,1,10,10V62.475A10.011,10.011,0,0,1,317.343,72.475Z"
                transform="translate(0 0)"
                fill="#707070"
              />
              <path
                d="M0,0H327.343a0,0,0,0,1,0,0V36.688a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10V0A0,0,0,0,1,0,0Z"
                transform="translate(0 25.788)"
                fill="#7a8397"
              />
              <path
                d="M1,1V36.688a9.01,9.01,0,0,0,9,9H317.343a9.01,9.01,0,0,0,9-9V1H1M0,0H327.343V36.688a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10Z"
                transform="translate(0 25.788)"
                fill="#707070"
              />
            </g>
            <path
              d="M2.5,87.8h-5V0h5Z"
              transform="translate(197.24 555.347)"
              fill="#707070"
            >
              {s_fourth.wgTran_3 ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#C0C0C0"
                  to="#625b57"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M2.5,8h-5V0h5Z"
              transform="translate(1329.754 555.143)"
              fill="#707070"
            >
              {s_first.wgTran_3 ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#C0C0C0"
                  to="#625b57"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M2.5,8h-5V0h5Z"
              transform="translate(955.939 555.143)"
              fill="#707070"
            >
              {s_second.wgTran_3 ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#C0C0C0"
                  to="#625b57"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <path
              d="M2.5,8h-5V0h5Z"
              transform="translate(580.756 555.143)"
              fill="#707070"
            >
              {s_third.wgTran_3 ? (
                <animate
                  attributeType="CSS"
                  attributeName="fill"
                  from="#C0C0C0"
                  to="#625b57"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              ) : null}
            </path>
            <g transform="translate(1540.713 629.558)">
              <path
                d="M348.48,72.475H10a10.011,10.011,0,0,1-10-10V10A10.011,10.011,0,0,1,10,0H165.848a11.537,11.537,0,0,0,4.785,8.861,18.369,18.369,0,0,0,11.288,3.661A18.369,18.369,0,0,0,193.21,8.861,11.538,11.538,0,0,0,197.995,0H348.48a10.011,10.011,0,0,1,10,10V62.475A10.011,10.011,0,0,1,348.48,72.475Z"
                transform="translate(0 0)"
                fill="url(#ag)"
              />
              <path
                d="M348.48,71.475a9.01,9.01,0,0,0,9-9V10a9.01,9.01,0,0,0-9-9H198.934a12.718,12.718,0,0,1-5.109,8.65,19.372,19.372,0,0,1-11.9,3.873,19.372,19.372,0,0,1-11.9-3.873A12.719,12.719,0,0,1,164.909,1H10a9.01,9.01,0,0,0-9,9V62.475a9.01,9.01,0,0,0,9,9H348.48m0,1H10a10.011,10.011,0,0,1-10-10V10A10.011,10.011,0,0,1,10,0H165.848a11.537,11.537,0,0,0,4.785,8.861,18.369,18.369,0,0,0,11.288,3.661A18.369,18.369,0,0,0,193.21,8.861,11.538,11.538,0,0,0,197.995,0H348.48a10.011,10.011,0,0,1,10,10V62.475A10.011,10.011,0,0,1,348.48,72.475Z"
                transform="translate(0 0)"
                fill="#707070"
              />
              <path
                d="M0,0H358.48a0,0,0,0,1,0,0V36.688a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10V0A0,0,0,0,1,0,0Z"
                transform="translate(0 25.788)"
                fill="#7a8397"
              />
              <path
                d="M1,1V36.688a9.01,9.01,0,0,0,9,9H348.48a9.01,9.01,0,0,0,9-9V1H1M0,0H358.48V36.688a10,10,0,0,1-10,10H10a10,10,0,0,1-10-10Z"
                transform="translate(0 25.788)"
                fill="#707070"
              />
            </g>
            <rect
              width="353.631"
              height="138.891"
              rx="12"
              transform="translate(783.185 563.144)"
              fill="rgba(255,213,146,0.7)"
            />
            <rect
              width="353.631"
              height="138.891"
              rx="12"
              transform="translate(1156.999 563.144)"
              fill="#ffdfa6"
            />
            <rect
              width="353.631"
              height="138.891"
              rx="12"
              transform="translate(408.001 563.144)"
              fill="#ffe4bd"
            />
            <path
              d="M0,0H353.631a0,0,0,0,1,0,0V56.27a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z"
              transform="translate(783.185 633.764)"
              fill="#ffd592"
            />
            <path
              d="M0,0H353.631a0,0,0,0,1,0,0V56.27a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z"
              transform="translate(1156.999 633.764)"
              fill="#ffd592"
            />
            <path
              d="M0,0H353.631a0,0,0,0,1,0,0V56.27a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z"
              transform="translate(408.001 633.764)"
              fill="#ffd592"
            />
            <text
              transform="translate(827.722 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                槽套溫度
              </tspan>
            </text>
            <text
              transform="translate(1201.537 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                槽套溫度
              </tspan>
            </text>
            <text
              transform="translate(452.539 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                槽套溫度
              </tspan>
            </text>
            <text
              transform="translate(827.723 625.777)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                設定溫度
              </tspan>
            </text>
            <text
              transform="translate(1201.537 625.777)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                設定溫度
              </tspan>
            </text>
            <text
              transform="translate(452.539 625.777)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                設定溫度
              </tspan>
            </text>
            <text
              transform="translate(827.723 659.398)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                熱煤設定
              </tspan>
            </text>
            <text
              transform="translate(1201.537 659.398)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                熱煤設定
              </tspan>
            </text>
            <text
              transform="translate(452.539 659.398)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                熱煤設定
              </tspan>
            </text>
            <text
              transform="translate(827.723 671.02)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="23" xmlSpace="preserve">
                熱煤溫度{" "}
              </tspan>
            </text>
            <text
              transform="translate(1201.537 671.02)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="23" xmlSpace="preserve">
                熱煤溫度{" "}
              </tspan>
            </text>
            <text
              transform="translate(452.539 671.02)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="23" xmlSpace="preserve">
                熱煤溫度{" "}
              </tspan>
            </text>
            <text
              transform="translate(1058.472 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1432.286 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(683.288 591.157)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1058.472 625.959)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1432.286 625.959)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(683.288 626.001)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1058.472 659.75)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1432.286 659.75)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(683.288 659)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1058.472 694.02)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(1432.286 694.02)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <text
              transform="translate(683.288 694)"
              fontSize="21"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                ℃
              </tspan>
            </text>
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(783.185 600.143)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(1156.999 600.143)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(408.001 600.143)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(783.185 633.764)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(1156.999 633.764)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(408.001 633.764)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(783.185 667.385)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(1156.999 667.385)"
              fill="#fff2f2"
            />
            <path
              d="M353.5.5H0v-1H353.5Z"
              transform="translate(408.001 667.385)"
              fill="#fff2f2"
            />
            <path
              d="M.5,139h-1V0h1Z"
              transform="translate(955.939 563.143)"
              fill="#fffefe"
            />
            <path
              d="M.5,139h-1V0h1Z"
              transform="translate(1329.754 563.143)"
              fill="#fffefe"
            />
            <path
              d="M.5,139h-1V0h1Z"
              transform="translate(580.756 563.143)"
              fill="#fffefe"
            />
            <g transform="translate(219.582 1223.261)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(593.396 1223.261)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(-155.602 1222.664)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(219.582 1188.64)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(593.396 1188.64)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(-155.602 1188.64)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(219.582 1256.882)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(593.396 1256.882)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(-155.602 1256.665)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(219.582 1291.855)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(593.396 1291.855)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(-155.602 1291.665)">
              <path
                d="M901.117-602.534a1.93,1.93,0,0,1-.437-1.346c.025-1.923.01-3.846.01-5.77,0-1.954.009-3.908,0-5.861a3.945,3.945,0,0,0-.863-2.548,3.963,3.963,0,0,0-4.755-1.3,4.06,4.06,0,0,0-2.618,3.916c-.013,3.511-.035,7.022.014,10.532a5.159,5.159,0,0,1-.862,3.059,5.169,5.169,0,0,0,.249,6.01,5.331,5.331,0,0,0,5.253,2.5A5.718,5.718,0,0,0,901.117-602.534ZM900-596.754a4.136,4.136,0,0,1-5.105,1.449,4.161,4.161,0,0,1-2.41-4.405,3.728,3.728,0,0,1,1.125-2.154,1.569,1.569,0,0,0,.5-1.3q-.022-6-.007-12c0-1.728,1.017-2.878,2.508-2.858,1.458.02,2.434,1.151,2.437,2.838,0,1.985,0,3.97,0,5.955,0,2.046.012,4.092-.009,6.138a1.574,1.574,0,0,0,.482,1.212A4.057,4.057,0,0,1,900-596.754Z"
                transform="translate(0 0)"
                fill="#474747"
                fillRule="evenodd"
              />
              <path
                d="M910.69-594.011q0-3.112,0-6.222c0-.611-.093-1.343.8-1.354.965-.011.845.766.845,1.4,0,4.209.01,8.419-.008,12.627a1.4,1.4,0,0,0,.647,1.362,2.35,2.35,0,0,1,.848,2.76,2.527,2.527,0,0,1-2.424,1.612,2.57,2.57,0,0,1-2.267-1.83,2.369,2.369,0,0,1,.955-2.573,1.3,1.3,0,0,0,.61-1.286C910.678-589.68,910.69-591.846,910.69-594.011Z"
                transform="translate(-14.947 -14.764)"
                fill="#474747"
                fillRule="evenodd"
              />
            </g>
            <g transform="translate(1675.039 669.585)">
              <text
                transform="translate(-19 19.211)"
                fill="#fff"
                fontSize="21"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  重量
                </tspan>
              </text>
              <text
                transform="translate(114.36 15.211)"
                fill="#fff"
                fontSize="12"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  kg
                </tspan>
              </text>
              <rect
                width="84.321"
                height="24.615"
                transform="translate(27.52 -1.789)"
                fill="#fff"
              />
              <path
                d="M-.5-.5H84.821V25.115H-.5ZM83.821.5H.5V24.115H83.821Z"
                transform="translate(27.52 -1.789)"
                fill="#1a1311"
              />
              <text
                transform="translate(59.181 17.211)"
                fill="#1a1311"
                fontSize="18"
                fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
              >
                <tspan x="0" y="0">
                  {s_utility.catchW}
                </tspan>
              </text>
            </g>
            <path
              d="M.25,66.6h-.5V0h.5Z"
              transform="translate(272.5 33.542)"
              fill="#fff"
            />
            <path
              d="M.25,66.6h-.5V0h.5Z"
              transform="translate(653.5 33.542)"
              fill="#fff"
            />
            <path
              d="M.25,66.6h-.5V0h.5Z"
              transform="translate(1028.5 33.542)"
              fill="#fff"
            />
            <path
              d="M.25,66.6h-.5V0h.5Z"
              transform="translate(1403.773 33.542)"
              fill="#fff"
            />
            {/* <text
            transform="translate(628.43 591.156)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_third.outTemp}</tspan>
          </text> */}
            <g transform="translate(628.43 591.156)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {/* 2B批號 扭力 */}
                {s_third.outTemp}
              </text>
            </g>
            {/* <text transform="translate(1003.613 591.156)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            註解 <tspan x="0" y="0">{s_second.outTemp}</tspan>
          </text> */}
            <g transform="translate(1003.613 591.156)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {/* 2B批號 扭力 */}
                {s_second.outTemp}
              </text>
            </g>
            {/* <text transform="translate(1377.43 591.156)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_first.outTemp}</tspan>
          </text> */}
            <g transform="translate(1377.43 591.156)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {/* 2B批號 扭力 */}
                {s_first.outTemp}
              </text>
            </g>
            {/* <text transform="translate(628.43 626.001)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_third.setTemp}</tspan>
          </text> */}
            <g transform="translate(628.43 626.001)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_third.setTemp}
              </text>
            </g>
            {/* <text transform="translate(1003.613 626.001)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            註解 <tspan x="0" y="0">{s_second.setTemp}</tspan>
          </text> */}
            <g transform="translate(1003.613 626.001)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_second.setTemp}
              </text>
            </g>
            {/* <text transform="translate(1377.43 626.001)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_first.setTemp}</tspan>
          </text> */}
            <g transform="translate(1377.43 626.001)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_first.setTemp}
              </text>
            </g>
            {/* <text transform="translate(628.43 659)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_third.setkeroTemp}</tspan>
          </text> */}
            <g transform="translate(628.43 659)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_third.setkeroTemp}
              </text>
            </g>
            {/* <text transform="translate(1003.613 659)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            註解 <tspan x="0" y="0">{s_second.setkeroTemp}</tspan>
          </text> */}
            <g transform="translate(1003.613 659)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_second.setkeroTemp}
              </text>
            </g>
            {/* <text transform="translate(1377.43 659)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_first.setkeroTemp}</tspan>
          </text> */}
            <g transform="translate(1377.43 659)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_first.setkeroTemp}
              </text>
            </g>
            {/* <text transform="translate(628.43 694)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_third.keroTemp}</tspan>
          </text> */}
            <g transform="translate(628.43 694)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_third.keroTemp}
              </text>
            </g>
            {/* <text transform="translate(1003.613 694)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            註解 <tspan x="0" y="0">{s_second.keroTemp}</tspan>
          </text> */}
            <g transform="translate(1003.613 694)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_second.keroTemp}
              </text>
            </g>
            {/* <text transform="translate(1377.43 694)" fill="#1a1311" fontSize="21"
            fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei">
            <tspan x="0" y="0">{s_first.keroTemp}</tspan>
          </text> */}
            <g transform="translate(1377.43 694)">
              <text textAnchor="middle" x="0" y="0" fontSize="21">
                {s_first.keroTemp}
              </text>
            </g>
          </g>
        </svg>
      </div>
    </StyleSVG>
  );
};
export default SVGcomponent;
