import styled from "styled-components";

export const Wrapper = styled.div`
  //width: ${({width}) => width};
  width: 100%inherit;
  height: 143px;
  display: flex;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
`
export const Header = styled.div`
  background-color: #BCEAFF;
  width: 100%;
  height: 36px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #707070;
  display: flex;

`
export const LeftHeader = styled.div`
  color: #083FEF;
  font-size: 21px;
  font-weight: bold;
  font-family: Microsoft JhengHei;
  width: 60%;
  text-align: center;
`
export const RightHeader = styled.div`
  width: 40%;
  color: #000;
  font-size: 21px;
  font-family: Microsoft JhengHei;
  font-weight: bold;
  text-align: center;
`
export const Container = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  height: 368px;
  overflow: hidden;
  width: 100%;
  border: .5px solid #707070;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`
export const Container1 = styled.div`
  
  height: 368px;
  overflow: auto;
  
`
export const Card = styled.div`
  margin-top: -1px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-top: .5px solid #707070;
`
export const Span = styled.span`
  width: 60%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  color: #083FEF;
`
export const Time = styled.div`
  width: 40%;
  white-space: wrap;
  text-align: center;
  color: #000;
  font-size: 17px;
`