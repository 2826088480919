let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let timeOut = 5 * 60 * 1000; //設定超時時間: 10分

window.onload = function () {
  window.document.onmousemove = function () {
    lastTime = new Date().getTime(); //更新操作時間
  };
};
function webOutTime(path) {
  currentTime = new Date().getTime(); //更新當前時間
  if (currentTime - lastTime > timeOut) {
    //判斷是否超時
    console.log("超時,導回看板");
    window.location.assign(`/${path}`);
  }
}

export default webOutTime;
