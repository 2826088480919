import styled from "styled-components";
import { fontSize } from "../config";

const handleFontSize = (size) => {
  switch (size) {
    case "h1":
      return fontSize.h1;
    case "h2":
      return fontSize.h2;
    case "h3":
      return fontSize.h3;
    case "h4":
      return fontSize.h4;
    case "h5":
      return fontSize.h5;
    case "p":
      return fontSize.p;
    default:
      return fontSize.p;
  }
};

const handleType = (type) => {
  switch (type) {
    case "main":
      return "mainColor";
    case "error":
      return "error";
    case "warning":
      return "warning";
    case "success":
      return "success";
    case "disabled":
      return "disabledTextColor";
    default:
      return "text";
  }
};


const TitleElement = ({
  className,
  children,
  level = "h1",
  mark = false,
  type = "text",
  vice = false,
  style,
}) => {
  return (
    <div className={className} style={style}>
      <span className={`text ${mark && "mark"} ${vice && "vice"}`}>
        {children}
      </span>
    </div>
  );
};

/**
 *
 * @param {string} level"h1~p"
 * @param {bool} mark "遮罩"
 * @param {string} type     
 *   "main"
     "error"
     "warning"
     "success"
     "disabled"
  @param {bool} vice "opacity 0.6"
 */


export const Title = styled(TitleElement)`
  font-weight: 800;
  font-size: ${({ level }) => {
    return handleFontSize(level);
  }};
  color: ${({ theme, type }) => {
    return theme[handleType(type)]
  }};
  line-height: 1.5;
  font-weight: 400;
  margin: 5px 0px;
  white-space: nowrap;
  .vice {
    opacity: 0.6;
  }
  .text {
    margin: 0px;
    padding: 0px;
  }
  /* 有背景時字皆為白色 */
  .mark {
    color: white;
    background-color: ${({ theme }) => theme.mainColor};
    border-radius: 5px;
  }
`;

export default Title