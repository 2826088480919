import { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  message,
  Modal,
  Button,
  Descriptions,
  Space,
  Alert,
} from "antd";
import {
  MyPageName,
  MyPageTitle,
  MyThemeH1,
  MyMainColorH1,
} from "../../styled/publicTag";
import { sop } from "../../services/api/api";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

const WosSOP = () => {
  const pdfRef = useRef(null);

  const [pdfInfo, setPdfInfo] = useState({
    isOpen: true,
    pdfUrl: "",
    width: 800,
    scale: 1,
    pdfData: {},
  });

  const upSize = () => {
    setPdfInfo({ ...pdfInfo, scale: pdfInfo.scale + 0.1 });
  };

  const downSize = () => {
    setPdfInfo({ ...pdfInfo, scale: pdfInfo.scale - 0.1 });
  };

  const call_sop = async () => {
    try {
      const res = await sop();
      console.log(res);
      if (res.status) {
        setPdfInfo({ ...pdfInfo, ...res.data });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    call_sop();
  }, []);

  const onDocumentLoadSuccess = (e) => {
    console.log(e._pdfInfo);
    setPdfInfo({ ...pdfInfo, pdfData: e._pdfInfo });
  };

  const labelStyle = {
    fontSize: "26px",
    backgroundColor: "#f0fffb",
  };

  useEffect(() => {
    //此為橫式pdf
    if (pdfRef.current.clientHeight < 600) {
      setPdfInfo({ ...pdfInfo, width: 1200 });
      //此為直式pdf
    } else if (pdfRef.current.clientHeight > 600) {
      setPdfInfo({ ...pdfInfo, width: 500 });
    }
  }, [pdfRef]);

  return (
    <>
      <MyPageName>
        <Row justify="space-between" align="top">
          <Col span={6}>
            <MyPageTitle>設備看板_SOP顯示</MyPageTitle>
            {/* <MyAntdInput label="test" onChange={(e)=>{console.log("sop",e)}} placeholder="test123"/> */}
          </Col>
        </Row>
      </MyPageName>
      <Descriptions title={<MyMainColorH1>標準檢驗SOP</MyMainColorH1>} bordered>
        <Descriptions.Item label="文件預覽" span={3} labelStyle={labelStyle}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setPdfInfo({ ...pdfInfo, isOpen: true });
            }}
          >
            <Document
              file={pdfInfo.pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              noData="暫時沒有PDF"
              inputRef={pdfRef}
              error={<Alert type="error" message="暫無PDF文件" />}
            >
              <Page pageNumber={1} height={800} scale={1} />
            </Document>
          </div>
        </Descriptions.Item>
      </Descriptions>

      <Modal
        title={<MyThemeH1>SOP瀏覽</MyThemeH1>}
        visible={pdfInfo.isOpen}
        onCancel={() => {
          setPdfInfo({ ...pdfInfo, isOpen: false });
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="ttst"
            type="primary"
            onClick={() => {
              setPdfInfo({ ...pdfInfo, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1400px"}
        centered
        forceRender
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Space size={48}>
            <Button size="large" type="primary" onClick={upSize}>
              +
            </Button>
            <Button size="large" type="primary" onClick={downSize}>
              -
            </Button>
          </Space>
        </div>
        <div
          style={{
            width: "100%",
            height: "840px",
            overflow: "scroll",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document
            file={pdfInfo.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf_center"
            onLoadProgress={({ loaded, total }) =>
              console.log("Loading a document: " + (loaded / total) * 100 + "%")
            }
            error={<Alert type="error" message="暫無PDF文件" />}
          >
            {pdfInfo.pdfData &&
              new Array(pdfInfo.pdfData.numPages)
                .fill("")
                .map((item, index) => {
                  return (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      scale={pdfInfo.scale}
                      height={800}
                    />
                  );
                })}
            {/* <Page pageNumber={1} height={800} scale={pdfInfo.scale} /> */}
          </Document>
        </div>
      </Modal>
    </>
  );
};

export default WosSOP;
